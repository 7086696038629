import {
  ActivityCard,
  TrackingActivityModelKey,
  TrackingActivityModelType,
  useTrackingActivity
} from "@components/activity-controller";
import { EmailForm, LoadMoreBtn, useEmail } from "@components/email-controller";
import { Modal, WithIndicator } from "@views/components";
import { useSelector } from "react-redux";
import { selectUserId } from "@state/selectors";
import { MainForm } from "@components/task-controller";
import ConfirmDialog from "@components/modal.confirmation";
import useActivityTask from "@components/activity-controller/useActivityTask";

export default function ActivityHistory({
  modelId,
  modelType,
  modelKey
}: {
  modelId: number;
  modelType: TrackingActivityModelType;
  modelKey: TrackingActivityModelKey;
}) {
  const userId = useSelector(selectUserId);
  const emailMappingKey = (modelKey: string) => {
    if (modelKey === "user_id") {
      return "contact_id";
    }
    return modelKey;
  };
  // tracking activity hook
  const { trackingActivities, total, loadMore, fetchTrackingActivities } = useTrackingActivity(
    modelId,
    modelType
  );
  // email hook
  const [, setItem, , emailSelected, , onSubmit, onDiscard, handleCloseModal] = useEmail(
    { ref_type: modelType, ref_id: modelId, contact_id: userId },
    { [emailMappingKey(modelKey)]: modelId },
    () => fetchTrackingActivities(true)
  );
  // task hook
  const [
    taskTitle,
    taskSelected,
    handleTaskSubmit,
    handleTaskDelete,
    handleTaskCancel,
    handleTaskViewChange,
    onConfirm,
    closeModal,
    handleTaskEdit
  ] = useActivityTask(() => fetchTrackingActivities(true));
  return (
    <div className="text-sm font-semibold text-gray-600 pt-3 space-y-3">
      <WithIndicator
        isLoaded={trackingActivities !== null}
        isEmpty={trackingActivities?.length === 0}
      >
        {trackingActivities?.map((activity) => {
          return (
            <ActivityCard
              key={activity.id}
              activity={activity}
              onSelectEmail={setItem}
              handleTaskDelete={handleTaskDelete}
              handleTaskViewChange={handleTaskViewChange}
              handleTaskEdit={handleTaskEdit}
            />
          );
        })}
      </WithIndicator>
      <LoadMoreBtn items={trackingActivities} total={total} loadMore={loadMore} />
      {/* Email Modal*/}
      {emailSelected && (
        <Modal modalKey="open_email_form">
          <EmailForm
            refer={{ ref_id: modelId, ref_type: modelType, contact_id: userId }}
            data={emailSelected}
            onSubmit={onSubmit}
            onDiscard={onDiscard}
            closeModal={handleCloseModal}
          />
        </Modal>
      )}
      {/* Task Modal */}
      {taskSelected && (
        <Modal modalKey={`update_activity_task`} title={taskTitle}>
          <MainForm
            // belongTo='property_id'
            handleSubmit={handleTaskSubmit}
            handleCancel={handleTaskCancel}
            type={taskSelected.type}
            data={taskSelected}
          />
        </Modal>
      )}
      {/* Delete Task Modal */}
      <Modal modalKey="delete_task">
        <ConfirmDialog
          onConfirm={onConfirm}
          onCancel={closeModal}
          options={{
            title: "Delete this note?",
            subTitle: "Are you sure you want to permanently delete this item?",
            btnStyle: "negative"
          }}
        />
      </Modal>
    </div>
  );
}
