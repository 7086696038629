import React, { useRef, useState } from "react";
import { useTaskStore } from "@views/components/task-controller/store";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";

export default function withTaskLoading<T>(WrappedComponent: React.FC<T>) {
  return function TaskLoading(props: any) {
    const inAction = useTaskStore((state) => state.inAction);
    const [isLoading, setIsLoading] = useState(inAction);
    const init = useRef(false);

    useLegacyEffect(() => {
      if (!init.current) {
        init.current = true;
        return;
      }

      if (inAction) return;
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }, [inAction]);

    return !isLoading ? <WrappedComponent {...props} /> : null;
  };
}
