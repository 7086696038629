import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import toast from "@components/toast";
import { ITask, TASK_TYPE } from "@model/task";
import useModal from "@utils/hooks/useModal";
import { selectUserProfile, selectAdmin } from "@state/selectors";
import { RefType } from "./index";
import type { UserType } from "@model/contact";
import type { IProperty } from "@model/property";
import type { IDeal } from "@model/deal";
import GetTasksService from "@services/tasks/get-tasks.service";
import { IProject } from "@model/project";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";
import { actionsSelector, useTaskStore } from "./store";

export type formType = {
  id: number | string;
  content: string;
};

type queries = {
  page: number;
  per_page: number;
};
export type NoteState = {
  isLoading: boolean;
  queries: queries;
  total: number;
  items: ITask[] | [];
};

export default function useNoteTask(
  type: TASK_TYPE = TASK_TYPE.TASK,
  referTo: RefType<UserType | IDeal | IProperty | IProject>,
  cb?: () => void
) {
  const [isReset, resetState] = useState<boolean>(false);
  const [ownerNotes, setOwnerNotes] = useState<string>("");
  const [state, setState] = useState<NoteState>({
    isLoading: false,
    items: [],
    queries: { page: 1, per_page: 10 },
    total: 0
  });

  const currentUser = useSelector(selectUserProfile);

  const isAdmin = useSelector(selectAdmin);
  const [openModal, closeModal, onConfirm, , modalState] = useModal();
  const { setSelectedTask, createTask, updateTask, deleteTask } = useTaskStore(actionsSelector);

  useLegacyEffect(() => {
    getTasks();
  }, []);

  useLegacyEffect(() => {
    if (referTo.refType === "property_id") {
      const referData = referTo.data as IProperty;
      if (referData?.owner_notes) {
        setOwnerNotes(formatNote(referData.owner_notes));
      }
    }
  }, []);

  const formatNote = (note: string) => {
    return note
      .replace(/Owner Tel:/g, "\nOwner Tel:")
      .replace(/Owner Mobile:/g, "\nOwner Mobile:")
      .replace(/Owner e-Mail:/g, "\nOwner e-Mail:")
      .replace(/Detailed Address:/g, "\nDetailed Address:")
      .replace(/Unit Number:/g, "\nUnit Number:")
      .replace(/Comment:/g, "\nComment:")
      .replace(/Where are the Keys:/g, "\nWhere are the Keys:")
      .replace(/Maintenance Fee:/g, "\nMaintenance Fee:")
      .replace(/Sell With the Company:/g, "\nSell With the Company:")
      .replace(/Rental Deposit:/g, "\nRental Deposit:")
      .replace(/12 Month Contract:/g, "\n12 Month Contract:")
      .replace(/6 Month Contract:/g, "\n6 Month Contract:");
  };

  const getTasks = async () => {
    try {
      const queries: any = {
        filters: {
          type,
          [referTo.refType]: { is: [referTo.data.id] }
        },
        per_page: state.queries.per_page,
        page: state.queries.page
      };
      setState((prev: NoteState) => ({ ...prev, isLoading: true }));
      const res = await GetTasksService.call(queries, "task-relations");
      const newItems = [...state.items, ...res.data];
      const newQuery = {
        page: res.meta.current_page + 1,
        per_page: res.meta.per_page
      };
      setState({
        isLoading: false,
        items: newItems,
        total: res.meta.total,
        queries: newQuery
      });
    } catch (error) {
      console.log("err", error);
    }
  };

  const resetTask = () => {
    const initState = { isLoading: false, items: [], queries: { page: 1, per_page: 10 }, total: 0 };
    setState(initState);

    resetState(true);
  };

  useEffect(() => {
    if (isReset) {
      getTasks();

      resetState(false);
    }
  }, [isReset]);

  const showMore = () => getTasks();

  const handleDelete = async (id: number | string) => {
    try {
      const confirmed = await openModal("delete_task");
      if (!confirmed) return;
      await deleteTask(id);
      // update local state
      setState((prev: NoteState) => ({
        ...prev,
        total: (prev.total -= 1),
        items: prev.items.filter((p) => p.id !== id)
      }));
      if (cb) cb();
      toast.success("Data has been deleted");
    } catch (error) {
      console.log(error);
      toast.error("Please try again");
    }
  };

  const handleViewChange = (data: ITask) => {
    handleSubmit(data);
  };

  const handleSubmit = async (item: ITask) => {
    try {
      if (item.id === "new") {
        const newItem = await createTask(item);
        setState((prev: NoteState) => ({ ...prev, items: [newItem, ...prev.items] }));
        toast.success("Data has been created");
      } else {
        if (typeof item?.id !== "number") return;
        const data = await updateTask(item);

        const taskIndex = state.items.find((t: ITask) => t.id === item.id);
        if (taskIndex) {
          setState((prev: NoteState) => ({
            ...prev,
            items: prev.items.map((p) => {
              if (p.id === item.id) return data;
              return { ...p };
            })
          }));
        } else {
          setState((prev: NoteState) => ({ ...prev, items: [data, ...prev.items] }));
        }
        if (cb) cb();
        toast.success("Data has been updated");
      }
      // close model
      if (modalState.key) {
        closeModal();
      }
    } catch (error) {
      console.log("err", error);
      toast.error("Please try again");
    }
  };

  const defaultObject = useMemo(() => {
    let newObject: ITask = { id: "new", type };
    if (referTo.refType === "property_id") {
      // main relation
      newObject.property_ids = [referTo.data.id];
      newObject.properties = [referTo.data as IProperty];
    } else if (referTo.refType === "project_id") {
      // main relation
      newObject.project_ids = [referTo.data.id];
      newObject.projects = [referTo.data as IProject];
    } else if (referTo.refType === "user_id") {
      // main relation
      newObject.user_id = referTo.data.id;
      newObject.user = referTo.data as UserType;
    } else if (referTo.refType === "deal_id") {
      // main relation
      newObject.deal_id = referTo.data.id;
      newObject.deal = referTo.data as IDeal;

      // associated to
      if (newObject.deal.user) {
        newObject.user_id = newObject.deal.user.id;
        newObject.user = newObject.deal.user as UserType;
      }
    }
    newObject.agent_id = currentUser?.id;
    newObject.agent = currentUser as UserType;
    return newObject;
  }, []);

  const createDraft = async () => {
    try {
      // call API to create new notes
      const newItem = await createTask({ ...defaultObject });
      setState((prev: NoteState) => ({ ...prev, items: [newItem, ...prev.items] }));
      setSelectedTask(newItem);
    } catch (error) {
      toast.error("Please try again");
    }
  };

  return {
    pageData: referTo.data,
    defaultObject,
    state,
    isAdmin,
    currentUser,
    ownerNotes,
    handleSubmit,
    handleDelete,
    showMore,
    handleViewChange,
    onConfirm,
    closeModal,
    createDraft,
    resetTask,
    setSelectedTask
  };
}
