// helpers
import { useState, useEffect, useRef } from "react";
import { sidebarNavigation } from "./layout.configs";
import { submenuType } from "./layout.configs";
import { RouteComponentProps, withRouter, Prompt } from "react-router";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useLocalStorage } from "@utils/hooks/useLocalStorage";
import { classNames, permissionScope, isLiveFeature } from "@utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import { ActionType } from "@state/actions/action.types";
import { selectForm, selectUserProfile } from "@state/selectors";
import { logout } from "@state/actions/action.creators";

type props = {
  // sidebarExpand: boolean;
  // setSidebarExpand: (sidebarExpand: boolean) => void;
} & RouteComponentProps;

const LayoutDefaultSidebar = ({ match }: props) => {
  const formState = useSelector(selectForm);
  const userProfile = useSelector(selectUserProfile);
  const location = useLocation();
  const dispatch = useDispatch();

  const isMount = useRef(false);
  // find active route
  const activeRoute = sidebarNavigation.find((item) =>
    item.parentOf.some((path: string) => matchPath(path, match.path))
  );

  const [submenus, setSubmenus] = useState<submenuType>(activeRoute?.subMenus || null);

  const [storage, setStorage] = useLocalStorage<{
    sidebarExpanded: boolean;
    subMenusExpanded: boolean;
  }>("", "layout", {
    sidebarExpanded: false,
    subMenusExpanded: false
  });
  const [sidebarExpanded, setSidebarExpanded] = useState(storage?.sidebarExpanded || false);

  useEffect(() => {
    // clear all form state after location was changed
    dispatch({ type: ActionType.ON_FORM_RESET });
  }, [location]);

  useEffect(() => {
    if (isMount.current) {
      setStorage({ ...storage, sidebarExpanded });
    }
    isMount.current = true;
  }, [sidebarExpanded]);

  // useEffect(() => {
  // if (activeRoute?.subMenus) setSubmenus(activeRoute?.subMenus)
  // }, [])

  return (
    <>
      <Prompt
        when={!!formState.diff}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <nav
        aria-label="Sidebar"
        className="z-20 flex-col items-center justify-between hidden md:flex-shrink-0 md:bg-gray-800 md:overflow-y-auto md:flex"
      >
        <ReactTooltip
          id="navMenus"
          place="right"
          type="dark"
          effect="solid"
          delayShow={200}
          disable={sidebarExpanded}
        />

        <div
          className={`transition-all duration-400 ease-in-out relative flex flex-col  p-3 space-y-3 ${
            sidebarExpanded ? "w-44" : "w-20"
          }`}
        >
          {sidebarNavigation
            .filter(
              (menu) =>
                permissionScope(menu.permission, userProfile?.role!) && isLiveFeature(menu.id)
            )
            .map((item) => (
              <NavLink
                to={item.path}
                key={item.id}
                activeClassName="text-gray-900 bg-white hover:bg-white rounded-lg"
                isActive={(match, location) => {
                  return (
                    (match?.url && item.parentOf.includes(match.url)) ||
                    item.parentOf.some((path: string) => location.pathname.startsWith(path))
                  );
                }}
                className={`hover:bg-white hover:text-gray-900 text-gray-400 rounded-lg  flex-shrink-0 inline-flex items-center h-14   space-x-3 w-full  p-2 ${
                  sidebarExpanded ? "justify-start" : "justify-center"
                }`}
                // exact
                data-for="navMenus"
                data-tip={item.name}
                onClick={() => {
                  if (!formState.diff) setSubmenus(item.subMenus);
                }}
              >
                <item.icon className="relative z-10 w-6 h-6" aria-hidden="true" />
                <span
                  className={`text-sm font-bold z-0 relative ${
                    sidebarExpanded ? "block opacity-100" : "hidden opacity-0"
                  }`}
                >
                  {item.name}
                </span>
              </NavLink>
            ))}
        </div>
        <div>
          {/* expanding the sidebar */}
          <button
            onClick={() => setSidebarExpanded(!sidebarExpanded)}
            className="inline-flex items-center justify-center flex-shrink-0 text-gray-400 rounded-lg hover:bg-gray-700 h-14 w-14"
          >
            {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
            </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`w-4 h-4 ${!sidebarExpanded && "rotate-180"} `}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </nav>
      <Submenus
        submenus={submenus}
        userProfile={userProfile}
        storage={storage}
        setStorage={setStorage}
      />
    </>
  );
};

const Submenus = ({
  submenus,
  userProfile,
  storage,
  setStorage
}: {
  submenus: submenuType;
  storage: any;
  setStorage: any;
  userProfile: any;
}) => {
  const [subMenusExpanded, setSidebarExpanded] = useState(storage?.subMenusExpanded || false);
  const isMount = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMount.current) {
      setStorage({ ...storage, subMenusExpanded });
    }
    isMount.current = true;
  }, [subMenusExpanded]);

  return (
    <aside className="relative hidden bg-white border-t border-r border-gray-200 group md:flex-shrink-0 md:flex">
      <div
        className={classNames(
          submenus
            ? subMenusExpanded
              ? "w-40 p-2" // shows full submenus
              : "w-auto " // shows small submenus
            : "w-0", // hide submenus
          "relative flex flex-col h-full overflow-y-auto transition-all duration-400 ease-in-out"
        )}
      >
        <nav className="space-y-1" aria-label="Sidebar">
          {submenus
            ?.filter((menu) => permissionScope(menu.permission, userProfile?.role!))
            .map((item, idx) => {
              return item.disable ? (
                <p
                  data-for="navSubmenus"
                  data-tip={item.name}
                  className="flex items-center px-2 py-2 space-x-2 text-sm text-gray-300 rounded-md "
                >
                  <item.icon className="relative z-10 w-4 h-4" aria-hidden="true" />
                  <span
                    className={`truncate ${
                      subMenusExpanded ? "block opacity-100" : "hidden opacity-0"
                    }`}
                  >
                    {item.name}
                  </span>
                </p>
              ) : (
                <NavLink
                  to={item.path}
                  key={idx}
                  data-for="navSubmenus"
                  data-tip={item.name}
                  onClick={(e) => {
                    if (item.name === "Log out") {
                      e.preventDefault();
                      logout(dispatch);
                    }
                  }}
                  className={`
                    flex items-center px-2 py-2 text-sm text-gray-600 rounded-md space-x-2
                    ${
                      window.location.pathname === item.path
                        ? "bg-gray-100 text-gray-900"
                        : "hover:text-gray-900 hover:bg-gray-100 "
                    }`}
                >
                  {item.icon && <item.icon className="relative z-10 w-4 h-4" aria-hidden="true" />}

                  <span
                    className={`truncate ${
                      subMenusExpanded ? "block opacity-100" : "hidden opacity-0"
                    }`}
                  >
                    {item.name}
                  </span>
                </NavLink>
              );
            })}
        </nav>
        {submenus && (
          <ReactTooltip
            id="navSubmenus"
            place="right"
            type="dark"
            effect="solid"
            delayShow={200}
            // disable={subMenusExpanded}
          />
        )}
      </div>

      {/* collapse button */}
      <div className="absolute flex items-center justify-end top-[50%] right-0">
        <div
          onClick={() => setSidebarExpanded(!subMenusExpanded)}
          className="px-1 py-2 text-gray-500 transition-all ease-in-out bg-gray-100 opacity-100 cursor-pointer duration-400 rounded-l-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`w-4 h-4 ${!subMenusExpanded && "rotate-180"} `}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </aside>
  );
};

export default withRouter(LayoutDefaultSidebar);
