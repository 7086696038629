import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const defaultToolbar = [
  {
    name: "history",
    items: ["undo", "redo"]
  },
  {
    name: "styles",
    items: ["styles"]
  },

  {
    name: "formatting",
    items: ["bold", "italic"]
  },
  {
    name: "alignment",
    items: ["alignleft", "aligncenter", "alignright", "alignjustify"]
  },

  {
    name: "indentation",
    items: ["bullist", "numlist", "outdent", "indent"]
  }
];
const MyEditor = ({
  initialValue,
  onChange,
  onBlur,
  init,
  onImageUpload
}: {
  initialValue: string;
  onChange: any;
  onBlur?: any;
  init?: any;
  onImageUpload?: (file: any) => Promise<string>;
}) => {
  const [initialState, setInitialState] = useState("");
  const [localState, setLocalState] = useState("");

  const hasInputChanged = useRef(false);

  useEffect(() => {
    // the initial state will never be changed even this component is re-rendered
    setInitialState(initialValue);

    // in case of when editing draft email
    if (initialValue) setLocalState(initialValue);
  }, []);

  useEffect(() => {
    // called onChange when the event comes from onEditorChange
    if (hasInputChanged.current) onChange(localState);
  }, [localState]);

  const handleUpdate = (value: any, editor: any) => {
    hasInputChanged.current = true;
    setLocalState(value);
  };

  let toolbar = [...defaultToolbar];
  if (init?.toolbar?.length) toolbar = [...toolbar, ...init.toolbar];
  const defaultPlugins: string[] = init?.plugins || ["lists"];
  return (
    <Editor
      initialValue={initialState}
      value={localState}
      onBlur={onBlur}
      tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
      init={{
        height: init?.height || 500,
        plugins: [...defaultPlugins, "wordcount"].join(" "),
        paste_as_text: init?.paste_as_text ?? true,
        invalid_elements: init?.invalid_elements ?? "h1",
        paste_data_images: init?.paste_data_images || false,
        menubar: false,
        toolbar,
        toolbar_sticky: true,
        image_advtab: false,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: "body { font-family:Montserrat,Helvetica,Arial,serif font-size:14px }",
        contextmenu: "copy cut paste redo undo | link",
        style_formats: init?.style_formats || [
          {
            title: "Headings",
            items: [
              { title: "Heading 2", format: "h2" },
              { title: "Heading 3", format: "h3" },
              { title: "Heading 4", format: "h4" },
              { title: "Heading 5", format: "h5" },
              { title: "Heading 6", format: "h6" }
            ]
          },
          {
            title: "Inline",
            items: [
              { title: "Bold", format: "bold" },
              { title: "Italic", format: "italic" },
              { title: "Underline", format: "underline" },
              { title: "Strikethrough", format: "strikethrough" },
              { title: "Superscript", format: "superscript" },
              { title: "Subscript", format: "subscript" },
              { title: "Code", format: "code" }
            ]
          },
          {
            title: "Blocks",
            items: [
              { title: "Paragraph", format: "p" },
              { title: "Blockquote", format: "blockquote" },
              { title: "Div", format: "div" },
              { title: "Pre", format: "pre" }
            ]
          },
          {
            title: "Align",
            items: [
              { title: "Left", format: "alignleft" },
              { title: "Center", format: "aligncenter" },
              { title: "Right", format: "alignright" },
              { title: "Justify", format: "alignjustify" }
            ]
          }
        ],
        async images_upload_handler(blobInfo) {
          const blob = blobInfo.blob();
          if (blob && onImageUpload) {
            return onImageUpload(blob);
          }
        },
        init_instance_callback: (editor) => {
          try {
            const button = editor
              .getContainer()
              ?.getElementsByClassName("tox-statusbar__wordcount")[0] as HTMLButtonElement;
            button?.click();
          } catch (error) {
            console.log("Editor: getContainer failed");
          }
        }
      }}
      onEditorChange={handleUpdate}
    />
  );
};

export default React.memo(MyEditor);
