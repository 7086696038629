import { ITrackingActivity } from "@components/activity-controller";
import {
  ActivityCardContent,
  ActivityCardWrapper
} from "@components/activity-controller/components/index";
import { IMessage } from "@model/email";
import { ITask } from "@model/task";

export default function ActivityCard({
  activity,
  onSelectEmail,
  handleTaskEdit,
  handleTaskViewChange,
  handleTaskDelete
}: {
  activity: ITrackingActivity;
  onSelectEmail: (item: IMessage) => void;
  handleTaskEdit: (item: ITask) => void;
  handleTaskViewChange: (item: ITask) => void;
  handleTaskDelete: (id: number | string) => void;
}) {
  const { ref_type, ref, action } = activity;
  return (
    <ActivityCardWrapper refType={ref_type} refData={ref} action={action}>
      <ActivityCardContent
        activity={activity}
        onSelectEmail={onSelectEmail}
        handleTaskDelete={handleTaskDelete}
        handleTaskViewChange={handleTaskViewChange}
        handleTaskEdit={handleTaskEdit}
      />
    </ActivityCardWrapper>
  );
}
