import { useState, useMemo, ChangeEvent, KeyboardEvent, useEffect } from "react";
import { useWatch } from "react-hook-form";
import Select from "react-select";
import moment from "moment";

import { MyEditor, MultipleSelect } from "@components/index";
import {
  classNames,
  removeHtmlTags,
  trimmedString,
  numberWithCommas,
  removeCommas,
  getFlattenOptions,
  cn
} from "@utils/helpers";
import { SearchSelect } from "./forms/search-select";
import MessageInfo from "./forms/form.message-info";
import { countryOptions } from "@model/app";
import SelectDatePicker from "./select-date-picker";
import { getCustomSelectStyles } from "@utils/react-select.helpers";
import { InlineInputProps, InputMode } from "@models/model.form-field";

// const fetchDropdown = async (queries: queryType, endpoint: string) => {
//   const { data } = await get({ path: endpoint, queries });

//   if (!data.length) {
//     // show specific message for project search
//     if (endpoint === 'projects/names') return [{ value: 'new', label: 'Other building name' }];
//     return [];
//   }

//   const mapFn = (item: any) => {
//     switch (endpoint) {
//       case 'locations/search':
//         return { value: item.geo_id, label: item.location_format };
//       case 'projects/names':
//         return { value: item.ref_id, label: item.content };
//       default:
//         return null;
//     }
//   };

//   const filterFn = (item: any) => {
//     return item !== null;
//   };

//   return data.map(mapFn).filter(filterFn);
// }

// const fetchDebounced = debounce((inputValue: string, endpoint: string) => {
//   let queries: queryType = {}

//   if (endpoint === 'locations/search') {
//     // new search location endpoint
//     queries.q = inputValue;
//   } else if (endpoint === 'projects/names') {
//     queries = {
//       search: inputValue,
//       language_id: 'en',
//       country_id: 'TH',
//     };
//   }

//   return fetchDropdown(queries, endpoint);
// }, 500);

export function ReadOnlyField({ name, value, readonly, onClick, styles }: any): JSX.Element {
  const formattedValue = () => {
    if (typeof value === "string") {
      return value.split("_").join(" ");
    }

    return value;
  };

  const defaultCase = [
    "email",
    "en_name",
    "en_description",
    "sudonum_chat_link",
    "sudonum_notify_agent",
    "sudonum_uuid"
  ];
  const textTransform = defaultCase.includes(name) ? "" : "capitalize";

  return (
    <div className={cn("flex items-center group flex-1 w-full h-[40px]", styles)}>
      <div
        className={cn(
          `w-full flex items-center cursor-pointer ${textTransform} line-clamp-2`,
          !value && "text-gray-300",
          readonly && "cursor-not-allowed"
        )}
        onClick={onClick}
      >
        {formattedValue() || (readonly ? " " : "Click to add")}
      </div>
    </div>
  );
}

export function NumberStringField({
  name,
  Controller,
  control,
  register,
  value,
  onBlur,
  onKeyDown,
  handleChange,
  condition,
  autoFocus = true,
  options
}: any): JSX.Element | null {
  const [isFocus, setIsFocus] = useState(autoFocus);
  if (!condition) return null;

  function formatValue(event: ChangeEvent<HTMLInputElement>, onChange: any) {
    const formattedValue = numberWithCommas(removeCommas(event.target.value));
    onChange(formattedValue);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }: any) => {
        let borderClasses = "rounded-md";
        if (options?.txtLeading)
          borderClasses = "rounded-none border-l-0 rounded-tr-md rounded-br-md";
        return (
          <div className="relative flex w-full">
            {options?.txtLeading && (
              <span
                className={`inline-flex items-center px-3 text-gray-500 border border-r-0 ${
                  isFocus ? "border-purple-700" : "border-gray-300"
                } rounded-l-md bg-gray-50 sm:text-sm`}
              >
                {options?.txtLeading}
              </span>
            )}
            <input
              type="text"
              className={`flex-1 min-w-0 block w-full h-[40px] ${borderClasses} border-gray-300 rounded-md  shadow-sm focus:ring-0 focus:border-purple-700 sm:text-sm`}
              autoFocus={autoFocus}
              {...register(name)}
              value={value}
              onFocus={() => setIsFocus(true)}
              onBlur={(e) => {
                onBlur(e);
                setIsFocus(false);
              }}
              onKeyDown={onKeyDown}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (handleChange) {
                  // handle from parent
                  formatValue(event, handleChange);
                  return;
                }
                formatValue(event, onChange);
              }}
            />
          </div>
        );
      }}
    />
  );
}

function InputField({
  inputType,
  name,
  register,
  value,
  onBlur,
  onKeyDown,
  condition,
  options,
  styles
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }
  let borderClasses = "rounded-md";
  if (options?.txtLeading) borderClasses = "rounded-none border-l-0 rounded-tr-md rounded-br-md";
  return (
    <div className="relative flex w-full">
      {options?.txtLeading && (
        <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-purple-700 border-rounded-300 rounded-l-md bg-gray-50 sm:text-sm">
          {options?.txtLeading}
        </span>
      )}
      <input
        type={inputType}
        {...register(name)}
        value={value}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        className={`flex-1 min-w-0 block w-full h-[40px]  border-gray-300 ${borderClasses} shadow-sm focus:ring-0 focus:border-purple-700 sm:text-sm ${styles}`}
        autoFocus
      />
    </div>
  );
}

function CheckboxField({
  name,
  Controller,
  control,
  value,
  onBlur,
  condition
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }: any) => (
        <input
          type="checkbox"
          className="w-[20px] h-[20px] border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
          checked={value === "1" || value === 1}
          onBlur={onBlur}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.checked ? "1" : "0");
          }}
        />
      )}
    />
  );
}

function TextareaField({ name, register, onBlur, onKeyDown, condition }: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <textarea
      id={name}
      name={name}
      {...register(name)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      rows={3}
      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-purple-700 sm:text-sm"
      autoFocus
    />
  );
}

export const SelectionField = ({
  inputType,
  name,
  Controller,
  control,
  options,
  onBlur,
  condition,
  styles
}: any): JSX.Element | null => {
  if (!condition) {
    return null;
  }

  return (
    <Controller
      key={`${name}_${inputType}`}
      name={name}
      control={control}
      render={({ field }: any) => {
        return (
          <Select
            defaultValue={options.optionValue}
            options={options.inputOptions}
            onBlur={onBlur}
            onChange={(opt) => {
              field.onChange(opt?.value || null);

              if (options?.callBack) options.callBack(opt?.value);
            }}
            styles={styles ? styles : getCustomSelectStyles(options.optionValue)}
            menuPortalTarget={options.menuPortalTarget ? document.body : null}
            autoFocus
          />
        );
      }}
    />
  );
};

function DropdownField({
  name,
  register,
  options,
  onBlur,
  condition,
  value
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <select
      {...register(name)}
      onBlur={onBlur}
      defaultValue={options.optionValue}
      className="block w-full pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-1 focus:border-purple-700 focus:ring-0 sm:text-sm"
      autoFocus
    >
      {options.inputOptions?.length &&
        options.inputOptions?.map((option: any, idx: number) => (
          <option key={idx} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  );
}

function ButtonGroupField({
  name,
  Controller,
  control,
  options,
  value,
  condition
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <div className="relative z-0 inline-flex rounded-md shadow-sm">
      <Controller
        key={`${name}_btn_group`}
        name={name}
        control={control}
        render={({ field: { onChange } }: any) =>
          options.inputOptions?.map((option: { label: string; value: string }, idx: number) => {
            let classes =
              "relative inline-flex items-center border border-gray-300 -ml-px px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:border-indigo-500 focus:ring-indigo-500";

            if (idx === 0) {
              classes =
                "relative inline-flex items-center border border-gray-300 rounded-l-md px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:border-indigo-500 focus:ring-indigo-500";
            } else if (idx + 1 === options.inputOptions?.length) {
              classes =
                "relative inline-flex items-center -ml-px border border-gray-300 rounded-r-md px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:border-indigo-500 focus:ring-indigo-500";
            }

            const cssClassNames = classNames(
              value === option.value ? "text-white bg-purple-700" : "text-gray-700 bg-white",
              classes
            );

            return (
              <button
                type="button"
                key={option.value}
                className={cssClassNames}
                onClick={() => onChange(option.value)}
              >
                {option.label}
              </button>
            );
          })
        }
      />
    </div>
  );
}

function AsyncSelectionField({
  name,
  Controller,
  control,
  options,
  // fetchFn,
  onBlur,
  condition,
  isMulti = false
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <Controller
      key={`${name}_dropdown`}
      name={name}
      control={control}
      render={({ field: { onChange } }: any) => (
        <SearchSelect
          // configs={{ key: 'async-selection-field', callback: (value: string) => fetchFn(value, options.endpoint)}}
          configs={options}
          autoFocus
          isMulti={isMulti}
          defaultOptions={options.defaultOptions}
          isClearable
          defaultValue={options.defaultValue}
          noOptionsMessage={() => "Start typing"}
          onBlur={onBlur}
          onChange={(event: any) => {
            onChange(event?.value || null);
            if (options && options?.callBack) {
              options.callBack(event);
            }
          }}
        />
      )}
    />
  );
}

function MultipleSelectionField({
  name,
  Controller,
  control,
  options,
  onBlur,
  condition
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <Controller
      key={`${name}_multi_dropdown`}
      name={name}
      control={control}
      render={({ field: { onChange, value } }: any) => {
        const tagValues = value ? JSON.parse(value) : [];

        return (
          <MultipleSelect
            // configs={{ key: 'async-selection-field', callback: (value: string) => fetchFn(value, options.endpoint)}}
            configs={options}
            autoFocus
            defaultOptions={options.defaultOptions}
            isClearable
            defaultValue={options.defaultOptions.filter((option: any) =>
              tagValues?.includes(option?.value)
            )}
            onBlur={onBlur}
            onChange={(event: any) => {
              onChange(event ? JSON.stringify(event.map((item: any) => item.value)) : null);
              if (options && options?.callBack) options.callBack(event);
            }}
          />
        );
      }}
    />
  );
}

function EditorField({
  name,
  Controller,
  control,
  value,
  onBlur,
  condition,
  options
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  const initialValue = value ? value : "";

  return (
    <Controller
      key={`${name}_editor`}
      name={name}
      control={control}
      render={({ field: { onChange } }: any) => (
        <MyEditor
          initialValue={initialValue}
          onBlur={onBlur}
          init={options && "init" in options ? options.init : undefined}
          onImageUpload={options && "onImageUpload" in options ? options.onImageUpload : undefined}
          onChange={(event: any) => onChange(event)}
        />
      )}
    />
  );
}

function DatePickerField({
  name,
  control,
  Controller,
  condition,
  setReadMode
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  function handleChangeSelectDatePicker(date: Date | null, onChange: any) {
    const dateStr = date !== null ? moment(date).format("YYYY-MM-DD") : "";
    onChange(dateStr);
    setReadMode();
  }

  return (
    <Controller
      name={`${name}`}
      control={control}
      rules={{ required: true }}
      render={({ field: { name, value, onChange } }: any) => (
        <SelectDatePicker
          name={name}
          value={value}
          onBlur={setReadMode}
          onChange={(date) => handleChangeSelectDatePicker(date, onChange)}
        />
      )}
    />
  );
}

function DateTimePickerField({
  inputType,
  name,
  register,
  value,
  onBlur,
  onKeyDown,
  condition
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <input
      type="datetime-local"
      {...register(name)}
      value={value}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className="block w-full h-[40px] border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-purple-700 sm:text-sm"
      autoFocus
    />
  );
}

function NativeDatePicker({
  name,
  register,
  value,
  onBlur,
  onKeyDown,
  condition
}: any): JSX.Element | null {
  if (!condition) {
    return null;
  }

  return (
    <input
      type="date"
      {...register(name)}
      value={value}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className="block w-full h-[40px] border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-purple-700 sm:text-sm"
      autoFocus
    />
  );
}

export function InputWithPrefix({
  name,
  register,
  value,
  onBlur,
  onKeyDown,
  condition,
  Controller,
  control,
  options,
  prefixName,
  autoFocus,
  handleChange
}: any): JSX.Element | null {
  const [isFocusSelect, setFocusSelect] = useState(false);
  const [isFocusText, setFocusText] = useState(autoFocus);

  useEffect(() => {
    if (isFocusSelect || isFocusText) return;
    onBlur && onBlur();
  }, [isFocusSelect, isFocusText]);

  if (!condition) {
    return null;
  }

  function formatValue(event: ChangeEvent<HTMLInputElement>, onChange: any) {
    const formattedValue = numberWithCommas(removeCommas(event.target.value));
    onChange(formattedValue);
  }

  const borderClasses = options?.inputOptions
    ? "rounded-none border-l-0 rounded-tr-md rounded-br-md"
    : "rounded-md";

  const selectStyles = {
    ...getCustomSelectStyles(options.optionValue, false, {
      border: "none",
      background: "transparent",
      width: "90px"
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      width: "30px"
    })
  };

  const borderColor = isFocusSelect || isFocusText ? "border-purple-700" : "border-gray-300";

  return (
    <div className="relative flex w-full">
      <div
        className={`inline-flex items-center text-gray-500 pr-1 border border-r-0 ${borderColor} rounded-l-md bg-gray-50 sm:text-sm`}
      >
        <Controller
          key={`${prefixName}`}
          name={prefixName}
          control={control}
          render={({ field: { onChange } }: any) => {
            return (
              <Select
                placeholder="Select"
                defaultValue={options.optionValue}
                options={options.inputOptions}
                onChange={(opt) => {
                  onChange(opt?.value || null);
                  if (options?.callBack) options.callBack(opt?.value);
                }}
                styles={selectStyles}
                menuPortalTarget={options.menuPortalTarget ? document.body : null}
                onFocus={() => {
                  setFocusSelect(true);
                }}
                components={{
                  IndicatorSeparator: () => null
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFocusSelect(false);
                  }, 100);
                }}
              />
            );
          }}
        />
      </div>

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }: any) => {
          return (
            <input
              onFocus={() => {
                setFocusText(true);
              }}
              type="text"
              className={`flex-1 min-w-0 block w-full h-[40px] ${borderClasses} ${borderColor} rounded-md  shadow-sm focus:ring-0 focus:border-purple-700 sm:text-sm`}
              autoFocus={isFocusText}
              {...register(name)}
              value={value}
              onBlur={() => {
                setTimeout(() => {
                  setFocusText(false);
                }, 100);
              }}
              onKeyDown={onKeyDown}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                formatValue(event, handleChange || onChange);
              }}
            />
          );
        }}
      />
    </div>
  );
}

export default function InlineInput({
  name,
  inputType = "text",
  register,
  Controller,
  control,
  customValue = null,
  options,
  readonly = false,
  editonly = false,
  inputStyles,
  inlineInputStyles,
  readOnlyStyles,
  secondaryName
}: InlineInputProps): JSX.Element {
  const value = useWatch({ control, name });
  const secondaryValue = useWatch({ control, name: secondaryName || "" });
  const [mode, setMode] = useState(InputMode.READ);

  const txtValue = useMemo(() => {
    if (customValue) return customValue;

    let txtValue = value;

    switch (inputType) {
      case "checkbox":
        txtValue = value === "1" || value === 1 ? "Yes" : "No";
        break;
      case "numberString":
        txtValue = numberWithCommas(txtValue);
        break;
      default:
        txtValue = removeHtmlTags(value || "");
        txtValue = trimmedString(txtValue, 200);
    }

    // bedrooms 0 is Studio
    if (name === "bedrooms" && value === "0") {
      txtValue = "Studio";
    } else if (name === "stage_id") {
      // populate stage name from inputOptions
      if (!value) return "";

      if (options.optionValue.value === value) {
        return options.optionValue.label;
      }

      const option = options.inputOptions?.find((opt: any) => opt.value === value);
      return option.label;
    } else if (inputType === "asyncSelect") {
      return options?.defaultValue?.label || value || "";
    } else if (inputType === "asyncSelect-multiple") {
      if (!value) return "";

      const options = value ?? [];
      const optionLabels = options.map((opt: any) => opt?.label);

      return optionLabels.join(", ");
    } else if (name === "country_id") {
      // map country_id with label
      return countryOptions.find((c) => c.value === value)?.label || "";
    } else if (["date-time-picker"].includes(inputType)) {
      if (moment(txtValue).isValid()) {
        return moment(txtValue).format("DD/MM/YYYY  h:mm A");
      }
    } else if (["native-date-picker"].includes(inputType)) {
      if (moment(txtValue).isValid()) {
        return moment(txtValue).format("DD/MM/YYYY");
      }
    } else if (["react-selection", "dropdown"].includes(inputType)) {
      const flattenInputOptions = getFlattenOptions(options.inputOptions);
      const option = flattenInputOptions?.find(
        // eslint-disable-next-line
        (opt: any) => opt.value == value || opt.label == value
      );
      if (option) {
        return option?.label;
      }
      return "";
    } else if (name === "tags") {
      txtValue = value ? JSON.parse(value) : "";
      if (txtValue) txtValue = txtValue?.join(", ");
    } else if (["input-prefix"].includes(inputType)) {
      const option = options.inputOptions?.find((opt: any) => opt.value === secondaryValue);
      if (option) {
        return `${option?.label} ${numberWithCommas(value)}`;
      }
    }

    const label = [];
    // adding leading/trailing
    if (txtValue && options?.txtLeading) label.push(options.txtLeading);
    label.push(txtValue);
    // if (txtValue && options?.txtTrailing) label.push(options.txtTrailing)

    return label.join(" ");
  }, [value, secondaryValue]);

  const guideline = options?.guideline;

  const guidelineCountCharacter = useMemo(() => {
    // check condition for show count character
    const isShowGuidelineCountCharacter = guideline?.isShowGuidelineCountCharacter;
    const condition = guideline?.condition;
    if (!isShowGuidelineCountCharacter && !condition) return 0;

    // clean up value
    let txtValue = removeHtmlTags(value || "");

    return txtValue.length;
  }, [value]);

  const isGuidelineWarning = useMemo(() => {
    const condition = guideline?.condition;
    // check condition for show count character
    if (!condition) return false;

    const { min, max } = condition;
    if (guidelineCountCharacter < min) {
      return true;
    }
    if (guidelineCountCharacter > max) {
      return true;
    }
    // clean up value
    return false;
  }, [guidelineCountCharacter]);

  const isReadMode = mode === InputMode.READ;
  const setEditMode = () => setMode(InputMode.EDIT);
  const setReadMode = () => setMode(InputMode.READ);
  const handlePressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setMode(InputMode.READ);
    }
  };

  return (
    <div className={`flex items-center w-full min-h-[40px] ${inlineInputStyles}`}>
      {!editonly && (readonly || isReadMode) ? (
        <ReadOnlyField
          name={name}
          value={txtValue}
          readonly={readonly}
          onClick={setEditMode}
          styles={readOnlyStyles}
        />
      ) : (
        <div className="w-full">
          <NumberStringField
            name={name}
            Controller={Controller}
            control={control}
            register={register}
            value={value}
            onBlur={setReadMode}
            options={options}
            onKeyDown={handlePressEnter}
            condition={["numberString"].includes(inputType)}
          />

          <InputField
            inputType={inputType}
            name={name}
            register={register}
            value={value}
            onBlur={setReadMode}
            options={options}
            onKeyDown={handlePressEnter}
            styles={inputStyles}
            condition={["number", "text"].includes(inputType)}
          />

          <CheckboxField
            name={name}
            Controller={Controller}
            control={control}
            value={value}
            onBlur={setReadMode}
            condition={["checkbox"].includes(inputType)}
          />

          <TextareaField
            name={name}
            register={register}
            onBlur={setReadMode}
            onKeyDown={handlePressEnter}
            condition={["textarea"].includes(inputType)}
          />

          <SelectionField
            inputType={inputType}
            name={name}
            Controller={Controller}
            control={control}
            options={options}
            onBlur={setReadMode}
            styles={inputStyles}
            condition={["react-selection"].includes(inputType)}
          />

          <AsyncSelectionField
            name={name}
            Controller={Controller}
            control={control}
            options={options}
            // fetchFn={fetchDebounced}
            onBlur={setReadMode}
            condition={["asyncSelect"].includes(inputType)}
          />

          <AsyncSelectionField
            name={name}
            Controller={Controller}
            control={control}
            options={options}
            // fetchFn={fetchDebounced}
            onBlur={setReadMode}
            isMulti={true}
            condition={["asyncSelect-multiple"].includes(inputType)}
          />

          <MultipleSelectionField
            name={name}
            Controller={Controller}
            control={control}
            register={register}
            options={options}
            onBlur={setReadMode}
            condition={["select-multiple"].includes(inputType)}
          />

          <DropdownField
            name={name}
            value={value}
            register={register}
            options={options}
            onBlur={setReadMode}
            condition={["dropdown"].includes(inputType)}
          />

          <ButtonGroupField
            name={name}
            Controller={Controller}
            control={control}
            options={options}
            value={value}
            condition={["btn-group"].includes(inputType)}
          />

          <EditorField
            name={name}
            Controller={Controller}
            control={control}
            value={value}
            onBlur={setReadMode}
            options={options}
            condition={["editor"].includes(inputType)}
          />

          <DatePickerField
            name={name}
            Controller={Controller}
            control={control}
            setReadMode={setReadMode}
            condition={["date-picker"].includes(inputType)}
          />

          <NativeDatePicker
            name={name}
            register={register}
            value={value}
            onBlur={setReadMode}
            onKeyDown={handlePressEnter}
            condition={["native-date-picker"].includes(inputType)}
          />

          <DateTimePickerField
            inputType={inputType}
            name={name}
            register={register}
            value={value}
            onBlur={setReadMode}
            onKeyDown={handlePressEnter}
            condition={["date-time-picker"].includes(inputType)}
          />

          <InputWithPrefix
            name={name}
            prefixName={secondaryName}
            Controller={Controller}
            control={control}
            value={value}
            secondaryValue={secondaryValue}
            register={register}
            options={options}
            onBlur={setReadMode}
            autoFocus
            condition={["input-prefix"].includes(inputType)}
          />
          {guideline && (
            <MessageInfo isWarning={isGuidelineWarning} className="mt-2">
              <div className="flex justify-between flex-1">
                {guideline?.label && <p>{guideline.label}</p>}
                {guideline?.isShowGuidelineCountCharacter && (
                  <p className="hidden sm:block">{guidelineCountCharacter} CHARACTERS.</p>
                )}
              </div>
            </MessageInfo>
          )}
        </div>
      )}
    </div>
  );
}
