import { classNames } from "@utils/helpers";

const ConfirmDialog = ({
  options,
  onConfirm,
  onCancel
}: {
  options: any;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <div className="inline-block max-w-sm pb-4">
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 ">
          <div className="mt-2">
            <p className="text-sm text-gray-500">{options?.subTitle}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-5">
        <button
          type="button"
          className={classNames(
            "inline-flex justify-center w-auto mr-2 px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm",
            options.btnStyle === "positive"
              ? "bg-green-600 focus:ring-green-500 hover:bg-green-700"
              : "bg-red-600 focus:ring-red-500 hover:bg-red-700"
          )}
          onClick={onConfirm}
        >
          {options?.confirmationButtonText ?? "Continue"}
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-auto px-4 py-2 ml-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={onCancel}
        >
          {options?.cancelButtonText ?? "Cancel"}
        </button>
      </div>
    </div>
  );
};

export default ConfirmDialog;
