import { Action } from "../actions/action.index";
import { ActionType } from "../actions/action.types";
import { INotificationMenu, NotificationAlertType } from "@model/notification";

interface INotifications {
  notificationMenu: INotificationMenu;
  notificationAlert: NotificationAlertType | null;
}

const initialState = {
  notificationAlert: null, // from Firebase
  notificationMenu: {
    data: [],
    meta: {
      nextPage: 1,
      lastPage: 1,
      unreadCount: 0
    }
  }
};

const reducer = (state: INotifications = initialState, action: Action): INotifications => {
  switch (action.type) {
    case ActionType.SET_NOTIFICATION_ALERT:
      return {
        ...state,
        notificationAlert: action.payload
      };
    case ActionType.UPDATE_NOTIFICATION_MENU:
      const currentItems = state.notificationMenu?.data || [];
      return {
        ...state,
        notificationMenu: {
          data: [...currentItems, ...action.payload.data],
          meta: action.payload.meta
        } // all notification data including meta
      };
    case ActionType.SET_NOTIFICATION_MENU:
      return {
        ...state,
        notificationMenu: action.payload
      };
    case ActionType.RESET_NOTIFICATION_MENU:
      return {
        ...state,
        notificationMenu: initialState.notificationMenu
      };
    default:
      return state;
  }
};

export default reducer;
