import { Fragment, ReactNode, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";

import { selectModal } from "@state/selectors";
import { ActionType } from "@state/actions/action.types";
import { XIcon } from "@heroicons/react/solid";

// import Portal from './portal'
export default function Modal({
  modalKey,
  title,
  closableBackdrop,
  children
}: {
  modalKey: string;
  title?: string;
  children: ReactNode;
  closableBackdrop?: any;
}) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const appendRef = useRef(false);
  const modalState = useSelector(selectModal);

  const onDismiss = () => {
    dispatch({
      type: ActionType.TOGGLE_MODAL,
      payload: {
        key: null,
        actionCallback: null
      }
    });
    modalState?.actionCallback && modalState?.actionCallback(false);
  };

  const onCloseDialog = () => {
    // NOTE: by default all modal can close by click on the backdrop
    if (closableBackdrop) onDismiss();
  };

  // fix autofocus issue
  // https://github.com/tailwindlabs/headlessui/discussions/741
  const handleMouseDown = () => {
    const tinymceEle = document.getElementsByClassName("tox-tinymce-aux");
    const editorEle = document.getElementsByClassName("tox-tinymce");
    if (!appendRef.current && tinymceEle?.length && editorEle?.length) {
      appendRef.current = true;
      editorEle[0].append(tinymceEle[0]);
      // console.log('append');
    }
  };

  return (
    <Transition.Root show={!!modalState.key && modalKey === modalState.key} as={Fragment}>
      <Dialog
        as="div"
        onMouseDown={handleMouseDown}
        className="fixed inset-0 z-[100] overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onCloseDialog}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full px-6 text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:w-auto sm:my-8 sm:align-middle">
              {title && (
                <div className="flex justify-between items-center my-3 sm:mt-5">
                  <Dialog.Title as="h3" className="font-medium leading-6 text-gray-900 ">
                    {title}
                  </Dialog.Title>
                  {closableBackdrop && (
                    <XIcon
                      onClick={() => onDismiss()}
                      className="w-5 h-5 text-red-500 hover:cursor-pointer hover:text-red-700"
                    />
                  )}
                </div>
              )}
              <div ref={cancelButtonRef} className="w-auto">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
