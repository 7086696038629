export default function LoadMoreBtn({
  items,
  total,
  loadMore
}: {
  items: any[] | null;
  total: number;
  loadMore: () => void;
}) {
  return !!items?.length && total > items.length ? (
    <div className="flex items-center justify-center py-3">
      <p
        className="text-xs text-blue-400 underline cursor-pointer hover:text-blue-600"
        onClick={loadMore}
      >
        Show More
      </p>
    </div>
  ) : null;
}
