import { deleteItem } from "@utils/api";

class DeleteTaskService {
  endpoint: string;

  static async call(id: number | string): Promise<any> {
    const service = new DeleteTaskService(`tasks/${id}`);
    return await service.call();
  }

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async call(): Promise<any> {
    const path = this.endpoint;
    return await deleteItem({ path, queries: {} });
  }
}

export default DeleteTaskService;
