import { UserRole } from "@model/app";
import { get } from "@utils/api";

type TeamUserType = {
  id: number;
  name: string;
  email: string;
  role: UserRole;
};

class GetTeamUsersDropdownService {
  endpoint: string;
  queries: object;

  constructor(queries: object) {
    this.endpoint = "teams/users/search";
    this.queries = queries;
  }

  static call(queries: object) {
    return new GetTeamUsersDropdownService(queries).getTeamUsers();
  }

  async getTeamUsers() {
    const { data }: { data: TeamUserType[] } = await get({
      path: this.endpoint,
      queries: this.queries
    });

    if (!data.length) {
      return [];
    }

    return data.map((item) => ({
      label: `${item.name} ${item.email && `- ${item.email}`} (${item.role})`,
      value: item.id
    }));
  }
}

export default GetTeamUsersDropdownService;
