import React from "react";
import { IconType } from "./icon.type";

function Filter({ className }: IconType): JSX.Element {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fat"
      data-icon="filter-list"
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M329.5 64H22.48c-18.9 0-29.33 21.5-17.41 35.88L112 225.8V368c0 7.828 3.812 15.17 10.25 19.66l79.1 55.98C206.5 446.6 211.3 448 215.9 448C228.3 448 240 438.2 240 423.1V225.8l106.9-125.9C358.8 85.5 348.4 64 329.5 64zM334.7 89.52L224 219.9v204.1C224 429.5 219.3 432 215.9 432c-1.582 0-3.09-.4941-4.482-1.469l-80.03-56.01C129.3 373 128 370.6 128 368V219.9L17.39 89.66C15.46 87.34 15.88 85.09 16.58 83.61C17.09 82.52 18.68 80 22.48 80h307c3.799 0 5.395 2.521 5.904 3.605C336.1 85.09 336.5 87.34 334.7 89.52zM424 104h80c4.422 0 8-3.578 8-7.1S508.4 88 504 88h-80C419.6 88 416 91.58 416 95.1S419.6 104 424 104zM504 248h-176C323.6 248 320 251.6 320 256s3.578 8 8 8h176C508.4 264 512 260.4 512 256S508.4 248 504 248zM504 408h-176C323.6 408 320 411.6 320 416s3.578 8 8 8h176c4.422 0 8-3.562 8-8S508.4 408 504 408z"
      />
    </svg>
  );
}

export default Filter;
