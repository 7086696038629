import { Menu, Transition } from "@headlessui/react";
import { Placement } from "@popperjs/core";
import classNames from "classnames";
import { Manager, Popper, Reference } from "react-popper";

export type DropdownMenuStyle = {
  menu?: string;
  menuButton?: string;
  menuItems?: string;
};

interface IPopperOptions {
  placement?: Placement;
  modifiers?: any[];
}

interface IDropdownMenu {
  style: DropdownMenuStyle;
  buttonLabel?: string | JSX.Element;
  children: JSX.Element;
  popperOptions?: IPopperOptions;
}

const DropdownMenu = ({ buttonLabel, children, style, popperOptions }: IDropdownMenu) => {
  return (
    <Menu as="div" className={classNames("relative", style.menu ? style.menu : "")}>
      {({ open }) => {
        return (
          <Manager>
            <Reference>
              {({ ref }) => (
                <Menu.Button
                  className={classNames(
                    "flex items-center p-1 bg-white rounded-md hover:text-gray-600 focus:outline-none focus:ring-0",
                    style.menuButton ? style.menuButton : ""
                  )}
                  ref={ref}
                >
                  <span className="sr-only">Open options</span>
                  {buttonLabel ? buttonLabel : <DefaultMenuIcon />}
                </Menu.Button>
              )}
            </Reference>

            <Popper
              strategy="fixed"
              placement={popperOptions?.placement ?? "bottom-start"}
              modifiers={popperOptions?.modifiers ?? []}
            >
              {({ ref, style: popperStyle, isReferenceHidden }) => {
                return open && !isReferenceHidden ? (
                  <div
                    className={classNames(
                      "absolute z-30 origin-top-right bg-white rounded-md shadow-lg focus:outline-none",
                      open ? "ring-black ring-1 ring-opacity-5" : "",
                      style.menuItems ? style.menuItems : ""
                    )}
                    ref={ref}
                    style={popperStyle}
                  >
                    <Transition.Child
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items>{children}</Menu.Items>
                    </Transition.Child>
                  </div>
                ) : null;
              }}
            </Popper>
          </Manager>
        );
      }}
    </Menu>
  );
};

const DefaultMenuIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="ellipsis-vertical"
    className="w-4 h-4 svg-inline--fa fa-ellipsis-vertical"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 512"
  >
    <path
      fill="currentColor"
      d="M128 64c0-35.39-28.62-64-64-64S0 28.61 0 64s28.62 64 64 64S128 99.39 128 64zM128 256c0-35.39-28.62-64-64-64S0 220.6 0 256s28.62 64 64 64S128 291.4 128 256zM128 448c0-35.39-28.62-64-64-64s-64 28.61-64 64s28.62 64 64 64S128 483.4 128 448z"
    ></path>
  </svg>
);

export default DropdownMenu;
