import { Dispatch } from "redux";
import memoize from "memoize-one";

import { ActionType } from "./action.types";
import { Action } from "./action.index";
import { get, post, wrapPromise } from "@utils/api";
import type { GlobalEmailType } from "@model/email";
import {
  eraseCookie,
  getViewUser,
  updateLocalStoragePreferences,
  updateLocalStorageSuperAdmin
} from "@utils/helpers";
import { AppStorageType } from "@model/app";
import { IMedia } from "@model/media";
import { fcmTokenAction, FcmTokenActionType } from "@components/notification-controller/helper";
import { resetNotificationMenu } from "@state/actions/action.notification";

export const updateProfile = (dispatch: Dispatch<Action>, payload: any) => {
  dispatch({ type: ActionType.INITIALIZE, payload });
};

export const updatePreferences = (dispatch: Dispatch<Action>, payload: any) => {
  dispatch({
    type: ActionType.UPDATE_PREFERENCE,
    payload
  });
};

export const updateInputOptions = (dispatch: Dispatch<Action>, payload: any) => {
  dispatch({
    type: ActionType.UPDATE_INPUT_OPTIONS,
    payload
  });
};

export const updateViewUser = (dispatch: Dispatch<Action>, payload: any) => {
  dispatch({
    type: ActionType.UPDATE_VIEW_USER,
    payload
  });
};

export const updateIsSuperAdmin = (dispatch: Dispatch<Action>, payload: any) => {
  dispatch({
    type: ActionType.UPDATE_IS_SUPER_ADMIN,
    payload
  });
};

export const updateIsSystemSuperAdmin = (dispatch: Dispatch<Action>, payload: any) => {
  dispatch({
    type: ActionType.UPDATE_IS_SYSTEM_SUPER_ADMIN,
    payload
  });
};

export const initAppData = async (dispatch: Dispatch, viewUserId: number | null) => {
  try {
    const { data } = await get({ path: "me", queries: { view_user_id: viewUserId } });
    updateProfile(dispatch, data);
    updatePreferences(dispatch, data.preferences);
    updateInputOptions(dispatch, data.input_options);
    updateIsSuperAdmin(dispatch, data.role === "super-admin");
    updateIsSystemSuperAdmin(dispatch, data.is_system_super_admin || data.role === "super-admin");
    updateLocalStoragePreferences(data.preferences);
    const previousViewUser = getViewUser();
    if (
      viewUserId === null &&
      previousViewUser?.value !== null &&
      (data.is_system_super_admin || data.role === "super-admin")
    ) {
      updateViewUser(dispatch, previousViewUser);
    } else {
      updateLocalStorageSuperAdmin({
        view_user: { label: data.email, value: viewUserId },
        is_system_super_admin: data.is_system_super_admin || data.role === "super-admin"
      });
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAppResource = memoize((dispatch: Dispatch<Action>, viewUserId: number | null) => {
  return wrapPromise(initAppData(dispatch, viewUserId));
});

export const logout = async (dispatch: Dispatch<Action>) => {
  try {
    fcmTokenAction(FcmTokenActionType.remove);
    // reset Notification Menu data every time login
    resetNotificationMenu(dispatch);
    await post({ path: "logout", data: {} });
    const item = localStorage.getItem("app");
    // remove token from Storage
    if (item) {
      const obj: AppStorageType = JSON.parse(item);
      localStorage.setItem("app", JSON.stringify({ ...obj, token: "" }));
    }

    // remove cookie for auto login
    // console.log('eraseCookie')
    eraseCookie("lazudi_session");

    dispatch({ type: ActionType.INITIALIZE, payload: null });
  } catch (error) {
    console.log(error);
  }
};

// HANDLE MODAL

export const openGlobalModal = <T = boolean>(
  dispatch: Dispatch<Action>,
  payload: any
): Promise<T> => {
  return new Promise<T>((resolve) => {
    dispatch({
      type: ActionType.TOGGLE_MODAL,
      payload: {
        key: payload.key,
        actionCallback: resolve,
        options: payload.options
      }
    });
  });
};

export const resetGlobalModal = (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.TOGGLE_MODAL,
    payload: {
      key: null,
      actionCallback: null
    }
  });
};

export const onConfirmGlobalModal = (dispatch: Dispatch<Action>, payload: any, data?: any) => {
  payload?.actionCallback && payload.actionCallback(data === false ? false : data || true);
  resetGlobalModal(dispatch);
};

export const closeGlobalModal = (dispatch: Dispatch<Action>, payload: any) => {
  payload?.actionCallback && payload.actionCallback(false);
  resetGlobalModal(dispatch);
};

export const openGlobalEmail = async (dispatch: Dispatch<Action>, payload: GlobalEmailType) => {
  openGlobalModal(dispatch, { key: "global_email_form" });

  // SETUP EMAIL DATA BELOW

  dispatch({
    type: ActionType.TOGGLE_GLOBAL_EMAIL,
    payload: {
      refer: payload.refer,
      data: payload.data
    }
  });
};

export const updateProfileImageAction = (dispatch: Dispatch<Action>, payload: IMedia) => {
  dispatch({
    type: ActionType.UPDATE_PROFILE_IMAGE,
    payload
  });
};
