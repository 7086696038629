import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const templateValidationRules = yupResolver(
  yup.object({
    name: yup.string().required("Required field"),
    subject: yup.string().required("Required field"),
    template: yup.string().required("Required field")
  })
);

export const folderValidationRules = yupResolver(
  yup.object({
    name: yup.string().required("Required field")
  })
);
