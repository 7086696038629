import React, { useRef } from "react";
import DatePicker from "react-datepicker";

import { CalendarCheck } from "@components/icon";

export interface DatePickerProps {
  name: string;
  value: string | null;
  onBlur?: any;
  onChange: (date: Date | null) => void;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  dateFormat?: string;
  placeholderText?: string;
}

export default function SelectDatePicker({
  name,
  value,
  onChange,
  onBlur,
  dateFormat = "dd/MM/yyyy",
  placeholderText = "DD/MM/YYYY",
  showMonthYearPicker = false,
  showYearPicker = false
}: DatePickerProps): JSX.Element {
  const ref = useRef<HTMLInputElement>(null);
  const selected = value ? new Date(value) : null;

  const InputField = React.forwardRef<HTMLInputElement>((props, ref) => (
    <div className="relative">
      <input type="text" ref={ref} {...props} />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <CalendarCheck className="w-5 h-5 text-gray-400 svg-inline--fa fa-calendar-check" />
      </div>
    </div>
  ));

  return (
    <DatePicker
      key={name}
      id={name}
      name={name}
      onBlur={onBlur}
      selected={selected}
      onChange={onChange}
      dateFormat={dateFormat}
      placeholderText={placeholderText}
      dropdownMode="select"
      customInput={<InputField ref={ref} />}
      className="block w-full h-[38px] border-gray-300 rounded-md focus:border-1 focus:ring-0 sm:text-xs"
      disabledKeyboardNavigation
      peekNextMonth
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
    />
  );
}
