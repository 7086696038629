import {
  ITrackingActivity,
  IUpdateFieldData,
  TrackingActivityActionType,
  TrackingActivityModelType
} from "@components/activity-controller";
import classNames from "classnames";
import EmailCard from "@components/email-controller/components/email.card";
import { ItemView } from "@components/task-controller";
import moment from "moment/moment";
import { IMessage } from "@model/email";
import { ITask } from "@model/task";
import { useSelector } from "react-redux";
import { selectAdmin, selectUserId } from "@state/selectors";
import { UserType } from "@model/contact";
import TwilioRecordCard from "@components/activity-controller/components/activity.twilio-record-card";

export default function ActivityCardContent({
  activity,
  onSelectEmail,
  handleTaskEdit,
  handleTaskViewChange,
  handleTaskDelete
}: {
  activity: ITrackingActivity;
  onSelectEmail: (item: IMessage) => void;
  handleTaskEdit: (item: ITask) => void;
  handleTaskViewChange: (item: ITask) => void;
  handleTaskDelete: (id: number | string) => void;
}) {
  const userId = useSelector(selectUserId);
  const isAdmin = useSelector(selectAdmin);
  const { user, model_id, model_type, ref, ref_id, ref_type, action, data, created_at } = activity;

  // email card
  if (ref_type === TrackingActivityModelType.message && ref) {
    return <EmailCard item={ref} onSelectItem={onSelectEmail} />;
  }
  // task card
  if (ref_type === TrackingActivityModelType.task && ref) {
    return (
      <ItemView
        data={ref}
        editable={isAdmin || userId === ref?.id}
        handleChange={handleTaskViewChange}
        handleDelete={handleTaskDelete}
      />
    );
  }
  //twilio record card
  if (
    (ref_type === TrackingActivityModelType.twilio_record && ref) ||
    action === TrackingActivityActionType.call_out ||
    action === TrackingActivityActionType.call_in
  ) {
    return (
      <TwilioRecordCard record={ref} data={data} user={user} date={created_at} action={action} />
    );
  }

  // create event card
  if (ref_type && !ref && !action) {
    const content = `This #${ref_id} ${ref_type}  was created`;
    return <ContentCard user={user} content={content} date={created_at} />;
  }

  // delete event card
  if (ref_type && !ref && action === TrackingActivityActionType.delete) {
    const content = `This #${ref_id} ${ref_type} was deleted`;
    return <ContentCard user={user} content={content} date={created_at} />;
  }

  // create model card
  if (action === TrackingActivityActionType.create) {
    const content = `This #${model_id} ${model_type} was created`;
    return <ContentCard user={user} content={content} date={created_at} />;
  }

  // update model field card
  if (action === TrackingActivityActionType.update && data) {
    return <UpdateFieldCard user={user} data={data} date={created_at} />;
  }

  return <EmptyCard date={created_at} />;
}

const ContentCard = ({ content, date, user }: { content: string; date: Date; user?: UserType }) => {
  return (
    <div
      className={classNames(
        "block px-2 py-2 bg-white shadow sm:rounded-md sm:pr-2 sm:pl-3 h-auto relative"
      )}
    >
      <p className="font-semibold text-black truncate">{content}</p>
      <div className="flex items-end justify-between mt-2 text-xs text-gray-400 border-t border-gray-200 sm:flex-col">
        <p className="order-2 mt-2 sm:order-1">Created by: {user?.name || "-"}</p>
        <div className="flex flex-row items-center order-1 space-x-3 sm:order-2">
          <p>Updated {moment(date).fromNow()}</p>
        </div>
      </div>
    </div>
  );
};

const UpdateFieldCard = ({
  data,
  date,
  user
}: {
  data: IUpdateFieldData[];
  date: Date;
  user?: UserType;
}) => {
  return (
    <div
      className={classNames(
        "block px-2 py-2 bg-white shadow sm:rounded-md sm:pr-2 sm:pl-3 h-auto relative"
      )}
    >
      <p className="truncate">Field update</p>
      <div className="flex flex-col mt-1 text-xs">
        {data.map((item) => {
          const { field, old_value, new_value } = item;
          return (
            <div key={field} className="flex space-x-2">
              <span>{field}:</span>
              <span className="font-semibold underline">{old_value}</span> <span>to</span>{" "}
              <span className="font-semibold underline">{new_value}</span>
            </div>
          );
        })}
      </div>
      <div className="flex items-end justify-between mt-2 text-xs text-gray-400 border-t border-gray-200 sm:flex-col">
        <p className="order-2 mt-2 sm:order-1">Created by: {user?.name || "-"}</p>
        <div className="flex flex-row items-center order-1 space-x-3 sm:order-2">
          <p>Updated {moment(date).fromNow()}</p>
        </div>
      </div>
    </div>
  );
};

const EmptyCard = ({ date }: { date: Date }) => (
  <div
    className={classNames(
      "block px-2 py-2 bg-white shadow sm:rounded-md sm:pr-2 sm:pl-3 h-auto relative"
    )}
  >
    <p className="font-semibold text-black truncate">Empty</p>
    <div className="flex items-center text-gray-500 sm:mt-0 min-w-[150px] sm:justify-end text-xs">
      <p>Updated {moment(date).fromNow()}</p>
    </div>
  </div>
);
