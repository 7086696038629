import { BatchAction, BatchActionType, BatchState, BatchStatus } from "./batch.type";

const initialState: BatchState = {
  // Using by back-end for batch processing.
  includes: [],
  allListings: false,
  values: {},
  filters: {},
  search: null,
  // Using by front-end for handle UI states.
  ui: {
    selectedAllItems: false,
    selectedAllPages: false,
    totalListing: 0
  },
  // Using by front-end for handle async tasks.
  status: BatchStatus.IDLE,
  error: null
};

function batchEditingReducer(state: BatchState = initialState, action: BatchAction): BatchState {
  switch (action.type) {
    case BatchActionType.RESET_STATE:
      return {
        ...state,
        ...action.payload
      };
    case BatchActionType.RESET_STATUS:
    case BatchActionType.SUBMIT_BATCH_PENDING:
    case BatchActionType.SUBMIT_BATCH_FULFILLED:
    case BatchActionType.SUBMIT_BATCH_REJECTED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error
      };
    case BatchActionType.SELECTED_ALL_ITEMS:
      return {
        ...state,
        includes: action.payload.includes,
        allListings: action.payload.allListings,
        ui: {
          ...state.ui,
          selectedAllItems: action.payload.ui.selectedAllItems,
          selectedAllPages: action.payload.ui.selectedAllPages
        }
      };
    case BatchActionType.SELECTED_ALL_PAGES:
      return {
        ...state,
        allListings: action.payload.allListings,
        ui: {
          ...state.ui,
          selectedAllPages: action.payload.ui.selectedAllPages
        }
      };
    case BatchActionType.SELECTED_ITEM:
      return {
        ...state,
        includes: [...state.includes, action.payload]
      };
    case BatchActionType.REMOVED_ITEM:
      return {
        ...state,
        includes: state.includes.filter((id) => id !== action.payload)
      };
    case BatchActionType.UPDATED_TOTAL_LISTING:
      return {
        ...state,
        ui: {
          ...state.ui,
          totalListing: action.payload.ui.totalListing
        }
      };
    case BatchActionType.UPDATED_VALUES:
      return {
        ...state,
        values: action.payload
      };
    case BatchActionType.UPDATED_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case BatchActionType.UPDATED_SEARCH:
      return {
        ...state,
        search: action.payload
      };
    default:
      return state;
  }
}

export default batchEditingReducer;
