import { get, queryType } from "@utils/api";

class TrackingActivityService {
  endpoint: string;
  queries: queryType;

  static async fetch(
    model_id: number,
    model_type: string,
    page: number,
    per_page: number = 10
  ): Promise<any> {
    const queries: queryType = {
      model_id,
      model_type,
      page,
      per_page,
      sort_by: "created_at"
    };
    let endpoint: string = "tracking-activity";

    return await new TrackingActivityService(endpoint, queries).get();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async get(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data, meta } = await get({ path, queries });

    return { data, meta };
  }
}

export default TrackingActivityService;
