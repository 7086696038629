import useModal from "@utils/hooks/useModal";
import DeleteEmailTemplateService from "@services/emails/templates/delete-email-template.service";
import { toast } from "@views/components";
import { useEffect, useState } from "react";
import { EmailType, IEmailTemplate } from "@model/email";
import GetEmailTemplatesService from "@services/emails/templates/get-email-templates.service";
import { EmailTemplateContentType } from "@components/email-controller/index";

export default function useEmailTemplateList({
  setSelectedTemplate,
  setParentFolderId
}: {
  setSelectedTemplate: any;
  setParentFolderId?: any;
}) {
  const [openModal, closeModal, onConfirm] = useModal();
  const REMOVE_TEMPLATE_MODAL_KEY = "remove-template";

  const [templates, setTemplates] = useState<IEmailTemplate[]>([]);
  const [cachedTemplates, setCachedTemplates] = useState<
    { folderId: number; templates: IEmailTemplate[] }[]
  >([]);
  const [folderId, setFolderId] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string | null>(null);
  const [folderHistory, setFolderHistory] = useState<
    { folder: IEmailTemplate | null | undefined; folderId: number }[]
  >([]);
  const [prevFolder, setPrevFolder] = useState<IEmailTemplate | null | undefined>(null);

  // sort template and folder
  const sortTemplateAndFolder = (list: IEmailTemplate[]) => {
    return list.sort(
      (a: IEmailTemplate, b: IEmailTemplate) =>
        a.type.localeCompare(b.type) || a.name.localeCompare(b.name)
    );
  };

  // update cache and setTemplates
  const updateCachedTemplates = (folderId: number, templates: IEmailTemplate[]) => {
    const hitCachedTemplates = findCachedTemplates(folderId);
    if (hitCachedTemplates) {
      // update existed cached
      setCachedTemplates(
        cachedTemplates.reduce((a: { folderId: number; templates: IEmailTemplate[] }[], item) => {
          if (item.folderId === folderId) {
            return [
              ...a,
              {
                folderId,
                templates
              }
            ];
          }
          return [...a, item];
        }, [])
      );
    } else {
      // add new cached
      setCachedTemplates([
        ...cachedTemplates,
        {
          folderId,
          templates
        }
      ]);
    }
    setTemplates(templates);
  };

  // find cache from folder_id
  const findCachedTemplates = (folderId: number) => {
    return cachedTemplates.find(
      (cache: { folderId: number; templates: IEmailTemplate[] }) => cache.folderId === folderId
    ) as { folderId: number; templates: IEmailTemplate[] };
  };

  useEffect(() => {
    if (setParentFolderId) {
      setParentFolderId(folderId);
    }
  }, [folderId]);

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      // find cache
      const hitCachedTemplates = findCachedTemplates(folderId);
      // check cache
      if (hitCachedTemplates) {
        setTemplates(hitCachedTemplates.templates);
        setLoading(false);
      } else {
        const templates = GetEmailTemplatesService.call({
          filters: JSON.stringify({
            folder_id: {
              is: [folderId === 0 ? null : folderId]
            }
          }),
          per_page: 20
        });
        Promise.all([templates]).then(function (values) {
          const templates = sortTemplateAndFolder(values[0]);
          updateCachedTemplates(folderId, templates);
          setLoading(false);
        });
      }
    };

    fetchTemplates();
  }, [folderId]);

  const handleRemove = async (id: number, activeTemplate: boolean) => {
    const isConfirmed = await openModal(REMOVE_TEMPLATE_MODAL_KEY);

    if (isConfirmed) handleRemoveOnConfirm(id, activeTemplate);
  };

  const handleRemoveOnConfirm = (id: number, activeTemplate: boolean) => {
    // Remove from state
    const templateToRemove = templates.find((t) => t.id === id);
    removeTemplate(id);

    // Call API to remove
    DeleteEmailTemplateService.call(id)
      .then(() => {
        toast.success("Data has been updated.");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Fail to remove the template.");
        // Add back on error
        if (templateToRemove) addTemplate(templateToRemove);
      });

    // reset selected stage if remove after select ( edit | create )
    if (activeTemplate) {
      setSelectedTemplate(null);
    }
  };

  const showCreateForm = () =>
    setSelectedTemplate({
      id: 0,
      name: "",
      subject: "",
      template: "",
      folderId: folderId ? folderId : null,
      type: EmailType.template
    });
  const showCreateFolder = () =>
    setSelectedTemplate({
      id: 0,
      name: "",
      subject: "",
      template: "",
      folderId: folderId ? folderId : null,
      type: EmailType.folder
    });

  const addTemplate = (item: IEmailTemplate) => {
    const newTemplates = sortTemplateAndFolder([...templates, item as IEmailTemplate]);
    updateCachedTemplates(folderId, newTemplates);
  };

  const removeTemplate = (id: number) => {
    const filteredTemplates = templates.filter((t) => t.id !== id);

    updateCachedTemplates(folderId, filteredTemplates);
  };

  const handleFolderChange = (newFolderId: number) => {
    const newItem =
      newFolderId && templates.length ? templates.find((item) => item.id === newFolderId) : null;
    folderHistory.push({
      folder: prevFolder,
      folderId
    });
    setPrevFolder(newItem);
    setFolderId(newFolderId);
    setFolderName(newItem ? newItem.name : "Root");
    setLoading(true);
  };

  const handleBackFolder = () => {
    const folder = folderHistory.pop() as {
      folder: IEmailTemplate | null | undefined;
      folderId: number;
    };
    setPrevFolder(folder.folder);
    setFolderId(folder.folderId);
    setFolderName(folder.folder ? folder.folder.name : "Root");
    setLoading(true);
  };

  const updateTemplate = (item: IEmailTemplate) => {
    const index = templates.findIndex((t) => t.id === item.id);
    const newTemplates = [...templates];
    newTemplates[index] = item as IEmailTemplate; //changing value in the new array

    updateCachedTemplates(folderId, newTemplates);
  };

  const createOrUpdateEmailTemplate = (item: EmailTemplateContentType) => {
    const newTemplateItem = item as IEmailTemplate;
    const cachedFolder = cachedTemplates.find(
      (cache) => cache.folderId === (newTemplateItem.folder_id || 0)
    );
    if (cachedFolder) {
      const currentTemplates = cachedFolder.templates;
      const cachedTemplate = currentTemplates.find(
        (template) => template.id === newTemplateItem.id
      );
      if (cachedTemplate) {
        const isExactlySame = JSON.stringify(cachedTemplate) === JSON.stringify(newTemplateItem);
        if (!isExactlySame) {
          updateTemplate(newTemplateItem);
          setSelectedTemplate(null);
        }
      } else {
        addTemplate(newTemplateItem);
        setSelectedTemplate(null);
      }
    }
  };

  return {
    templates,
    handleRemove,
    closeModal,
    onConfirm,
    REMOVE_TEMPLATE_MODAL_KEY,
    folderId,
    folderName,
    isLoading,
    handleFolderChange,
    handleBackFolder,
    showCreateForm,
    showCreateFolder,
    createOrUpdateEmailTemplate
  } as const;
}
