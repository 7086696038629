import { ReactNode } from "react";

type EditorInputType = "editor";

type OptionInputTypes =
  | "react-selection"
  | "asyncSelect"
  | "asyncSelect-multiple"
  | "select-multiple"
  | "dropdown"
  | "btn-group";

type BasicInputTypes =
  | "numberString"
  | "number"
  | "text"
  | "checkbox"
  | "textarea"
  | "date-picker"
  | "native-date-picker"
  | "date-time-picker"
  | "input-prefix";

export type InputType = BasicInputTypes | OptionInputTypes | EditorInputType;

export type FormDisplayType = "horizontal" | "vertical";

export enum InputMode {
  READ = "read",
  EDIT = "edit"
}

export interface Condition {
  key: any;
  matches: any[];
}

export type OptionType = {
  key?: string;
  type?: string;
  filters?: any;
  inputOptions?: any;
  optionValue?: any;
  defaultValue?: any;
  defaultOptions?: any;
  menuPortalTarget?: boolean;
  txtLeading?: string;
  endpoint?: string;
  showCreateNew?: boolean;
  callBack?: (event: any) => void;
  filterOptions?: any;
  onImageUpload?: any;
};

type OptionField = {
  inputType: OptionInputTypes;
  options: OptionType;
};

type BasicField = {
  inputType?: BasicInputTypes;
  options?: any;
};

type EditorField = {
  inputType: EditorInputType;
  options?: Partial<{
    init: Partial<{
      height: number;
      paste_data_images: boolean;
      plugins: string[];
      toolbar: any;
      paste_as_text?: boolean;
      invalid_elements?: string;
    }>;
    guideline: any;
    onImageUpload: any;
  }>;
};

interface BasicFormInlineFieldProps {
  label: string;
  field: string;
  errors: any;
  required?: boolean;
  name: string;
  customValue?: any;
  register: any;
  controller?: any;
  control?: any;
  readonly?: boolean;
  editonly?: boolean;
  conditions?: Condition[];
  formDisplayType?: FormDisplayType;
  formGroupClass?: string;
  children?: ReactNode;
}

export type FormInlineFieldProps = BasicFormInlineFieldProps &
  (OptionField | BasicField | EditorField);

interface BasicInlineInputProps {
  name: string;
  register: any;
  Controller?: any;
  control?: any;
  customValue?: any;
  readonly?: boolean;
  editonly?: boolean;
  value?: any;
  setValue?: any;
  inputStyles?: any;
  inlineInputStyles?: string;
  readOnlyStyles?: string;
  secondaryName?: string;
}

export type InlineInputProps = BasicInlineInputProps & (OptionField | BasicField | EditorField);
