import React, { useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { EmailTemplateContentType, EmailTemplateList } from "@components/email-controller";

const EmailTemplateSelector = React.memo(
  ({ onSelected }: { onSelected: (templateId: number) => void }) => {
    const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplateContentType>(null);

    useEffect(() => {
      if (selectedTemplate && selectedTemplate.id !== 0) {
        onSelected(selectedTemplate.id);
      }
    }, [selectedTemplate]);

    return (
      <div className="flex flex-col justify-center">
        <Popover className={"relative "}>
          <Popover.Button
            className={
              "px-2 py-1 rounded border inline-flex items-center gap-x-2 w-full justify-center bg-white"
            }
          >
            Templates
            <ChevronDownIcon className="w-4 h-4" />
          </Popover.Button>

          <Popover.Panel className={"absolute z-[25]"}>
            {({ close }) => (
              <div className="bg-white border rounded-md w-72 mt-1">
                <EmailTemplateList
                  currentTemplate={selectedTemplate}
                  activeTemplate={
                    selectedTemplate && selectedTemplate.id !== 0 ? selectedTemplate.id : false
                  }
                  onClickUpdate={(template) => {
                    setSelectedTemplate(template);
                    close();
                  }}
                  editable={false}
                />
              </div>
            )}
          </Popover.Panel>
        </Popover>
      </div>
    );
  }
);

export default EmailTemplateSelector;
