import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import NotificationAlertItem from "./notification.alert-item";
import { useDispatch, useSelector } from "react-redux";
import { selectNotificationAlert } from "@state/selectors";
import useNotification from "../useNotification";
import { resetNotificationAlert } from "@state/actions/action.notification";
import { NotificationAlertType } from "@model/notification";

export default function NotificationAlert() {
  const notificationAlert = useSelector(selectNotificationAlert);
  const [fetchNotificationMenu] = useNotification();
  const dispatch = useDispatch();
  const [notifyLists, setNotificationLists] = useState<any[]>([]);

  useEffect(() => {
    // console.log('useEffect', notificationAlert)
    if (notificationAlert) {
      fetchNotificationMenu(true).then((notification) => console.log(notification));
      setNotificationLists((prev: any) => [{ ...notificationAlert, id: Date.now() }, ...prev]);
    }
  }, [notificationAlert]);

  const onExpire = (id: number) => {
    // remove notifyLists by id
    setNotificationLists((prev: any) => prev.filter((p: any) => p.id !== id));
    // reset notificationAlert Data
    resetNotificationAlert(dispatch);
  };
  // console.log({notifyLists})
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:items-start sm:p-6"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={!!notifyLists.length}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-col w-full max-w-xs space-y-2">
              {notifyLists.map((notificationAlert: NotificationAlertType) => (
                <NotificationAlertItem
                  {...notificationAlert}
                  key={notificationAlert.id}
                  onExpire={onExpire}
                />
              ))}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
