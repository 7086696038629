import { get, queryType } from "@utils/api";

class GetEmailTemplatesService {
  endpoint: string = "email-templates";
  queries: queryType;

  constructor(queries: queryType) {
    this.queries = queries;
  }

  static async call(queries: queryType = {}) {
    return await new GetEmailTemplatesService(queries).getTemplates();
  }

  async getTemplates() {
    const { data } = await get({
      path: this.endpoint,
      queries: this.queries
    });

    return data;
  }
}

export default GetEmailTemplatesService;
