import React from "react";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";
import { useTaskModalStore } from "../store/task-modals.store";

export default function withTaskModalStore<T>(WrappedComponent: React.FC<T>): React.FC<T> {
  return function TaskLoading(props: any) {
    const { type, data, modelKey } = props;
    const { setStore: setTaskModalStore, reset: resetTaskModalStore } = useTaskModalStore(
      (state) => ({
        setStore: state.setStore,
        reset: state.reset
      })
    );

    useLegacyEffect(() => {
      setTaskModalStore({
        type,
        referTo: data ? { data, refType: modelKey } : { data: null, refType: null }
      });

      return () => {
        resetTaskModalStore();
      };
    }, [type, data, modelKey]);

    return <WrappedComponent {...props} />;
  };
}
