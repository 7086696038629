// export enum formControlStatus {
//   onChanges = 'onChanges',
//   complete = 'complete',
//   normal = 'normal',
//   submitted = 'submitted',
//   errors = 'errors',
// }

import { ViewUserType } from "@state/reducers/reducer.app";

export enum RoutePath {
  signIn = "/signin",
  devSignIn = "/dev-signin*"
}

export enum propertySizeUnit {
  SQM = "SQM",
  SQF = "SQF"
}

export enum propertyRentalUnit {
  Monthly = "Monthly",
  Yearly = "Yearly"
}

export type PreferenceType = {
  defaultCountry: CountryCode;
  propertySizeUnit: propertySizeUnit;
  propertyRentalUnit: propertyRentalUnit;
};

export type SuperAdminType = {
  view_user: ViewUserType;
  is_system_super_admin: boolean;
};

export type AppStorageType = {
  token: string;
  preferences?: PreferenceType;
  superAdmin?: SuperAdminType;
  hasGrantedAllScopes?: boolean;
};

export type selectOptionType = {
  value?: string;
  label?: string;
  options?: any;
};

export type AppInputOptions = {
  boolean_option: InputOption[];
  buy_or_lease_reasons: InputOption[];
  buying_options: InputOption[];
  client_type: InputOption[];
  contact_type: InputOption[];
  countries: InputOption[];
  currency_code: InputOption[];
  customer_age_range_options: InputOption[];
  sources: InputOption[];
  deal_sources: InputOption[];
  partner_website: InputOption[];
  enquiry_type: InputOption[];
  gender_of_decision_maker: InputOption[];
  gender_options: InputOption[];
  languages: InputOption[];
  lazudi_countries: InputOption[];
  lease_unit: InputOption[];
  lifecycle_stage: InputOption[];
  lost_reasons: InputOption[];
  marital_status: InputOption[];
  portals: InputOption[];
  portals_th: InputOption[];
  priority: InputOption[];
  property_type: InputOption[];
  type_of_property_sold: InputOption[];
  salutation: InputOption[];
  seller_lost_reasons: InputOption[];
  seller_type: InputOption[];
  user_rule: InputOption[];
};

export enum CountryCode {
  AE = "AE",
  GB = "GB",
  ID = "ID",
  PH = "PH",
  TH = "TH"
}

export const countriesAvailable: {
  country_id: CountryCode;
  label: string;
  currency: string;
  currencySymbol: string;
  gps: { lat: number; lng: number };
  timeZone: string;
}[] = [
  {
    country_id: CountryCode.AE,
    label: "United Arab Emirates",
    currency: "AED",
    currencySymbol: "AED",
    gps: { lat: 23.424076, lng: 53.847818 },
    timeZone: "Asia/Dubai"
  },
  {
    country_id: CountryCode.GB,
    label: "United Kingdom",
    currency: "GBP",
    currencySymbol: "£",
    gps: { lat: 55.378051, lng: -3.435973 },
    timeZone: "Europe/London"
  },
  {
    country_id: CountryCode.ID,
    label: "Indonesia",
    currency: "IDR",
    currencySymbol: "Rp",
    gps: { lat: -0.789275, lng: 113.921327 },
    timeZone: "Asia/Jakarta"
  },
  {
    country_id: CountryCode.PH,
    label: "Philippines",
    currency: "PHP",
    currencySymbol: "₱",
    gps: { lat: 12.879721, lng: 121.774017 },
    timeZone: "Asia/Manila"
  },
  {
    country_id: CountryCode.TH,
    label: "Thailand",
    currency: "THB",
    currencySymbol: "฿",
    gps: { lat: 13.7244416, lng: 100.3529157 },
    timeZone: "Asia/Bangkok"
  }
];

export const countryOptions: selectOptionType[] = countriesAvailable.map((i) => ({
  value: i.country_id,
  label: i.label
}));

export const getCurrencyCode = (countryCode = "TH") => {
  const country = countriesAvailable.find((country) => country.country_id === countryCode);
  return country ? country.currency : "";
};

export const getCountryLabelByCountryCode = (countryCode: CountryCode, defaultValue: any) => {
  const country = countriesAvailable.find((country) => country.country_id === countryCode);
  return country ? country.label : defaultValue;
};

export type Nullable<ObjectType> = {
  [Key in keyof ObjectType]: ObjectType[Key] | null;
};

export type ReturnItemType = {
  label: string;
  value: string;
  options?: any;
};

export type renderCallback = (item: any) => ReturnItemType;

export interface InputOption {
  label: string;
  value: string;
  group?: string;
}

export type InputOptionLists = InputOption[];

export type FormatNumberType = "default" | "currency" | "duration" | "percentage" | "shorten";

export enum Priority {
  none = "none",
  high = "high",
  medium = "medium",
  low = "low"
}

export const PriorityTableList = [
  { label: Priority.high, value: Priority.high, colorStyle: "bg-purple-100 text-purple-800" },
  { label: Priority.medium, value: Priority.medium, colorStyle: "bg-green-100 text-green-800" },
  { label: Priority.low, value: Priority.low, colorStyle: "bg-yellow-100 text-yellow-800" }
];

export const PriorityList = [
  { label: Priority.none, value: "", colorStyle: "" },
  ...PriorityTableList
];

enum Locale {
  Thai = "th",
  ThaiEnglish = "th-en",
  IndonesiaEnglish = "id-en",
  PhilippinesEnglish = "ph-en",
  UK = "uk",
  UAE = "ae",
  UAEArabic = "ae-ar"
}

export const localeOptions = [
  { label: "Thailand - Thai", value: Locale.Thai },
  { label: "Thailand - English", value: Locale.ThaiEnglish },
  { label: "Indonesia - English", value: Locale.IndonesiaEnglish },
  { label: "Philippines - English", value: Locale.PhilippinesEnglish },
  { label: "United Kingdom - English", value: Locale.UK },
  { label: "United Arab Emirates - English", value: Locale.UAE },
  { label: "United Arab Emirates - Arabic", value: Locale.UAEArabic }
];

export const siteOptions = [
  { label: "Ariaman Property Group", value: "ariamanproperty" },
  { label: "Luxury Hua Hin", value: "luxhhlaz" },
  { label: "G Immobilien", value: "gimmlaz" },
  { label: "Lazudi", value: "lazudi" }
];

export enum UserRole {
  superAdmin = "super-admin",
  admin = "admin",
  customerSuccess = "customer-success",
  agent = "agent",
  user = "user",
  contributor = "contributor",
  affiliate = "affiliate",
  manager = "manager"
}

export const AppPermissions = {
  administrator: [UserRole.superAdmin, UserRole.admin, UserRole.customerSuccess],
  owner: [UserRole.agent, UserRole.affiliate],
  agent: [UserRole.agent],
  admin: [UserRole.superAdmin, UserRole.admin],
  organization: [UserRole.superAdmin, UserRole.admin, UserRole.customerSuccess, UserRole.agent],
  contactManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent,
    UserRole.affiliate
  ],
  dealManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent,
    UserRole.affiliate
  ],
  taskManagement: [UserRole.superAdmin, UserRole.admin, UserRole.customerSuccess, UserRole.agent],
  projectManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent
  ],
  projectDetailManagement: [UserRole.superAdmin, UserRole.admin, UserRole.customerSuccess],
  developerManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent
  ],
  developerDetailManagement: [UserRole.superAdmin, UserRole.admin, UserRole.customerSuccess],
  propertyManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent,
    UserRole.affiliate
  ],
  blogManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.contributor
  ],
  emailTemplateManagement: [UserRole.superAdmin, UserRole.admin, UserRole.customerSuccess],
  preferenceSetting: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent
  ],
  storageManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent
  ],
  dashboardManagement: [
    UserRole.superAdmin,
    UserRole.admin,
    UserRole.customerSuccess,
    UserRole.agent
  ],
  profileSetting: ["*"]
};

export const OfferList = [
  { label: "Offer made", value: "offer-made" },
  { label: "Offer pending", value: "offer-pending" },
  { label: "Offer accepted", value: "offer-accepted" },
  { label: "Paperwork sent", value: "paperwork-sent" },
  { label: "Paperwork signed", value: "paperwork-signed" },
  { label: "Offer rejected", value: "offer-rejected" }
];

export const OfferTypeList = [{ label: "None", value: "" }, ...OfferList];

export const currencyOptions = [
  { label: "ADB Unit of Account", value: "XUA" },
  { label: "Afghani", value: "AFN" },
  { label: "Algerian Dinar", value: "DZD" },
  { label: "Argentine Peso", value: "ARS" },
  { label: "Armenian Dram", value: "AMD" },
  { label: "Aruban Florin", value: "AWG" },
  { label: "Australian Dollar", value: "AUD" },
  { label: "Azerbaijanian Manat", value: "AZN" },
  { label: "Bahamian Dollar", value: "BSD" },
  { label: "Bahraini Dinar", value: "BHD" },
  { label: "Baht", value: "THB" },
  { label: "Balboa", value: "PAB" },
  { label: "Barbados Dollar", value: "BBD" },
  { label: "Belarussian Ruble", value: "BYN" },
  { label: "Belarussian Ruble", value: "BYR" },
  { label: "Belize Dollar", value: "BZD" },
  { label: "Bermudian Dollar", value: "BMD" },
  { label: "Bolivar", value: "VEF" },
  { label: "Bolivar", value: "VES" },
  { label: "Boliviano", value: "BOB" },
  { label: "Brazilian Real", value: "BRL" },
  { label: "Brunei Dollar", value: "BND" },
  { label: "BTC", value: "BTC" },
  { label: "Bulgarian Lev", value: "BGN" },
  { label: "Burundi Franc", value: "BIF" },
  { label: "Cabo Verde Escudo", value: "CVE" },
  { label: "Canadian Dollar", value: "CAD" },
  { label: "Cayman Islands Dollar", value: "KYD" },
  { label: "CFA Franc BCEAO", value: "XOF" },
  { label: "CFA Franc BEAC", value: "XAF" },
  { label: "CFP Franc", value: "XPF" },
  { label: "Chilean Peso", value: "CLP" },
  { label: "Colombian Peso", value: "COP" },
  { label: "Comoro Franc", value: "KMF" },
  { label: "Congolese Franc", value: "CDF" },
  { label: "Convertible Mark", value: "BAM" },
  { label: "Cordob Oro", value: "NIO" },
  { label: "Cost Rican Colon", value: "CRC" },
  { label: "Croatian Kuna", value: "HRK" },
  { label: "Cuban Peso", value: "CUP" },
  { label: "Czech Koruna", value: "CZK" },
  { label: "Dalasi", value: "GMD" },
  { label: "Danish Krone", value: "DKK" },
  { label: "Denar", value: "MKD" },
  { label: "Djibouti Franc", value: "DJF" },
  { label: "Dobra", value: "STD" },
  { label: "Dominican Peso", value: "DOP" },
  { label: "Dong", value: "VND" },
  { label: "East Caribbean Dollar", value: "XCD" },
  { label: "Egyptian Pound", value: "EGP" },
  { label: "El Salvador Colon", value: "SVC" },
  { label: "Estonian Kroon", value: "EEK" },
  { label: "ETH", value: "ETH" },
  { label: "Ethiopian Birr", value: "ETB" },
  { label: "Euro", value: "EUR" },
  { label: "Falkland Islands Pound", value: "FKP" },
  { label: "Fiji Dollar", value: "FJD" },
  { label: "Forint", value: "HUF" },
  { label: "Ghan Cedi", value: "GHS" },
  { label: "Ghanaian Cedi", value: "GHC" },
  { label: "Gibraltar Pound", value: "GIP" },
  { label: "Gourde", value: "HTG" },
  { label: "Guarani", value: "PYG" },
  { label: "Guernsey Pound", value: "GGP" },
  { label: "Guine Franc", value: "GNF" },
  { label: "Guyan Dollar", value: "GYD" },
  { label: "Hong Kong Dollar", value: "HKD" },
  { label: "Hryvnia", value: "UAH" },
  { label: "Iceland Krona", value: "ISK" },
  { label: "Indian Rupee", value: "INR" },
  { label: "Iranian Rial", value: "IRR" },
  { label: "Iraqi Dinar", value: "IQD" },
  { label: "Jamaican Dollar", value: "JMD" },
  { label: "Jersey Pound", value: "JEP" },
  { label: "Jordanian Dinar", value: "JOD" },
  { label: "Kenyan Shilling", value: "KES" },
  { label: "Kina", value: "PGK" },
  { label: "Kip", value: "LAK" },
  { label: "Kuwaiti Dinar", value: "KWD" },
  { label: "Kwacha", value: "MWK" },
  { label: "Kwanza", value: "AOA" },
  { label: "Kyat", value: "MMK" },
  { label: "Lari", value: "GEL" },
  { label: "Latvian Lats", value: "LVL" },
  { label: "Lebanese Pound", value: "LBP" },
  { label: "Lek", value: "ALL" },
  { label: "Lempira", value: "HNL" },
  { label: "Leone", value: "SLL" },
  { label: "Liberian Dollar", value: "LRD" },
  { label: "Libyan Dinar", value: "LYD" },
  { label: "Lilangeni", value: "SZL" },
  { label: "Lithuanian Litas", value: "LTL" },
  { label: "Loti", value: "LSL" },
  { label: "LTC", value: "LTC" },
  { label: "Malagasy ariary", value: "MGA" },
  { label: "Malaysian Ringgit", value: "MYR" },
  { label: "Manx Pound", value: "IMP" },
  { label: "Mauritius Rupee", value: "MUR" },
  { label: "Mexican Peso", value: "MXN" },
  { label: "Mexican Unidad de Inversion (UDI)", value: "MXV" },
  { label: "Moldovan Leu", value: "MDL" },
  { label: "Moroccan Dirham", value: "MAD" },
  { label: "Mozambique Metical", value: "MZN" },
  { label: "Mvdol", value: "BOV" },
  { label: "Naira", value: "NGN" },
  { label: "Nakfa", value: "ERN" },
  { label: "Namibi Dollar", value: "NAD" },
  { label: "Nepalese Rupee", value: "NPR" },
  { label: "Netherlands Antillean Guilder", value: "ANG" },
  { label: "New Israeli Sheqel", value: "ILS" },
  { label: "New Romanian Leu", value: "RON" },
  { label: "New Taiwan Dollar", value: "TWD" },
  { label: "New Zealand Dollar", value: "NZD" },
  { label: "Ngultrum", value: "BTN" },
  { label: "North Korean Won", value: "KPW" },
  { label: "Norwegian Krone", value: "NOK" },
  { label: "Nuevo Sol", value: "PEN" },
  { label: "Ouguiya", value: "MRO" },
  { label: "Pakistan Rupee", value: "PKR" },
  { label: "Pataca", value: "MOP" },
  { label: "Pa\u2019anga", value: "TOP" },
  { label: "Peso Convertible", value: "CUC" },
  { label: "Peso Uruguayo", value: "UYU" },
  { label: "Philippine Peso", value: "PHP" },
  { label: "Pound Sterling", value: "GBP" },
  { label: "Pula", value: "BWP" },
  { label: "Qatari Rial", value: "QAR" },
  { label: "Quetzal", value: "GTQ" },
  { label: "Rand", value: "ZAR" },
  { label: "Rial Omani", value: "OMR" },
  { label: "Riel", value: "KHR" },
  { label: "Rufiyaa", value: "MVR" },
  { label: "Rupiah", value: "IDR" },
  { label: "Russian Ruble", value: "RUB" },
  { label: "Russian Ruble", value: "RUR" },
  { label: "Rwand Franc", value: "RWF" },
  { label: "Saint Helen Pound", value: "SHP" },
  { label: "Saudi Riyal", value: "SAR" },
  { label: "SDR (Special Drawing Right)", value: "XDR" },
  { label: "Serbian Dinar", value: "RSD" },
  { label: "Seychelles Rupee", value: "SCR" },
  { label: "Singapore Dollar", value: "SGD" },
  { label: "Solomon Islands Dollar", value: "SBD" },
  { label: "Som", value: "KGS" },
  { label: "Somali Shilling", value: "SOS" },
  { label: "Somoni", value: "TJS" },
  { label: "South Sudanese Pound", value: "SSP" },
  { label: "Sri Lank Rupee", value: "LKR" },
  { label: "Sucre", value: "XSU" },
  { label: "Sudanese Pound", value: "SDG" },
  { label: "Surinam Dollar", value: "SRD" },
  { label: "Swedish Krona", value: "SEK" },
  { label: "Swiss Franc", value: "CHF" },
  { label: "Syrian Pound", value: "SYP" },
  { label: "Taka", value: "BDT" },
  { label: "Tala", value: "WST" },
  { label: "Tanzanian Shilling", value: "TZS" },
  { label: "Tenge", value: "KZT" },
  { label: "Trinidad and Tobago Dollar", value: "TTD" },
  { label: "Tugrik", value: "MNT" },
  { label: "Tunisian Dinar", value: "TND" },
  { label: "Turkish Lira", value: "TRL" },
  { label: "Turkish Lira", value: "TRY" },
  { label: "Turkmenistan New Manat", value: "TMT" },
  { label: "UAE Dirham", value: "AED" },
  { label: "Ugand Shilling", value: "UGX" },
  { label: "Unidad de Fomento", value: "CLF" },
  { label: "Unidad de Valor Real", value: "COU" },
  { label: "Uruguay Peso en Unidades Indexadas (URUIURUI)", value: "UYI" },
  { label: "US Dollar", value: "USD" },
  { label: "US Dollar (Next day)", value: "USN" },
  { label: "Uzbekistan Sum", value: "UZS" },
  { label: "Vatu", value: "VUV" },
  { label: "WIR Euro", value: "CHE" },
  { label: "WIR Franc", value: "CHW" },
  { label: "Won", value: "KRW" },
  { label: "Yemeni Rial", value: "YER" },
  { label: "Yen", value: "JPY" },
  { label: "Yuan Renminbi", value: "CNY" },
  { label: "Zambian Kwacha", value: "ZMW" },
  { label: "Zimbabwe Dollar", value: "ZWD" },
  { label: "Zimbabwe Dollar", value: "ZWL" },
  { label: "Zloty", value: "PLN" }
];

export const languageOptions = [
  { label: "Afrikaans", value: "af" },
  { label: "Albanian", value: "sq" },
  { label: "Albanian - Albania", value: "sq-al" },
  { label: "Arabic", value: "ar" },
  { label: "Arabic - Algeria", value: "ar-dz" },
  { label: "Arabic - Bahrain", value: "ar-bh" },
  { label: "Arabic - Egypt", value: "ar-eg" },
  { label: "Arabic - Iraq", value: "ar-iq" },
  { label: "Arabic - Jordan", value: "ar-jo" },
  { label: "Arabic - Kuwait", value: "ar-kw" },
  { label: "Arabic - Lebanon", value: "ar-lb" },
  { label: "Arabic - Libya", value: "ar-ly" },
  { label: "Arabic - Morocco", value: "ar-ma" },
  { label: "Arabic - Oman", value: "ar-om" },
  { label: "Arabic - Qatar", value: "ar-qa" },
  { label: "Arabic - Saudi Arabia", value: "ar-sa" },
  { label: "Arabic - Sudan", value: "ar-sd" },
  { label: "Arabic - Syria", value: "ar-sy" },
  { label: "Arabic - Tunisia", value: "ar-tn" },
  { label: "Arabic - United Arab Emirates", value: "ar-ae" },
  { label: "Arabic - Yemen", value: "ar-ye" },
  { label: "Armenian", value: "hy" },
  { label: "Basque", value: "eu" },
  { label: "Belarusian", value: "be" },
  { label: "Belarusian - Belarus", value: "be-by" },
  { label: "Bulgarian", value: "bg" },
  { label: "Bulgarian - Bulgaria", value: "bg-bg" },
  { label: "Catalan", value: "ca" },
  { label: "Catalan - Catalan", value: "ca-es" },
  { label: "Chinese", value: "zh" },
  { label: "Chinese - China", value: "zh-cn" },
  { label: "Chinese - Hong Kong SAR", value: "zh-hk" },
  { label: "Chinese - Macau SAR", value: "zh-mo" },
  { label: "Chinese - Singapore", value: "zh-sg" },
  { label: "Chinese - Taiwan", value: "zh-tw" },
  { label: "Chinese (Simplified)", value: "zh-chs" },
  { label: "Chinese (Traditional)", value: "zh-cht" },
  { label: "Croatian", value: "hr" },
  { label: "Croatian - Croatia", value: "hr-hr" },
  { label: "Czech", value: "cs" },
  { label: "Czech - Czech Republic", value: "cs-cz" },
  { label: "Danish", value: "da" },
  { label: "Danish - Denmark", value: "da-dk" },
  { label: "Dutch", value: "nl" },
  { label: "Dutch - Belgium", value: "nl-be" },
  { label: "Dutch - The Netherlands", value: "nl-nl" },
  { label: "English", value: "en" },
  { label: "English - Australia", value: "en-au" },
  { label: "English - Canada", value: "en-ca" },
  { label: "English - Hong Kong", value: "en-hk" },
  { label: "English - India", value: "en-in" },
  { label: "English - Ireland", value: "en-ie" },
  { label: "English - Malaysia", value: "en-my" },
  { label: "English - Malta", value: "en-mt" },
  { label: "English - New Zealand", value: "en-nz" },
  { label: "English - Philippines", value: "en-ph" },
  { label: "English - Singapore", value: "en-sg" },
  { label: "English - South Africa", value: "en-za" },
  { label: "English - United Kingdom", value: "en-gb" },
  { label: "English - United States", value: "en-us" },
  { label: "English - Zimbabwe", value: "en-zw" },
  { label: "Estonian", value: "et" },
  { label: "Estonian - Estonia", value: "et-ee" },
  { label: "Faroese", value: "fo" },
  { label: "Farsi", value: "fa" },
  { label: "Finnish", value: "fi" },
  { label: "Finnish - Finland", value: "fi-fi" },
  { label: "French", value: "fr" },
  { label: "French - Belgium", value: "fr-be" },
  { label: "French - Canada", value: "fr-ca" },
  { label: "French - France", value: "fr-fr" },
  { label: "French - Luxembourg", value: "fr-lu" },
  { label: "French - Monaco", value: "fr-mc" },
  { label: "French - Switzerland", value: "fr-ch" },
  { label: "Galician", value: "gl" },
  { label: "Georgian", value: "ka" },
  { label: "German", value: "de" },
  { label: "German - Austria", value: "de-at" },
  { label: "German - Germany", value: "de-de" },
  { label: "German - Greece", value: "de-gr" },
  { label: "German - Liechtenstein", value: "de-li" },
  { label: "German - Luxembourg", value: "de-lu" },
  { label: "German - Switzerland", value: "de-ch" },
  { label: "Greek - Cyprus", value: "el-cy" },
  { label: "Greek - Greece", value: "el-gr" },
  { label: "Gujarati", value: "gu" },
  { label: "Hebrew", value: "he" },
  { label: "Hebrew - Israel", value: "iw-il" },
  { label: "Hindi", value: "hi" },
  { label: "Hindi - India", value: "hi-in" },
  { label: "Hungarian", value: "hu" },
  { label: "Hungarian - Hungary", value: "hu-hu" },
  { label: "Icelandic", value: "is" },
  { label: "Icelandic - Iceland", value: "is-is" },
  { label: "Indonesian", value: "id" },
  { label: "Indonesian - Indonesia", value: "in-id" },
  { label: "Irish", value: "ga" },
  { label: "Irish - Ireland", value: "ga-ie" },
  { label: "Italian", value: "it" },
  { label: "Italian - Italy", value: "it-it" },
  { label: "Italian - Switzerland", value: "it-ch" },
  { label: "Japanese", value: "ja" },
  { label: "Japanese - Japan", value: "ja-jp" },
  { label: "Kannada", value: "kn" },
  { label: "Kazakh", value: "kk" },
  { label: "Konkani", value: "kok" },
  { label: "Korean", value: "ko" },
  { label: "Korean - South Korea", value: "ko-kr" },
  { label: "Kyrgyz", value: "ky" },
  { label: "Latvian", value: "lv" },
  { label: "Latvian - Latvia", value: "lv-lv" },
  { label: "Lithuanian", value: "lt" },
  { label: "Lithuanian - Lithuania", value: "lt-lt" },
  { label: "Macedonian", value: "mk" },
  { label: "Macedonian - Macedonia", value: "mk-mk" },
  { label: "Malay", value: "ms" },
  { label: "Malay - Brunei", value: "ms-bn" },
  { label: "Malay - Malaysia", value: "ms-my" },
  { label: "Maltese", value: "mt" },
  { label: "Maltese - Malta", value: "mt-mt" },
  { label: "Marathi", value: "mr" },
  { label: "Mongolian", value: "mn" },
  { label: "Burmese", value: "my" },
  { label: "Burmese - Myanmar (Burma)", value: "my-mm" },
  { label: "Norwegian", value: "no" },
  { label: "Norwegian Bokmal", value: "nb" },
  { label: "Norwegian - Norway", value: "no-no" },
  { label: "Polish", value: "pl" },
  { label: "Polish - Poland", value: "pl-pl" },
  { label: "Portuguese", value: "pt" },
  { label: "Portuguese - Brazil", value: "pt-br" },
  { label: "Portuguese - Portugal", value: "pt-pt" },
  { label: "Punjabi", value: "pa" },
  { label: "Romanian", value: "ro" },
  { label: "Romanian - Romania", value: "ro-ro" },
  { label: "Russian", value: "ru" },
  { label: "Russian - Russia", value: "ru-ru" },
  { label: "Sanskrit", value: "sa" },
  { label: "Serbian", value: "sr" },
  { label: "Serbian - Bosnia and Herzegovina", value: "sr-ba" },
  { label: "Serbian - Montenegro", value: "sr-me" },
  { label: "Serbian - Serbia", value: "sr-rs" },
  { label: "Serbian - Serbia and Montenegro (Former)", value: "sr-cs" },
  { label: "Slovak", value: "sk" },
  { label: "Slovak - Slovakia", value: "sk-sk" },
  { label: "Slovenian", value: "sl" },
  { label: "Slovenian - Slovenia", value: "sl-si" },
  { label: "Spanish", value: "es" },
  { label: "Spanish - Argentina", value: "es-ar" },
  { label: "Spanish - Bolivia", value: "es-bo" },
  { label: "Spanish - Chile", value: "es-cl" },
  { label: "Spanish - Colombia", value: "es-co" },
  { label: "Spanish - Costa Rica", value: "es-cr" },
  { label: "Spanish - Cuba", value: "es-cu" },
  { label: "Spanish - Dominican Republic", value: "es-do" },
  { label: "Spanish - Ecuador", value: "es-ec" },
  { label: "Spanish - El Salvador", value: "es-sv" },
  { label: "Spanish - Guatemala", value: "es-gt" },
  { label: "Spanish - Honduras", value: "es-hn" },
  { label: "Spanish - Mexico", value: "es-mx" },
  { label: "Spanish - Nicaragua", value: "es-ni" },
  { label: "Spanish - Panama", value: "es-pa" },
  { label: "Spanish - Paraguay", value: "es-py" },
  { label: "Spanish - Peru", value: "es-pe" },
  { label: "Spanish - Puerto Rico", value: "es-pr" },
  { label: "Spanish - Spain", value: "es-es" },
  { label: "Spanish - United States", value: "es-us" },
  { label: "Spanish - Uruguay", value: "es-uy" },
  { label: "Spanish - Venezuela", value: "es-ve" },
  { label: "Swahili", value: "sw" },
  { label: "Swedish", value: "sv" },
  { label: "Swedish - Finland", value: "sv-fi" },
  { label: "Swedish - Sweden", value: "sv-se" },
  { label: "Syriac", value: "sy" },
  { label: "Tamil", value: "ta" },
  { label: "Tatar", value: "tt" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Thai - Thailand", value: "th-th" },
  { label: "Turkish", value: "tr" },
  { label: "Turkish - Turkey", value: "tr-tr" },
  { label: "Ukrainian", value: "uk" },
  { label: "Ukrainian - Ukraine", value: "uk-ua" },
  { label: "Urdu", value: "ur" },
  { label: "Vietnamese", value: "vi" },
  { label: "Vietnamese - Vietnam", value: "vi-vn" },
  { label: "Bengali", value: "bn" },
  { label: "Tagalog", value: "tl" },
  { label: "Malayalam", value: "ml" },
  { label: "Assamese", value: "as" }
];

export const EnquiryTypeList = [
  { label: "Buyer", value: "Buyer" },
  { label: "Rent / Tenant", value: "Rent / Tenant" },
  { label: "Co-Agent - Client Buy", value: "Co-Agent - Client Buy" },
  { label: "Co-Agent - Client Rent", value: "Co-Agent - Client Rent" },
  { label: "Owner - To Sell", value: "Owner - To Sell" },
  { label: "Owner - Want to Lease", value: "Owner - Want to Lease" },
  { label: "Owner - Sell & Lease", value: "Owner - Sell & Lease" },
  { label: "Agent wants to post listing", value: "Agent wants to post listing" },
  { label: "Spam / Marketing Person", value: "Spam / Marketing Person" },
  {
    label: "Commercial - Buy (land, Warehouse, bldg)",
    value: "Commercial - Buy (land, Warehouse, bldg)"
  },
  {
    label: "Commercial - Lease (land, Warehouse, bldg)",
    value: "Commercial - Lease (land, Warehouse, bldg)"
  },
  { label: "Offices Leasing", value: "Offices Leasing" },
  { label: "Commercial - Hotel", value: "Commercial - Hotel" }
];

export const DEFAULT_TIMEZONE = "Asia/Bangkok";
