import { get } from "@utils/api";
import type { queryType } from "@utils/api";

class ListingOwnerService {
  endpoint: string;
  queries: queryType;

  static async call(value: string): Promise<any> {
    return await new ListingOwnerService("users", { search: value }).getListingOwner();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async getListingOwner(): Promise<any> {
    const path: string = this.endpoint;
    const queries: queryType = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data.map((item: any) => ({
      label: `${item.name} ${item.email && `- ${item.email}`} (${item.role})`,
      value: item.id
    }));
  }
}

export default ListingOwnerService;
