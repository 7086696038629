import { TASK_TYPE } from "@model/task";
import FcmTokenService from "../../../services/fcmToken-services/fcmToken.service";
import { NOTIFICATION_EVENT } from "@model/notification";

export enum FcmTokenActionType {
  store = "store",
  remove = "remove"
}

// Whitelist utl path
export const notificationWhitelistPath = (type: string) => {
  if (type === TASK_TYPE.TASK) return "tasks";
  if (type === TASK_TYPE.VIEWING) return "viewing-deals";
  if (type === TASK_TYPE.DEAL) return "deals";
  return type;
};

export const notificationTypeBadgeColor: { [key: string]: string } = {
  task: "purple",
  viewing: "yellow",
  deal: "red"
};

// create Badge class and change color on type input
export const notificationGetBadgeClass = ({
  type,
  uppercase = false
}: INotificationGetBadgeClass) => {
  const color = notificationTypeBadgeColor[type];
  return `capitalize inline-flex items-center rounded bg-${color}-100 px-2 py-0.5 text-xs font-medium text--${color}-800 ${
    uppercase && "uppercase"
  }`;
};

export interface INotificationGetBadgeClass {
  type: TASK_TYPE;
  uppercase?: boolean;
}

// create notification target url
export const notificationCreateUrl = (
  type: string,
  id: string,
  event: NOTIFICATION_EVENT | null = null
) => {
  if (id && event !== NOTIFICATION_EVENT.BULK_LISTING_REASSIGN) {
    return `/${notificationWhitelistPath(type)}/${id}`;
  }
  return `/${notificationWhitelistPath(type)}`;
};

// action to store and remove fcm-token
export const fcmTokenAction = (action: FcmTokenActionType) => {
  // load fcmToken from local storage
  const fcmToken = localStorage.getItem("fcm-token");
  if (fcmToken) {
    if (action === FcmTokenActionType.store) {
      FcmTokenService.store(fcmToken);
    } else if (action === FcmTokenActionType.remove) {
      FcmTokenService.delete(fcmToken);
      localStorage.removeItem("fcm-token");
    }
  }
};
