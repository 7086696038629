import { get } from "@utils/api";
import type { queryType } from "@utils/api";
import { getPipelineId } from "@views/pages/deals/share";

class StageDropdownService {
  endpoint: string;
  queries: queryType;

  static async call(keySearch: string | null, value: string): Promise<any> {
    const queries: queryType = {};
    let endpoint = "stages";

    // let roleFilters: string[] = ['user', 'admin', 'agent'];
    // if (keySearch && ['seller', 'agent'].includes(keySearch)) roleFilters = ['admin', 'agent'];

    queries.search = value;
    queries.filters = JSON.stringify({
      // pipeline_id: 1
      // 'pipeline': {
      //   'is': roleFilters,
      // },
    });

    return await new StageDropdownService(endpoint, queries).get();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async get(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];
    const pipelineId = getPipelineId();
    return data
      .filter((i: any) => i.pipeline_id === pipelineId)
      .map((item: any) => ({
        label: item.name,
        value: item.id
      }));
  }
}

export default StageDropdownService;
