import { ActionType } from "./action.types";
import { Dispatch } from "redux";
import { Action } from "./action.index";

import { INotificationMenu, rawNotificationType } from "@model/notification";
import { notificationCreateUrl } from "@components/notification-controller/helper";

// convert raw data to notification menu
export const setNotificationMenu = (
  dispatch: Dispatch<Action>,
  payload: rawNotificationType,
  isInit: boolean = false
) => {
  const payloadObj = {
    data:
      payload.data
        ?.filter((i) => !!i.ref)
        .map((notification) => {
          const { ref } = notification;
          return {
            name: notification.title,
            description: notification.description,
            dueDate: ref.scheduled_at ?? "",
            createDate: ref.created_at ?? "",
            updateDate: ref.updated_at ?? "",
            type: notification.type,
            isRead: !!notification.read_at,
            url: notificationCreateUrl(notification.type, ref.id, notification.event),
            id: notification.id,
            ref_id: ref.id
          };
        }) || [],
    meta: {
      nextPage: payload.meta?.current_page + 1 || 1,
      lastPage: payload.meta?.last_page || 1,
      unreadCount: payload.meta?.unread_count || 0
    }
  };

  if (isInit) {
    dispatch({ type: ActionType.SET_NOTIFICATION_MENU, payload: payloadObj });
  } else {
    dispatch({ type: ActionType.UPDATE_NOTIFICATION_MENU, payload: payloadObj });
  }
};

export const setTargetNotificationItemTobeRead = (
  dispatch: Dispatch<Action>,
  notificationMenu: INotificationMenu,
  targetId: number
) => {
  const { data, meta } = notificationMenu;
  const payloadObj = {
    // find the target item then update isRead status to be true
    // reduce unreadCount by 1
    data: data.map((notification) => {
      // const { id } = notification;
      // const { id: targetNotificationId } = item;
      if (notification.id == targetId) {
        return {
          ...notification,
          isRead: true
        };
      }
      return notification;
    }),
    meta: {
      ...meta,
      unreadCount: meta.unreadCount - 1
    }
  };
  // replace notificationMenu with the payload
  dispatch({ type: ActionType.SET_NOTIFICATION_MENU, payload: payloadObj });
};

export const resetNotificationAlert = (dispatch: Dispatch<Action>) =>
  dispatch({ type: ActionType.SET_NOTIFICATION_ALERT, payload: null });

// reset notification menu data when log-out
export const resetNotificationMenu = (dispatch: Dispatch<Action>) =>
  dispatch({ type: ActionType.RESET_NOTIFICATION_MENU });
