import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import TableFilters from "../table.filter";
import TableSettings from "./table.settings";
import { MinTableItem, ITableProps, QueryConfigType, ColumnConfigType } from "../index";

interface ISidebarWrapper<T extends MinTableItem> {
  data: ITableProps<T>;
  columns: ColumnConfigType[];
  setColumns: React.Dispatch<React.SetStateAction<ColumnConfigType[]>>;
  sidebar: { open: boolean; key: string };
  closeSidebar: (tf: boolean) => void;
  queries: QueryConfigType;
  setQueries: React.Dispatch<React.SetStateAction<QueryConfigType>>;
  initialFilters: ColumnConfigType[];
  handleApplyFilters: () => void;
}

export default function SidebarPanel<T extends MinTableItem>({
  data,
  setColumns,
  columns,
  sidebar,
  closeSidebar,
  queries,
  setQueries,
  initialFilters,
  handleApplyFilters
}: ISidebarWrapper<T>) {
  return (
    <Transition.Root show={sidebar.open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-20 overflow-hidden" onClose={closeSidebar}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="flex flex-col h-full bg-white shadow-xl">
                  <div className="p-3 border-b shadow-sm flex-0">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900 capitalize">
                        {sidebar.key}
                      </Dialog.Title>
                      <div className="flex items-center ml-3 h-7">
                        <button
                          type="button"
                          className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-purple-500"
                          onClick={() => closeSidebar(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fal"
                            data-icon="times"
                            className="w-6 h-6 svg-inline--fa fa-times fa-w-10"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="h-full pt-3 overflow-scroll">
                    {data?.tabSettings && columns && sidebar.key === "settings" && (
                      <TableSettings
                        tabSettings={data?.tabSettings}
                        tableColumns={columns}
                        onUpdateColumns={setColumns}
                      />
                    )}
                    {sidebar.key === "filters" && (
                      <TableFilters
                        queries={queries}
                        setQueries={setQueries}
                        filters={initialFilters}
                        onApplyFilters={handleApplyFilters}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
