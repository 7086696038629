import NotificationMenuItem from "./notification.menu-item";
import useNotification from "@components/notification-controller/useNotification";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { selectNotificationMenu } from "@state/selectors";

const NotificationMenuPanel = () => {
  const {
    data,
    meta: { nextPage, lastPage }
  } = useSelector(selectNotificationMenu);
  const [fetchNotificationMenu, onNotificationClick] = useNotification();
  if (data.length === 0) {
    return <NotificationMenuPanelNoItems />;
  }

  return (
    <div className="max-h-[420px] overflow-y-auto relative p-2" id="propNotificationMenuWrapper">
      <div className="flex items-center justify-between ">
        <h2 className="text-xs font-semibold uppercase">Latest</h2>
      </div>
      <ul role="list" className="">
        <InfiniteScroll
          scrollableTarget="propNotificationMenuWrapper"
          dataLength={data.length}
          className="space-y-2 divide-y divide-gray-100"
          next={fetchNotificationMenu}
          hasMore={nextPage <= lastPage}
          loader={<p className="text-sm text-gray-300">Loading...</p>}
        >
          {data?.map((item) => (
            <li className="pt-2" key={item.id}>
              <NotificationMenuItem item={item} onNotificationClick={onNotificationClick} />
            </li>
          ))}
        </InfiniteScroll>
      </ul>
    </div>
  );
};

const NotificationMenuPanelNoItems = () => {
  return (
    <div className="h-[120px] overflow-y-auto relative p-2 flex flex-col justify-center">
      <h2 className="text-xs font-semibold text-center">No notifications to show</h2>
    </div>
  );
};

export default NotificationMenuPanel;
