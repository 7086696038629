import { getCustomSelectStyles } from "@utils/react-select.helpers";
import Select from "react-select";

export function MultipleSelect({ styles, defaultValue, defaultOptions, ...args }: any) {
  return (
    <Select
      {...args}
      isMulti
      closeMenuOnSelect={false}
      options={defaultOptions}
      defaultValue={defaultValue}
      styles={styles ? styles : getCustomSelectStyles(defaultValue)}
    />
  );
}
