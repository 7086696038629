import { EmailForm, useEmail } from "@views/components/email-controller";
import { Modal } from "@views/components";
import { useSelector } from "react-redux";
import { selectCanAccessEmail, selectGlobalEmail } from "@state/selectors";

export default function GlobalEmail() {
  const { refer, data } = useSelector(selectGlobalEmail);
  const [, , , , , onSubmit, onDiscard, handleCloseModal] = useEmail(refer);
  const canAccessEmail = useSelector(selectCanAccessEmail);

  return canAccessEmail ? (
    <Modal modalKey="global_email_form">
      {refer && data && (
        <EmailForm
          refer={refer}
          data={data}
          onSubmit={onSubmit}
          onDiscard={onDiscard}
          closeModal={handleCloseModal}
        />
      )}
    </Modal>
  ) : (
    <Modal modalKey="global_email_form" closableBackdrop>
      <div className="px-3 py-5">
        <p className="text-orange-400">Email creation is temporarily disabled.</p>
      </div>
    </Modal>
  );
}
