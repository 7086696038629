import React, { ReactNode, useState } from "react";
import { snakeToCamel } from "@utils/helpers";

export type dataType = {
  image?: null | string;
  label: null | string;
  value: any;
  isLink?: boolean;
};

interface IProps {
  styleType: "list" | "p" | "log" | "card-list";
  label: string;
  data: dataType[];
  children?: ReactNode;
  subTitle?: ReactNode;
  actionComponent?: ReactNode;
}

export default function Collapse({
  styleType = "list",
  label,
  data,
  children,
  subTitle,
  actionComponent
}: IProps) {
  const [isOpen, setOpen] = useState(true);

  return (
    <div className="mb-6 space-y-1 lg:px-5">
      <div className="flex items-center justify-between">
        <div className="flex space-x-1">
          <h3
            className="text-sm font-semibold tracking-wider text-gray-500 capitalize cursor-pointer hover:underline"
            id="projects-headline"
            onClick={() => setOpen(!isOpen)}
          >
            {label}
          </h3>
          {!!subTitle ? subTitle : null}
        </div>
        <div className="flex space-x-2">
          {actionComponent ? actionComponent : null}
          <svg
            onClick={() => setOpen(!isOpen)}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="caret-down"
            className={`${
              !isOpen && "rotate-90"
            } cursor-pointer transition-all  w-4 h-4 text-gray-500 svg-inline--fa fa-caret-down`}
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z"
            ></path>
          </svg>
        </div>
      </div>
      <div className={`transition-all overflow-hidden  ${isOpen ? " h-full" : "h-0"}`}>
        {/* list style */}
        {styleType === "list" && (
          <div className="mt-1 space-y-3" aria-labelledby="projects-headline">
            {data.map((item: dataType, idx) => (
              <CollapseList key={idx} item={item} />
            ))}
          </div>
        )}
        {styleType === "p" && (
          <div className="mt-1 space-y-3">
            {data.map((item: dataType, idx) => (
              <div key={idx} className="flex space-x-3" id={`move-to-${snakeToCamel(item.label)}`}>
                <p className="text-xs">{item.label}:</p>
                {item.isLink ? (
                  <a
                    className="text-xs text-purple-500 cursor-pointer hover:underline"
                    href={item.value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.value}
                  </a>
                ) : (
                  <p className="text-xs">{item.value}</p>
                )}
              </div>
            ))}
          </div>
        )}

        {/* log style */}
        {styleType === "log" && (children ?? <></>)}

        {/* card list style */}
        {styleType === "card-list" && (
          <div className="flex flex-col space-y-2 overflow-y-auto max-h-72">
            {children ?? <></>}
          </div>
        )}
      </div>
    </div>
  );
}

const CollapseList = ({ item }: { item: dataType }) => {
  return (
    <div className="flex items-center space-x-3">
      {item.image && (
        <div className="max-w-[30px] min-w-[30px]">
          <img className="w-auto h-6" src={item.image} alt="Collapse item" />
        </div>
      )}
      {item.isLink ? (
        <a className="text-xs text-purple-500 cursor-pointer hover:underline" href={item.value}>
          {item.label}
        </a>
      ) : (
        <p className="text-xs text-purple-700 cursor-pointer hover:underline">{item.label}</p>
      )}
    </div>
  );
};

export const AddActionComponent = ({ onClickAction }: { onClickAction: () => void }) => {
  return (
    <p className="text-sm text-blue-500 cursor-pointer" onClick={onClickAction}>
      add
    </p>
  );
};
