import { useSelector, useDispatch } from "react-redux";
import useNotification from "@components/notification-controller/useNotification";
import {
  requestForToken,
  isFCMSupported,
  onMessageListener
} from "@components/notification-controller/firebase";
import { selectUserId, selectUserProfile } from "@state/selectors/index";
import { fcmTokenAction, FcmTokenActionType } from "@components/notification-controller/helper";
import { ActionType } from "@state/actions/action.types";
import { notificationPermission } from "@views/components/notification-controller";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";
import { Messaging } from "firebase/messaging";

export default function useInitApp() {
  const userId = useSelector(selectUserId);
  const authUser = useSelector(selectUserProfile);

  const [fetchNotificationMenu] = useNotification();
  const dispatch = useDispatch();

  useLegacyEffect(() => {
    if (userId && authUser && notificationPermission.includes(authUser.role)) {
      // get notification menu items
      fetchNotificationMenu();

      // FCM INIT
      initFCM();
    }
  }, [userId]);

  // to reload page when user click back/forward button
  useLegacyEffect(() => {
    const handlePopstate = () => {
      if (window.location.href.endsWith("#")) return;
      window.location.reload();
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  });

  const initFCM = async () => {
    const isSupport = await isFCMSupported();

    if (isSupport) {
      if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // continue below
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        const permission = await Notification.requestPermission();
        if (permission !== "granted") return;
      } else {
        console.log("Notification.permission", Notification.permission);
        return;
      }

      // get token save into storage
      requestForToken((messaging: Messaging) => {
        fcmTokenAction(FcmTokenActionType.store);
        onMessageListener(messaging, (payload: any) => {
          dispatch({
            type: ActionType.SET_NOTIFICATION_ALERT,
            payload: { ...payload.notification, data: payload.data }
          });
        });
      });

      return;
    }

    // notify user
    console.log("This browser does not support desktop notification");
  };
  return [] as const;
}
