import { get } from "@utils/api";

class GetDealAttachmentFilesService {
  endpoint: string;

  constructor(dealId: string | number) {
    this.endpoint = `deals/get-attachment-files/${dealId}`;
  }

  static async call(id: string | number): Promise<any> {
    return new GetDealAttachmentFilesService(id).get();
  }

  async get(): Promise<any> {
    const path = this.endpoint;
    const queries = {};
    const response = await get({ path, queries });

    return response;
  }
}

export default GetDealAttachmentFilesService;
