import { UserType } from "@model/contact";
import LazudiLogo from "@images/logo.svg";
import { IUser } from "@views/pages/contacts/listings/contact.listing";
import { useMemo } from "react";
import { IMedia } from "@model/media";
import { UserRole } from "@models/model.app";

export const UserProfileImage = ({
  user,
  image,
  styles
}: {
  user: UserType | IUser;
  image: IMedia | null;
  styles: string;
}) => {
  const isLazudiInternalUser = useMemo(() => {
    if (
      user.role === UserRole.superAdmin ||
      user.role === UserRole.admin ||
      user.role === UserRole.customerSuccess
    )
      return true;

    if (user.role === UserRole.agent) {
      // internal agent only
      return !user.tags || !user.tags.includes("external-agent");
    }

    return false;
  }, [user]);

  return (
    <>
      {image?.url ? (
        <img
          className={`inline-block rounded-full object-cover ${styles}`}
          src={image.url}
          alt="profile-image"
        />
      ) : (
        <FallbackImage
          isLazudiUser={isLazudiInternalUser}
          username={user.name || ""}
          styles={styles}
        />
      )}
    </>
  );
};

export const FallbackImage = ({
  isLazudiUser,
  username,
  styles
}: {
  isLazudiUser: boolean;
  username: string;
  styles?: string;
}) => {
  // default logo for internal agent
  if (isLazudiUser) {
    return (
      <img
        className={
          "inline-block rounded-full object-contain border p-2" +
          (styles ? ` ${styles}` : " w-16 h-16")
        }
        src={LazudiLogo}
        alt="profile-image"
      ></img>
    );
  }

  // display the first character for normal user
  return (
    <div
      className={
        "inline-flex items-center justify-center flex-shrink-0 bg-gray-500 rounded-full" +
        (styles ? ` ${styles}` : " w-16 h-16")
      }
    >
      <span className="font-medium leading-none text-white ">{username[0]}</span>
    </div>
  );
};
