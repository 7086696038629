import InputFilter from "./filter.input";
import AutocompleteFilter from "./filter.autocomplete";
import DatePickerFilter from "./filter.date-picker";
import SelectionFilter from "./filter.selection";
import RangeFilter from "./filter.range";
import AsyncAutoComplete from "./filter.async-select";
import LocationGroup from "./filter.locations";
import ToggleFilter from "./filter.toggle";
import PropertyFeatureGroup from "./filter.property-features";
import { IFilterProps } from "../table.filter";
import { isObject } from "@utils/helpers";

export const getFilterValue = (props: IFilterProps, value: any) => {
  let payload = value;
  if (props.config?.relationship) {
    payload = {
      is: [value],
      relationship: props.config.relationship
    };
  }

  if (props.config?.condition) {
    payload = {
      [props.config.condition]: [value],
      textValue: { label: value, value: value }
    };
  }

  return payload;
};

export const setFilterValue = (props: IFilterProps) => {
  let value = props.value || "";

  if (props?.value?.relationship && props?.value?.is?.length) {
    value = props.value.is[0];
  }

  if (isObject(value) && props.config?.condition) {
    return value[props.config.condition][0];
  }

  return value;
};

export {
  InputFilter,
  DatePickerFilter,
  AutocompleteFilter,
  SelectionFilter,
  RangeFilter,
  AsyncAutoComplete,
  LocationGroup,
  PropertyFeatureGroup,
  ToggleFilter
};
