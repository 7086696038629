import { TaskStore } from "./task.store";

const taskListsSelector = (state: TaskStore) => ({
  tasks: state.tasks,
  setTasks: state.setTasks,
  updateTask: state.updateTask,
  deleteTask: state.deleteTask
});

const taskModalsSelector = (state: TaskStore) => ({
  selectedTask: state.selectedTask,
  setSelectedTask: state.setSelectedTask,
  createTask: state.createTask,
  updateTask: state.updateTask
});

const actionsSelector = (state: TaskStore) => ({
  setSelectedTask: state.setSelectedTask,
  createTask: state.createTask,
  updateTask: state.updateTask,
  deleteTask: state.deleteTask,
  setTasks: state.setTasks
});

export { taskListsSelector, taskModalsSelector, actionsSelector };
