import { ITeam } from "@model/contact";
import { get } from "@utils/api";

class GetTeamsDropdownService {
  endpoint: string;
  queries: object;

  constructor(queries: object) {
    this.endpoint = "teams";
    this.queries = queries;
  }

  static call(queries: object) {
    return new GetTeamsDropdownService(queries).getTeams();
  }

  async getTeams() {
    const { data }: { data: ITeam[] } = await get({
      path: this.endpoint,
      queries: this.queries
    });

    if (!data.length) {
      return [];
    }

    return data.map((item) => ({
      label: item.name,
      value: item.id
    }));
  }
}

export default GetTeamsDropdownService;
