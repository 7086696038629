import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { AppFallback } from "@components/index";
import moment from "moment-timezone";
import "./index.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Redux
import { Provider } from "react-redux";
import { store } from "@state/store";

import App from "./App";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Google Tag Manager
import TagManager from "react-gtm-module";
import { isProduction } from "@utils/helpers";
const IS_PROD = isProduction();

// google auth
let clientId = "446386565565-fn7qonkov7re3gb20a73t8rehs4rojhl.apps.googleusercontent.com";

// set default timezone
const timezone = moment.tz.guess();
moment.tz.setDefault(timezone);

if (IS_PROD) {
  // key for prod
  clientId = "446386565565-sl6ube0ivv7j4hg3ml595ar5l4h819to.apps.googleusercontent.com";

  // Google Tag Manager
  const tagManagerArgs = { gtmId: "G-5JBLT018V4" };
  TagManager.initialize(tagManagerArgs);
  TagManager.dataLayer({ dataLayer: { config: "G-5JBLT018V4" } });
  TagManager.dataLayer({ dataLayer: { js: new Date() } });

  // Sentry
  Sentry.init({
    dsn: "https://37e0b6df3a624445b31ffdc6e6aab19c@o413241.ingest.sentry.io/6312414",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1
  });
}

const root = createRoot(document.getElementById("root")!);

root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <Provider store={store}>
      <Suspense fallback={<AppFallback />}>
        <StrictMode>
          <App />
          <ToastContainer newestOnTop />
        </StrictMode>
      </Suspense>
    </Provider>
  </GoogleOAuthProvider>
);
