import { UserType } from "@model/contact";
import { IDeal } from "@model/deal";
import { IPipeline, PipelineType } from "@model/pipeline";
import { IProperty } from "@model/property";

export const isPipelineWithStageRules = (pipeline: IPipeline): boolean => {
  const pipelineWithStageRules = [
    PipelineType.BUYER,
    PipelineType.SELLER,
    PipelineType.LEADS,
    PipelineType.REFERRED_LEADS,
    PipelineType.DUBAI_INSTRUCTION
  ];
  return pipelineWithStageRules.includes(pipeline.id);
};

export const getAssociatedPropertiesByDeal = (deal: IDeal): IProperty[] => {
  let dealProperties;

  const primaryProperty = deal.property ? [deal.property] : [];
  if (primaryProperty.length > 0) {
    const filterPrimaryProperties = deal.properties?.filter((property) => {
      return property.id !== deal.property.id;
    });
    dealProperties = primaryProperty.concat(filterPrimaryProperties ?? []);
  } else {
    dealProperties = deal.properties ? deal.properties : [];
  }

  return dealProperties.reduce((unique, o) => {
    if (!unique.some((property) => property.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, [] as IProperty[]);
};

export const getAssociatedContactsByDeal = (deal: IDeal): UserType[] => {
  let dealContacts;

  if (deal.user) {
    const primaryContact = deal.user ? [deal.user] : [];

    const filterPrimaryContact = deal.users?.filter((user) => {
      return user.id !== deal.user?.id;
    });
    dealContacts = primaryContact.concat(filterPrimaryContact ?? []);
  } else {
    dealContacts = deal.users ? deal.users : [];
  }

  return dealContacts.reduce((unique, o) => {
    if (!unique.some((property) => property.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, [] as UserType[]);
};

type formLabelsType = {
  title: string;
  value: string;
  user: string;
};

export const formLabelMapping: { [key in PipelineType]: formLabelsType } = {
  [PipelineType.NONE]: { title: "", value: "", user: "" },
  [PipelineType.LEADS]: {
    title: "Create a new lead",
    value: "Budget",
    user: "Buyer / Tenant Contact"
  },
  [PipelineType.BUYER]: { title: "Create a new deal", value: "Value", user: "Contact" },
  [PipelineType.COMMISSION]: {
    title: "Create a new Seller / Landlord deal",
    value: "Property Price",
    user: "Property Seller Contact"
  },
  [PipelineType.SELLER]: { title: "Create a new deal", value: "Value", user: "Contact" },
  [PipelineType.VIEWINGS]: { title: "Create a new deal", value: "Value", user: "Contact" },
  [PipelineType.DEVELOPER]: { title: "Create a new deal", value: "Value", user: "Contact" },
  [PipelineType.REFERRED_LEADS]: { title: "Create a new deal", value: "Value", user: "Contact" },
  [PipelineType.DUBAI_INSTRUCTION]: { title: "Create a new deal", value: "Value", user: "Contact" }
};

export const getPipelineId = () => {
  const { pathname } = window.location;
  if (pathname === "/leads") return PipelineType.LEADS; //'leads'
  if (pathname === "/deals") return PipelineType.BUYER; //'buyer deals'
  if (pathname === "/commission-deals") return PipelineType.COMMISSION; //'commissions'
  if (pathname === "/seller-deals") return PipelineType.SELLER; //'sellers'
  if (pathname.startsWith("/viewing-deals")) return PipelineType.VIEWINGS; //'viewings'
  if (pathname === "/developer-deals") return PipelineType.DEVELOPER; //'developers'
  if (pathname === "/referred-leads") return PipelineType.REFERRED_LEADS; //'referred leads'
  if (pathname === "/dubai-instructions")
    return PipelineType.DUBAI_INSTRUCTION; //'dubai instructions'
  else return 0;
};
