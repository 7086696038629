import { ITask } from "@model/task";
import { put } from "@utils/api";

class UpdateTaskService {
  endpoint: string;
  data: any;

  static async call(id: number | string, item: any): Promise<ITask> {
    const service = new UpdateTaskService(`tasks/${id}`, item);
    return await service.call();
  }

  constructor(endpoint: string, data: any) {
    this.endpoint = endpoint;
    this.data = data;
  }

  async call(): Promise<any> {
    const path = this.endpoint;
    const {
      data: { data }
    }: any = await put({ path, data: this.data });
    return data;
  }
}

export default UpdateTaskService;
