import React from "react";
import { FormGroup, InlineInput } from "@views/components";
import { Condition, FormInlineFieldProps } from "@models/model.form-field";

export type { Condition };

export function isValidCondition(conditions: Condition[]): boolean {
  const results: boolean[] = conditions.map(({ key, matches }) => matches.includes(key));
  // Remove duplicated results e.g. [true, true] => [true], [true, false, false] = [true, false].
  const uniqResults: boolean[] = [...new Set(results)];

  // NOTE: After removed duplicated results, THERE IS ONLY ONE TRUTH!!! (ความจริงมีเพียงหนึ่งเดียวเสมอ)
  // `uniqResults = [true]`, that means all condition are matched.
  return !(uniqResults.length > 1 || !uniqResults[0]);
}

export default function FormInlineField({
  label,
  field,
  errors,
  required,
  name,
  inputType,
  customValue,
  options,
  register,
  controller,
  control,
  readonly = false,
  editonly = false,
  conditions,
  formGroupClass,
  formDisplayType = "horizontal",
  children
}: FormInlineFieldProps): JSX.Element | null {
  if (conditions && !isValidCondition(conditions)) {
    return null;
  }

  return (
    <FormGroup
      label={label}
      field={field}
      labelWidthClass={formGroupClass}
      errors={errors}
      required={required}
      readonly={readonly}
      displayType={formDisplayType}
    >
      <InlineInput
        name={name}
        inputType={inputType}
        customValue={customValue}
        register={register}
        Controller={controller}
        control={control}
        options={options}
        readonly={readonly}
        editonly={editonly}
      />
      {children}
    </FormGroup>
  );
}
