import { ConfirmDialog, Modal } from "@views/components";
import { MainForm } from "@views/components/task-controller";
import useTaskModals from "@views/components/task-controller/hooks/useTaskModals";

const TaskModals = () => {
  const {
    type,
    selectedTask,
    defaultObject,
    handleCreateNewTask,
    onUpdateTask,
    handleCloseUpdateModal,
    onConfirm,
    closeModal
  } = useTaskModals();

  return (
    <>
      {/* New task form */}
      <Modal modalKey={`create_new_${type}`} title={`Create a new ${type}`}>
        <MainForm
          handleSubmit={handleCreateNewTask}
          handleCancel={closeModal}
          type={type}
          data={defaultObject}
        />
      </Modal>

      {/* Edit task */}
      {selectedTask && (
        <Modal modalKey={`show_task_${selectedTask.id}`} title={`Update ${selectedTask.type}`}>
          <MainForm
            handleSubmit={onUpdateTask}
            handleCancel={handleCloseUpdateModal}
            type={selectedTask.type}
            data={selectedTask}
          />
        </Modal>
      )}

      {/* Remove confirmation */}
      <Modal modalKey={`delete_task`}>
        <ConfirmDialog
          onConfirm={onConfirm}
          onCancel={closeModal}
          options={{
            title: "Delete this note?",
            subTitle: "Are you sure you want to permanently delete this item?",
            btnStyle: "negative"
          }}
        />
      </Modal>
    </>
  );
};

export default TaskModals;
