import React from "react";
import { IFilterProps } from "../table.filter";
import { classNames } from "@utils/helpers";
import { getFilterValue, setFilterValue } from "./filters.index";

// interface IProps {
//   id: string
//   inputType: string
//   setFilterState: (prev: {}) => void
//   value: string | number
// }

export default function InputFilter(props: IFilterProps) {
  let value = setFilterValue(props);

  return (
    <input
      id={props.id}
      key={`filter_${props.id}`}
      type={props.inputType}
      autoComplete="off"
      onChange={(e) =>
        props.setFilterState((prev: {}) => ({
          ...prev,
          [e.target.id]: getFilterValue(props, e.target.value)
        }))
      }
      value={value}
      className={classNames(
        props.value
          ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
          : "focus:border-gray-300",
        "block w-full border-gray-300 rounded-md focus:ring-0  sm:text-sm"
      )}
    />
  );
}
