import React from "react";
import { snakeToCamel } from "@utils/helpers";

interface FormGroupProps {
  label: string;
  field: string;
  errors: any;
  displayType?: "horizontal" | "vertical";
  errorPosition?: "horizontal" | "vertical";
  required?: boolean;
  readonly?: boolean;
  children: React.ReactNode;
  labelWidthClass?: string;
  endIcon?: JSX.Element | string;
}

export default function FormGroup({
  label,
  field,
  errors,
  displayType = "horizontal",
  errorPosition = "horizontal",
  required = false,
  readonly = false,
  children,
  labelWidthClass = "sm:w-[200px]",
  endIcon
}: FormGroupProps): JSX.Element {
  const isHorizontalGroup = displayType === "horizontal";
  const isHorizontalPosition = errorPosition === "horizontal" || isHorizontalGroup;

  const error = (() => {
    const isNested = field.includes(".");

    if (!Object.entries(errors).length) {
      return;
    }

    if (isNested) {
      const fields = field.split(".");
      const errorMessage = fields.reduce((prev: any, cur: any) => {
        if (!prev) {
          return errors[cur];
        }

        return prev[cur];
      }, "");

      return errorMessage;
    } else {
      return errors[field];
    }
  })();

  return (
    <div
      className={`relative flex flex-col py-2 text-sm ${
        isHorizontalGroup && "sm:px-4 sm:flex-row"
      }`}
      id={`move-to-${snakeToCamel(field)}`}
    >
      <div
        className={`flex-0 w-full text-gray-500 flex items-center ${labelWidthClass} ${
          isHorizontalGroup ? "h-[40px]" : "pb-2"
        }`}
      >
        <p className={`pr-2 select-none ${error && "text-red-500"}`} title={label}>
          {label}
          {!readonly && required && (
            <sub className="text-sm text-red-500 relative top-[-2px]">*</sub>
          )}
        </p>
        {endIcon}
      </div>
      <div className={`flex flex-1 ${isHorizontalPosition ? "items-center" : "flex-col"}`}>
        {children}
        {!readonly && error && (
          <p className="ml-2 text-xs text-red-400 flex-0 whitespace-nowrap">{error.message}</p>
        )}
      </div>
    </div>
  );
}
