import { IArticleTag } from "@model/article";
import { get, queryType } from "@utils/api";

class GetTagsDropdownService {
  endpoint: string = "tags";
  queries: queryType;

  static call(queries: queryType): Promise<any> {
    return new GetTagsDropdownService(queries).getTags();
  }

  constructor(queries: queryType) {
    this.queries = queries;
  }

  async getTags(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data }: { data: IArticleTag[] } = await get({ path, queries });

    if (!data.length) {
      return [];
    }

    return data.map((item) => ({
      label: item.name,
      value: item.id,
      locale: item.locale
    }));
  }
}

export default GetTagsDropdownService;
