import { get } from "@utils/api";
import { IMessage } from "@model/email";
import { serializeFilters } from "@utils/helpers";

type resMeta = {
  current_page: number;
  from: number;
  last_page: number;
  links: any;
  path: string;
  per_page: string;
  to: number;
  total: number;
};
export type filters = {
  deal_id?: number;
  property_id?: number;
  contact_id?: number;
  project_id?: number;
};
export type queries = {
  // page: number
  offset: number;
  filters: filters;
};
class GetEmailThreadService {
  endpoint: string;
  queries: any;

  static async call(queries: any): Promise<{ data: IMessage[]; meta: resMeta }> {
    const service = new GetEmailThreadService("messages", {
      ...queries,
      per_page: 20,
      // page: 1,
      sort: "desc",
      sort_by: "id",
      offset: queries?.offset?.toString() || "0",
      filters: serializeFilters(queries.filters) //JSON.stringify(queries.filters),
      // ...queries.filters
    });
    return await service.call();
  }

  constructor(endpoint: string, queries: any) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async call(): Promise<any> {
    const path = this.endpoint;
    const res = await get({ path, queries: this.queries });
    return res;
  }
}

export default GetEmailThreadService;
