import { EmailType, IEmailTemplate } from "@model/email";
import { InlineInput, toast } from "@views/components";
import FormInlineField from "@views/components/form-inline-field";
import { useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { folderValidationRules, templateValidationRules } from ".";
import { EmailTemplateFormType } from "../..";

const EditEmailTemplateForm = ({
  folderId,
  template,
  formSubmitHandler,
  onCancel
}: {
  folderId: number;
  template: IEmailTemplate | null;
  formSubmitHandler: (
    payload: EmailTemplateFormType,
    onComplete: (isSuccess: boolean) => void
  ) => void;
  onCancel: () => void;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const type = template ? template.type : EmailType.template;
  const isFolder = type === EmailType.folder;

  const validationRules = isFolder ? folderValidationRules : templateValidationRules;
  const { handleSubmit, control, register } = useForm<EmailTemplateFormType>({
    defaultValues: template
      ? template
      : {
          id: 0,
          name: "",
          subject: "",
          template: "",
          folderId: folderId ? folderId : null,
          type: EmailType.template
        },
    resolver: validationRules
  });
  const { errors } = useFormState({ control });

  const onSubmitEmailTemplate = (payload: EmailTemplateFormType) => {
    setLoading(true);

    formSubmitHandler(payload, (isSuccess) => {
      if (isSuccess) {
        toast.success("Data has been updated.");
      } else {
        toast.error("Fail to save the email template.");
      }

      setLoading(false);
    });
  };

  const submitButtonLabel = () => {
    if (template) {
      if (template.id === 0) {
        if (template.type === EmailType.template) {
          return "Create";
        } else if (template.type === EmailType.folder) {
          return "Create Folder";
        }
      } else {
        return "Update";
      }
    }
    return "Create";
  };

  return (
    <form onSubmit={handleSubmit(onSubmitEmailTemplate)}>
      <div className="relative w-full text-sm">
        {isLoading && (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-white opacity-80"></div>
        )}

        <FormInlineField
          label="Name"
          name="name"
          formGroupClass="sm:w-[100px]"
          field="name"
          errors={errors}
          register={register}
          control={control}
          inputType="text"
        />
        {!isFolder && (
          <FormInlineField
            label="Subject"
            name="subject"
            formGroupClass="sm:w-[100px]"
            field="subject"
            errors={errors}
            register={register}
            control={control}
            inputType="text"
          />
        )}

        {!isFolder && (
          <div className="py-2 sm:px-4">
            <InlineInput
              name="template"
              register={register}
              control={control}
              Controller={Controller}
              options={{
                init: {
                  height: 500,
                  paste_data_images: true,
                  plugins: ["paste", "lists", "link", "textcolor"],
                  toolbar: [
                    {
                      name: "",
                      items: ["link", "forecolor", "backcolor"]
                    }
                  ]
                }
              }}
              editonly={true}
              inputType="editor"
            />
            {errors.template && (
              <p className="mt-2 text-xs text-red-400">{errors.template.message}</p>
            )}
          </div>
        )}
      </div>

      {isLoading ? (
        <LoadingState />
      ) : (
        <div className="pb-6 mt-7 sm:px-4 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="submit"
            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-purple-600 border border-transparent rounded-md shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:col-start-2 sm:text-sm"
          >
            {submitButtonLabel()}
          </button>
          <button
            type="button"
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      )}
    </form>
  );
};

const LoadingState = () => {
  return (
    <div className="p-4 mt-2 mb-4 rounded-md bg-purple-50">
      <p className="text-sm text-center text-purple-700">
        Saving an email template, Please wait...
      </p>
    </div>
  );
};

export default EditEmailTemplateForm;
