import React from "react";
import { IFilterProps } from "../table.filter";
import { classNames } from "@utils/helpers";
import { getFilterValue, setFilterValue } from "./filters.index";

export default function SelectionFilter(props: IFilterProps) {
  let value = setFilterValue(props);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.setFilterState((prev: {}) => ({
      ...prev,
      [e.target.id]: e.target.value ? getFilterValue(props, e.target.value) : null
    }));
  };

  return (
    <select
      id={props.id}
      className={classNames(
        props.value
          ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
          : "focus:border-gray-300",
        "block w-full px-3 border-gray-300 focus:ring-0 py-2 pl-3 pr-10 text-sm  rounded-md sm:text-xs h-[38px]"
      )}
      onChange={handleChange}
      value={value}
    >
      <option value="">Any</option>
      {props?.config?.options?.map((option) => {
        // console.log(option);
        if (option.options) {
          return (
            <optgroup key={option.value} label={option?.label?.toString() || ""}>
              {option.options.map((optgroup: { value: string; label: string }) => {
                return (
                  <option key={optgroup.value} value={optgroup.value}>
                    {optgroup.label}
                  </option>
                );
              })}
            </optgroup>
          );
        }
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
}
