import { Disclosure } from "@headlessui/react";
import { sidebarNavigation } from "./layout.configs";
import { NavLink } from "react-router-dom";
import { classNames, permissionScope, isLiveFeature } from "@utils/helpers";
import { logout } from "@state/actions/action.creators";
import { useDispatch } from "react-redux";

export default function MobileNav({ role }: { role: string }) {
  const dispatch = useDispatch();
  return (
    <nav className="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
      {sidebarNavigation
        .filter((menu) => permissionScope(menu.permission, role) && isLiveFeature(menu.id))
        .map((item) =>
          !item.subMenus ? (
            <NavLink
              key={item.id}
              to={item.path}
              activeClassName="bg-gray-200 text-gray-900"
              className="flex items-center w-full py-2 pl-2 text-sm font-medium text-gray-600 bg-white rounded-md hover:bg-gray-50 hover:text-gray-900 group"
            >
              <item.icon className="flex-shrink-0 w-6 h-6 mr-3" aria-hidden="true" />
              <span>{item.name}</span>
            </NavLink>
          ) : (
            <Disclosure as="div" key={item.id} className="space-y-1">
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      item.path === window.location.pathname ||
                        !!item.parentOf.includes(window.location.pathname)
                        ? "bg-gray-100 text-gray-900"
                        : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none "
                    )}
                  >
                    <item.icon
                      className="flex-shrink-0 w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                    <svg
                      className={classNames(
                        open ? "text-gray-400 rotate-90" : "text-gray-300",
                        "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                      )}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1">
                    {item.subMenus
                      ?.filter((menu) => permissionScope(menu.permission, role))
                      .map((subItem) => (
                        <div
                          key={subItem.name}
                          onClick={() => {
                            if (item.name === "Log out") logout(dispatch);
                          }}
                        >
                          <Disclosure.Button
                            key={subItem.name}
                            as="a"
                            href={subItem.path}
                            className="flex items-center w-full py-1 pl-8 text-sm font-medium rounded-md group hover:text-gray-900 hover:bg-gray-50"
                          >
                            <div
                              className={classNames(
                                "flex items-center flex-1 p-2 space-x-2 rounded-md ",
                                subItem.path === window.location.pathname && "text-purple-500"
                              )}
                            >
                              {subItem.icon && (
                                <subItem.icon
                                  className="relative z-10 w-4 h-4"
                                  aria-hidden="true"
                                />
                              )}

                              <span>{subItem.name}</span>
                            </div>
                          </Disclosure.Button>
                        </div>
                      ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )
        )}
    </nav>
  );
}
