import React from "react";
import { IFilterProps } from "../table.filter";
import { classNames } from "@utils/helpers";
import { useSelector } from "react-redux";
import { selectInputOptions } from "@state/selectors";
import { getFilterValue, setFilterValue } from "./filters.index";

export default function SiteSelectionFilter(props: IFilterProps) {
  const INPUT_OPTIONS = useSelector(selectInputOptions);
  const miniSiteOptions = INPUT_OPTIONS["partner_website"]
    ? [...INPUT_OPTIONS["partner_website"], { label: "Lazudi", value: "lazudi" }]
    : [];
  let value = setFilterValue(props);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.setFilterState((prev: {}) => ({
      ...prev,
      [e.target.id]: e.target.value ? getFilterValue(props, e.target.value) : null
    }));
  };

  return (
    <select
      id={props.id}
      className={classNames(
        props.value
          ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
          : "focus:border-gray-300",
        "block w-full px-3 border-gray-300 focus:ring-0 py-2 pl-3 pr-10 text-sm  rounded-md sm:text-xs h-[38px]"
      )}
      onChange={handleChange}
      value={value}
    >
      <option value="">Any</option>
      {miniSiteOptions.map((option) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
}
