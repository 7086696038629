import { EmailType, GlobalEmailReferType, IEmailTemplate, IMessage } from "@model/email";
import GetEmailTemplateDetailService from "@services/emails/templates/get-detail-email-template.service";
import { useState } from "react";
import EmailForm from "./email.form";
import EmailTemplateSelector from "@components/email-controller/components/form/template-selector/email.template-selector";
import { XIcon } from "@heroicons/react/solid";

interface IEmailContainer {
  refer: GlobalEmailReferType;
  data: IMessage;
  onSubmit: (item: IMessage) => Promise<void>;
  onDiscard: (id: number) => void;
  closeModal: () => void;
}

const DEFAULT_EMAIL_TEMPALTE = {
  id: 0,
  name: "",
  subject: "",
  template: "",
  type: EmailType.template
};

export default function EmailContainer(props: IEmailContainer) {
  const [selectedTemplate, setSelectedTemplate] = useState<IEmailTemplate | null>(null);

  const handleSelectedTemplate = async (id: number) => {
    // reset selected template with default values
    setSelectedTemplate(DEFAULT_EMAIL_TEMPALTE);

    // get parsed email body from api;
    try {
      const template: IEmailTemplate = await GetEmailTemplateDetailService.call(id, {
        contact_id: props.refer.contact_id
      });
      setSelectedTemplate(template);
    } catch (error) {
      return setSelectedTemplate(null);
    }
  };

  return (
    <div className="relative w-full sm:w-[650px]">
      <div className="flex justify-between p-4 mb-4">
        <div className="flex items-center gap-x-3">
          <p className="font-medium">Email</p>
          <EmailTemplateSelector onSelected={handleSelectedTemplate} />
        </div>
        <XIcon
          onClick={() => props.closeModal()}
          className="w-5 h-5 text-red-500 hover:cursor-pointer hover:text-red-700"
        />
      </div>

      <EmailForm {...props} selectedTemplate={selectedTemplate} />
    </div>
  );
}
