import { Transition, Popover } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectNotificationMenu, selectUserProfile } from "@state/selectors";
import NotificationMenuPanel from "@components/notification-controller/components/notification.menu-panel";
import { notificationPermission } from "..";

const NotificationMenu = () => {
  const authUser = useSelector(selectUserProfile);
  const {
    meta: { unreadCount }
  } = useSelector(selectNotificationMenu);

  if (authUser && !notificationPermission.includes(authUser.role)) return null;

  return (
    <Popover as="div" className="relative inline-block text-left">
      {!!unreadCount && (
        <div className="absolute top-[-5px] right-[-5px] z-10 flex items-center justify-center w-4 h-4 bg-red-500 rounded-full">
          <p className="text-[9px] text-white">{unreadCount}</p>
        </div>
      )}
      <Popover.Button>
        <span className="sr-only">View notifications</span>
        <BellIcon className="w-6 h-6 text-gray-800" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right bg-white rounded-md shadow-lg w-80 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <NotificationMenuPanel />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default NotificationMenu;
