// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNKooyyXWuIZlx6GEsugZbOINsVf_ROA0",
  authDomain: "crm-notification-468ae.firebaseapp.com",
  projectId: "crm-notification-468ae",
  storageBucket: "crm-notification-468ae.appspot.com",
  messagingSenderId: "750566967819",
  appId: "1:750566967819:web:cfa5b49bcc0b8ed4a2950e",
  measurementId: "G-1S0LJ6LJ1E"
};

export const requestForToken = (cb) => {
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  try {
    return getToken(messaging, {
      vapidKey:
        "BFlkpAUfYI0kcs0yBDzMzWsP2yR0vwBHD9U44ab3qdLdJlhMWqvh5vQh-kFXUady7pCuzsXsSE6GAJXKjObV1Us"
    })
      .then((currentToken) => {
        if (currentToken) {
          // console.log('current token for client: ', currentToken);
          // alert('current token for client.')
          // store to DB
          localStorage.setItem("fcm-token", currentToken);
          cb(messaging);
        } else {
          // Show permission request UI
          console.log("No registration token available. Request permission to generate one.");
          alert("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // alert('An error occurred while retrieving token. ')
      });
  } catch (error) {
    console.log("An error occurred while retrieving token. ");
  }
};

export const isFCMSupported = () => isSupported();

export const onMessageListener = (messaging, cb) =>
  onMessage(messaging, (payload) => {
    cb(payload);
  });
