import { IMessage } from "@model/email";
import { WithIndicator } from "@views/components";
import EmailCard from "./email.card";
import { selectCanAccessEmail } from "@state/selectors";
import { useSelector } from "react-redux";

export default function EmailMessageTemplate({
  isNewPending,
  items,
  setItem
}: {
  isNewPending: boolean;
  items: IMessage[] | null;
  setItem: (item: IMessage) => void;
}) {
  const canAccessEmail = useSelector(selectCanAccessEmail);

  return (
    <div className="space-y-3">
      {/* // todo comment this after email bug fix */}
      {!canAccessEmail && (
        <p className="text-right text-orange-300">Email creation is temporarily disabled.</p>
      )}
      {isNewPending && (
        <div className="relative block h-auto px-2 py-4 text-center text-gray-600 bg-gray-100 shadow sm:rounded-md sm:pr-6 sm:pl-3">
          Pending..
        </div>
      )}

      <WithIndicator isLoaded={items !== null} isEmpty={!isNewPending && items?.length === 0}>
        {items &&
          items.map((message: IMessage) => {
            return (
              <>
                {/* <div className="flex pt-3 space-x-1 text-sm font-medium text-gray-400 ">
              <p>({thread.messages.length})</p><p className='truncate'  dangerouslySetInnerHTML={{__html: thread.snippet}}></p>
            </div> */}
                <EmailCard item={message} onSelectItem={setItem} />
              </>
            );
          })}
      </WithIndicator>
    </div>
  );
}
