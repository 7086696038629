import React from "react";
import { FilterIcon } from "@components/icon";
import { QueryConfigType } from "../index";

export const NotifyBar = React.memo(
  ({
    notifyNumber,
    type,
    label,
    setOpen
  }: {
    notifyNumber: number;
    type: string;
    label: string;
    setOpen: (t: boolean) => void;
  }) => {
    return (
      <div className="relative flex justify-end cursor-pointer" onClick={() => setOpen(true)}>
        {notifyNumber > 0 && (
          <div className="absolute top-[-7px] left-[-10px] flex items-center justify-center w-4 h-4 bg-red-500 rounded-full">
            <p className="text-[9px] text-white">{notifyNumber}</p>
          </div>
        )}
        <div className="flex justify-center space-x-2 group">
          {type === "filters" && (
            <FilterIcon className="w-5 h-5 text-gray-600 cursor-pointer svg-inline--fa fa-filter-list" />
          )}
          {type === "settings" && (
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="cog"
              className="w-5 h-5 text-gray-500 cursor-pointer svg-inline--fa fa-cog fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M482.696 299.276l-32.61-18.827a195.168 195.168 0 0 0 0-48.899l32.61-18.827c9.576-5.528 14.195-16.902 11.046-27.501-11.214-37.749-31.175-71.728-57.535-99.595-7.634-8.07-19.817-9.836-29.437-4.282l-32.562 18.798a194.125 194.125 0 0 0-42.339-24.48V38.049c0-11.13-7.652-20.804-18.484-23.367-37.644-8.909-77.118-8.91-114.77 0-10.831 2.563-18.484 12.236-18.484 23.367v37.614a194.101 194.101 0 0 0-42.339 24.48L105.23 81.345c-9.621-5.554-21.804-3.788-29.437 4.282-26.36 27.867-46.321 61.847-57.535 99.595-3.149 10.599 1.47 21.972 11.046 27.501l32.61 18.827a195.168 195.168 0 0 0 0 48.899l-32.61 18.827c-9.576 5.528-14.195 16.902-11.046 27.501 11.214 37.748 31.175 71.728 57.535 99.595 7.634 8.07 19.817 9.836 29.437 4.283l32.562-18.798a194.08 194.08 0 0 0 42.339 24.479v37.614c0 11.13 7.652 20.804 18.484 23.367 37.645 8.909 77.118 8.91 114.77 0 10.831-2.563 18.484-12.236 18.484-23.367v-37.614a194.138 194.138 0 0 0 42.339-24.479l32.562 18.798c9.62 5.554 21.803 3.788 29.437-4.283 26.36-27.867 46.321-61.847 57.535-99.595 3.149-10.599-1.47-21.972-11.046-27.501zm-65.479 100.461l-46.309-26.74c-26.988 23.071-36.559 28.876-71.039 41.059v53.479a217.145 217.145 0 0 1-87.738 0v-53.479c-33.621-11.879-43.355-17.395-71.039-41.059l-46.309 26.74c-19.71-22.09-34.689-47.989-43.929-75.958l46.329-26.74c-6.535-35.417-6.538-46.644 0-82.079l-46.329-26.74c9.24-27.969 24.22-53.869 43.929-75.969l46.309 26.76c27.377-23.434 37.063-29.065 71.039-41.069V44.464a216.79 216.79 0 0 1 87.738 0v53.479c33.978 12.005 43.665 17.637 71.039 41.069l46.309-26.76c19.709 22.099 34.689 47.999 43.929 75.969l-46.329 26.74c6.536 35.426 6.538 46.644 0 82.079l46.329 26.74c-9.24 27.968-24.219 53.868-43.929 75.957zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"
              />
            </svg>
          )}
          <p className="hidden md:block text-sm text-gray-500 group-hover:text-gray-800">{label}</p>
        </div>
      </div>
    );
  }
);

export const Sorting = (props: { name: string; queries: QueryConfigType }) => {
  return props.queries.sort_by === props.name && props.queries.sort === "desc" ? (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="sort-down"
      className="w-3 h-3 text-gray-400 svg-inline--fa fa-sort-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <g className="fa-duotone-group">
        <path
          className="fa-secondary"
          fill="currentColor"
          d="M27.66 223.1h264.7c24.6 0 36.89-29.77 19.54-47.12l-132.5-136.8c-5.352-5.406-12.39-8.109-19.45-8.109c-7.053 0-14.12 2.703-19.53 8.109L8.119 176.9C-9.229 194.2 3.055 223.1 27.66 223.1zM159.9 111.1l46.5 48.01H113.5L159.9 111.1z"
        />
        <path
          className="fa-primary"
          fill="currentColor"
          d="M292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.4 136.8C145.9 477.3 152.1 480 160 480c7.055 0 14.15-2.702 19.5-8.109l132.4-136.8C329.2 317.8 316.9 288 292.3 288z"
        />
      </g>
    </svg>
  ) : (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="sort-up"
      className="w-3 h-3 text-gray-400 svg-inline--fa fa-sort-up"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <g className="fa-duotone-group">
        <path
          className="fa-secondary"
          fill="currentColor"
          d="M292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288zM160.1 400L113.6 352h92.95L160.1 400z"
        />
        <path
          className="fa-primary"
          fill="currentColor"
          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
        />
      </g>
    </svg>
  );
};

export const NoDataFound = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center w-full bg-gray-300 opacity-50">
      <p className="text-lg text-gray-500">No data found.</p>
    </div>
  );
};
