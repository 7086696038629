import { Menu } from "@headlessui/react";
import classNames from "classnames";

type ActionItemProp = {
  label: string;
  onClick: () => void;
  customStyles?: string;
};

const ActionItem = ({ label, onClick, customStyles }: ActionItemProp) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={() => onClick()}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm w-full text-left",
            customStyles
          )}
        >
          {label}
        </button>
      )}
    </Menu.Item>
  );
};

export default ActionItem;
