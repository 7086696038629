import React, { useState } from "react";
import { ReactComponent as EllipsisSolid } from "@icons/ellipsis-solid.svg";
import { IMessage, IAttachment } from "@model/email";
import DownloadImageService from "@services/images/download-image.service";

// function createElementFromHTML(htmlString: string) {
//   var div = document.createElement('div');
//   div.innerHTML = htmlString.trim();

//   // Change this to div.childNodes to support multiple top-level nodes.
//   return div;
// }

// function xml2string(node: any) {
//   if (typeof (XMLSerializer) !== 'undefined') {
//     var serializer = new XMLSerializer();
//     return serializer.serializeToString(node);
//   } else if (node?.xml) {
//     return node.xml;
//   }
//   return '';
// }

export default function EmailContent(props: { isFullCard: boolean; item: IMessage }) {
  const [showHistory, setShowHistory] = useState(false);
  const { isFullCard, item } = props;

  // const html = createElementFromHTML(props?.item.html)
  // const main = html?.firstChild
  // const history = (html.children.length > 1) ? html?.lastChild : ''

  return (
    <div className="w-full mt-2 text-sm prose-sm prose">
      {/* content */}
      {item.html && <div dangerouslySetInnerHTML={{ __html: item.html }}></div>}

      {/* ATTACHMENTS */}
      {/* {
        !!item?.attachments?.length && (
          <div className="flex flex-col py-3 mt-3 space-y-1 text-xs text-gray-500 border-t border-gray-100">
            {
              item?.attachments?.map((attachment) => (
                <a href='#'
                  className='text-blue-500 underline'
                  onClick={(e: React.MouseEvent<HTMLElement>) => downloadAttachment(e, attachment)}>{attachment.file_name}</a>
              ))
            }
          </div>
        )
      } */}

      {item.history_html && (
        <div className="mt-2">
          {isFullCard && (
            <div
              className="px-2 py-0 bg-gray-100 rounded-lg cursor-pointer w-max hover:bg-gray-200 "
              onClick={() => setShowHistory(!showHistory)}
            >
              <EllipsisSolid className="w-3 text-gray-500" />
            </div>
          )}

          {/* HISTORY */}
          <div className={`p-2 mt-6 text-sm bg-gray-50 ${showHistory ? "visible" : "hidden"}`}>
            <div className="" dangerouslySetInnerHTML={{ __html: item.history_html }}></div>
          </div>
        </div>
      )}
    </div>
  );
}
