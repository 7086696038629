import { Modal } from "@views/components";
import {
  RecommendationForm,
  useGooglePermissionModal
} from "@views/components/google-permission-modal";

export default function GooglePermissionModal() {
  useGooglePermissionModal();
  return (
    <>
      <Modal modalKey="google-permission-modal" title="Google Permissions Required">
        <RecommendationForm />
      </Modal>
    </>
  );
}
