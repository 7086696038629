import { formatDate } from "@utils/helpers";
import { notificationGetBadgeClass } from "@components/notification-controller/helper";
import { NotificationMenuItemType, NotificationOnClickType } from "@model/notification";
import parse from "html-react-parser";

const NotificationMenuItem = ({
  item,
  onNotificationClick
}: {
  item: NotificationMenuItemType;
  onNotificationClick: (item: NotificationMenuItemType, type: NotificationOnClickType) => void;
}) => {
  const { name, description, isRead, type, dueDate = "", createDate = "" } = item;
  return (
    <div
      className="flex justify-between py-1 cursor-pointer hover:bg-gray-50"
      onClick={() => onNotificationClick(item, NotificationOnClickType.menu)}
    >
      <div className="flex-1 overflow-hidden">
        <div className="flex justify-between space-x-3">
          <div className="flex-1 min-w-0">
            <p className="block focus:outline-none">
              <span className="relative inset-0" aria-hidden="true" />
              <div className="flex items-center flex-1 space-x-2">
                <span className={notificationGetBadgeClass({ type })}>{type}</span>
                <p className="text-xs font-medium text-gray-900 truncate">{name}</p>
              </div>
            </p>
          </div>
          {/* <time dateTime="2021-01-27T16:35" className="flex-shrink-0 text-xs text-gray-500 whitespace-nowrap">
          11:15 AM
        </time> */}
        </div>
        <div className="w-full px-2 mt-1">
          <span className="text-xs text-gray-600 line-clamp-2">{parse(description ?? "")}</span>
          <div className="flex mt-1 space-x-3 text-xs text-gray-500">
            <small>Created: {formatDate(createDate, "DD/MM/YYYY HH:mm")}</small>
            {dueDate && <small>Due: {formatDate(dueDate, "DD/MM/YYYY HH:mm")}</small>}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mr-2 flex-0 ">
        <div className="flex justify-end h-7">
          <svg
            aria-hidden="true"
            className="w-3 h-2 w-[20px]"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M392 320c-13.25 0-24 10.75-24 24v112c0 4.406-3.594 8-8 8h-304c-4.406 0-8-3.594-8-8v-304c0-4.406 3.594-8 8-8h112C181.3 144 192 133.3 192 120S181.3 96 168 96h-112C25.13 96 0 121.1 0 152v304C0 486.9 25.13 512 56 512h304c30.88 0 56-25.12 56-56v-112C416 330.8 405.3 320 392 320zM488 0H320c-13.25 0-24 10.75-24 24S306.8 48 320 48h110.1L183 295c-9.375 9.375-9.375 24.56 0 33.94C187.7 333.7 193.8 336 200 336s12.28-2.344 16.97-7.031L464 81.94V192c0 13.25 10.75 24 24 24S512 205.3 512 192V24C512 10.75 501.3 0 488 0z"
            ></path>
          </svg>
        </div>
        <div
          className={`w-[8px] h-[8px] ${
            !isRead ? "bg-blue-700" : "bg-gray-200 border border-gray-300"
          } rounded`}
        ></div>
      </div>
    </div>
  );
};

export default NotificationMenuItem;
