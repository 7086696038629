import Logo from "@images/logo.jpeg";
import { useSelector } from "react-redux";
import { selectUserProfile } from "@state/selectors";

const AppFallback: React.FC = () => {
  const user = useSelector(selectUserProfile);
  const isAppLoaded = !!user;
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen space-y-4 bg-white">
      {!isAppLoaded && <img className="w-[200px] h-auto" src={Logo} alt="Workflow" />}
    </div>
  );
};

export default AppFallback;
