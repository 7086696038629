import { useMemo } from "react";
import useModal from "@utils/hooks/useModal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ITask, TASK_MAIN_ROUTES } from "@model/task";
import GetTasksService from "@services/tasks/get-tasks.service";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";
import { selectUserProfile } from "@state/selectors";
import { UserType } from "@model/contact";
import {
  taskModalStateSelector,
  useTaskModalStore
} from "@components/task-controller/store/task-modals.store";
import { shallow, taskModalsSelector, useTaskStore } from "@components/task-controller/store";
import { IProperty } from "@model/property";
import { IProject } from "@model/project";
import { IDeal } from "@model/deal";
import { toast } from "@views/components";

const useTaskModals = () => {
  const currentUser = useSelector(selectUserProfile);
  const { id: paramId } = useParams<{ id: string }>();
  const [openModal, closeModal, onConfirm, , modalState] = useModal();
  const { selectedTask, setSelectedTask, createTask, updateTask } = useTaskStore(
    taskModalsSelector,
    shallow
  );
  const { referTo, type } = useTaskModalStore(taskModalStateSelector, shallow);

  const changeRouteHistory = TASK_MAIN_ROUTES.some((route) =>
    window.location.pathname.startsWith(route)
  );

  const taskId = changeRouteHistory && paramId;
  const { pathname } = window.location;
  const currentPath = pathname.split("/")[1];

  const defaultObject = useMemo(() => {
    let newObject: ITask = { id: "new", type };
    if (referTo.refType === "property_id") {
      // main relation
      newObject.property_ids = [referTo.data?.id];
      newObject.properties = [referTo.data as unknown as IProperty];
      newObject.scheduled_at = referTo.data?.contract_expiry_date ?? undefined;
    } else if (referTo.refType === "project_id") {
      // main relation
      newObject.project_ids = [referTo.data.id];
      newObject.projects = [referTo.data as unknown as IProject];
    } else if (referTo.refType === "user_id") {
      // main relation
      newObject.user_id = referTo.data.id;
      newObject.user = referTo.data as unknown as UserType;
    } else if (referTo.refType === "deal_id") {
      // main relation
      newObject.deal_id = referTo.data.id;
      newObject.deal = referTo.data as unknown as IDeal;

      // associated to
      if (newObject.deal.user) {
        newObject.user_id = newObject.deal.user.id;
        newObject.user = newObject.deal.user as UserType;
      }
    }
    newObject.agent_id = currentUser?.id;
    newObject.agent = currentUser as UserType;

    return newObject;
  }, [currentUser, referTo.data, referTo.refType, type]);

  const handleCreateNewTask = async (item: ITask) => {
    try {
      await createTask(item);
      toast.success("Data has been created");
      closeModal();
    } catch (error) {
      toast.error("Please try again");
    }
  };

  const onUpdateTask = async (task: ITask) => {
    try {
      if (typeof task.id !== "number") return;
      await updateTask(task);
      toast.success("Data has been updated");
      if (modalState.key) onConfirm();
    } catch (error) {
      console.log("handleUpdate error", error);
      toast.error("Please try again");
    } finally {
      handleCloseUpdateModal();
    }
  };

  const handleCloseUpdateModal = () => {
    setSelectedTask(null);
    if (changeRouteHistory) window.history.pushState({}, "", `/${currentPath}`);
    closeModal();
  };

  useLegacyEffect(() => {
    const fetchTaskDetail = async () => {
      try {
        const { data: task } = await GetTasksService.show(taskId);
        setSelectedTask(task);
      } catch (error) {
        toast.error("Please try again");
        handleCloseUpdateModal();
      }
    };

    if (!taskId || selectedTask) return;
    fetchTaskDetail();
  }, [taskId]);

  useLegacyEffect(() => {
    if (!selectedTask) return;
    openModal(`show_task_${selectedTask.id}`);
    if (changeRouteHistory) window.history.pushState({}, "", `/${currentPath}/${selectedTask.id}`);
  }, [selectedTask]);

  return {
    type,
    selectedTask,
    defaultObject,
    handleCreateNewTask,
    onUpdateTask,
    handleCloseUpdateModal,
    onConfirm,
    closeModal
  };
};

export default useTaskModals;
