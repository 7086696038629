import { post, deleteItem, queryType } from "@utils/api";

class FcmTokenService {
  endpoint: string;
  queries: queryType;

  static async store(fcmToken: string): Promise<any> {
    let endpoint: string = `fcm-tokens`;
    const queries: queryType = {
      fcm_token: fcmToken
    };

    return await new FcmTokenService(endpoint, queries).post();
  }

  static async delete(fcmToken: string): Promise<any> {
    let endpoint: string = `fcm-tokens`;
    const queries: queryType = {
      fcm_token: fcmToken
    };
    return await new FcmTokenService(endpoint, queries).delete();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async post(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    return await post({ path, data: queries });
  }

  async delete(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    return await deleteItem({ path, queries });
  }
}

export default FcmTokenService;
