import { ITask } from "@model/task";
import CreateTaskService from "@services/tasks/create-task.service";
import DeleteTaskService from "@services/tasks/delete-task.service";
import UpdateTaskService from "@services/tasks/update-task.service";
import { create } from "zustand";
import { shallow } from "zustand/shallow";

export type TaskStore = {
  tasks: ITask[];
  selectedTask: ITask | null;
  inAction: boolean;

  setTasks: (tasks: ITask[]) => void;
  createTask: (task: ITask) => Promise<ITask>;
  updateTask: (task: ITask) => Promise<ITask>;
  deleteTask: (taskId: number | string) => void;
  setSelectedTask: (task: ITask | null) => void;
};

const useTaskStore = create<TaskStore>((set) => ({
  tasks: [],
  selectedTask: null,
  inAction: false,

  setTasks: (tasks) => set({ tasks }),
  createTask: async (task) => {
    set({ inAction: true });
    try {
      const newTask = await CreateTaskService.call(task);
      return newTask;
    } catch (error) {
      throw error;
    } finally {
      set({ inAction: false });
    }
  },
  updateTask: async (task) => {
    set({ inAction: true });
    try {
      const updatedTask = await UpdateTaskService.call(task.id, task);
      return updatedTask;
    } catch (error) {
      throw error;
    } finally {
      set({ inAction: false });
    }
  },
  deleteTask: async (taskId) => {
    set({ inAction: true });
    try {
      await DeleteTaskService.call(taskId);
    } catch (error) {
      throw error;
    } finally {
      set({ inAction: false });
    }
  },
  setSelectedTask: (task) => set({ selectedTask: task })
}));

export { shallow, useTaskStore };
