import { IArticleCategory } from "@model/article";
import { get, queryType } from "@utils/api";

class GetCategoriesDropdownService {
  endpoint: string = "article-categories";
  queries: queryType;

  static call(queries: queryType): Promise<any> {
    return new GetCategoriesDropdownService({
      ...queries,
      sort: "asc",
      sort_by: "name"
    }).getTags();
  }

  constructor(queries: queryType) {
    this.queries = queries;
  }

  async getTags(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data }: { data: IArticleCategory[] } = await get({ path, queries });

    if (!data.length) {
      return [];
    }

    return data.map((item) => ({
      label: item.name,
      value: item.id,
      locale: item.locale
    }));
  }
}

export default GetCategoriesDropdownService;
