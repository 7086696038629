import { LoadingIndicator } from "@components/index";
import TableLayout from "./components/table.layout";
import TablePagination from "./components/table.pagination";
import SidebarWrapper from "./components/table.sidebar";
import Toolbar from "./components/table.toolbar";
import { NoDataFound } from "./components/table.tools";
import { ITableProps, MinTableItem } from "./index";
import useTable from "./useTable";

export default function DataTable<T extends MinTableItem>(props: ITableProps<T>) {
  const [
    rowsSelected,
    setRowsSelected,
    columns,
    setColumns,
    sidebar,
    queries,
    setQueries,
    closeSidebar,
    initialFilters,
    handleApplyFilters,
    handleFilterSidebar,
    filterNotifyNumber,
    handleSettingSidebar,
    isShowBatchEditing,
    allRowsChecked,
    setAllRowsChecked,
    isFetching,
    items,
    total,
    layout,
    setLayout
  ] = useTable<T>(props);

  return (
    <>
      <div className="z-10 flex flex-col h-full">
        {/* header, action tools, filters, settings */}
        <Toolbar<T>
          data={props}
          layout={layout}
          quickFilter={props.quickFilter ? props.quickFilter(queries, setQueries) : undefined}
          setLayout={setLayout}
          rowsSelected={rowsSelected}
          filterNotifyNumber={filterNotifyNumber}
          handleFilterSidebar={handleFilterSidebar}
          handleSettingSidebar={handleSettingSidebar}
        />

        {/* main section */}
        <div className="relative flex flex-col h-full overflow-auto sm:rounded-lg">
          <TableLayout<T>
            layout={layout}
            data={props}
            columns={columns}
            rowsSelected={rowsSelected}
            queries={queries}
            setQueries={setQueries}
            isShowBatchEditing={isShowBatchEditing}
            allRowsChecked={allRowsChecked}
            setAllRowsChecked={setAllRowsChecked}
            isFetching={isFetching}
            items={items}
            setRowsSelected={setRowsSelected}
          />

          {queries && <TablePagination total={total} queries={queries} setQueries={setQueries} />}

          {/* show loading while fetching */}
          <LoadingIndicator show={!!isFetching} />

          {/* show message if no data */}
          {!isFetching && !items?.length && <NoDataFound />}
        </div>
      </div>

      <SidebarWrapper<T>
        data={props}
        columns={columns}
        setColumns={setColumns}
        queries={queries}
        setQueries={setQueries}
        sidebar={sidebar}
        closeSidebar={closeSidebar}
        initialFilters={initialFilters}
        handleApplyFilters={handleApplyFilters}
      />
    </>
  );
}
