import { useSelector, useDispatch } from "react-redux";
import { selectForm } from "@state/selectors";
import { ActionType } from "@state/actions/action.types";
import CustomButton from "@views/components/buttons/button.custom";

const FormButton = () => {
  const formState = useSelector(selectForm);
  const dispatch = useDispatch();
  const open = formState.diff > 0;

  return (
    <div className="relative">
      <div
        className={`absolute ${
          open ? "bottom-0" : "bottom-[-64px]"
        }  left-0 right-0 z-[100] flex items-center h-16 px-6 space-x-6 transition-all bg-white border-t`}
      >
        <CustomButton
          label="Save"
          variant="primary"
          className="w-[100px]"
          onClick={() => dispatch({ type: ActionType.ON_FORM_SUBMIT })}
        />
        <CustomButton
          label="Cancel"
          variant="secondary-outline"
          className="w-[100px]"
          onClick={() => dispatch({ type: ActionType.ON_FORM_RESET })}
        />

        <p className="text-sm">You've changed {formState.diff} items</p>
      </div>
    </div>
  );
};

export default FormButton;
