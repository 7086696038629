import AsyncSelect from "react-select/async";
import DealDropdownService from "@services/deals/get-deals-dropdown.service";
import debounce from "debounce-promise";
import LocationService from "@services/locations/location.service";
import getContactsService from "@services/contacts/get-contacts-dropdown.service";
import { getCustomSelectStyles, getOption, getUserOptionsWith } from "@utils/react-select.helpers";
import PropertyDropdownService from "@services/properties/property-dropdown.service";
import GetStageDropdownService from "@services/deals/get-stages-dropdown.service";
import DeveloperSearchDropdownService from "@services/developers/developer-search-dropdown.service";
import AgentService from "@services/properties/agent-dropdown.service";
import ListingOwnerService from "@services/properties/listing-owner-dropdown.service";
import ProjectsNameDropdownService from "@services/projects/get-projects-name-dropdown.service";
import LocationDropdownService from "@services/locations/get-locations-dropdown.service";
import { SearchOptionKey } from "./";
import GetTagsDropdownService from "@services/blogs/tags/get-tags-dropdown.service";
import GetCategoriesDropdownService from "@services/blogs/categories/get-categories-dropdown.service";
import GetTeamsDropdownService from "@services/teams/get-teams-dropdown.service";
import GetTeamUsersDropdownService from "@services/teams/get-team-users-dropdown.service";
import { useMemo } from "react";

const loadOptions = debounce(async (value: string, configs: any) => {
  switch (configs.key) {
    case "stage":
    case "stages":
      const stageId = configs?.id ? configs.id : "";
      return GetStageDropdownService.call(stageId, value);
    case "developer":
    case "developers/search":
      const developers = await DeveloperSearchDropdownService.call(value);

      return developers.map(({ id, name }: any) => ({
        label: name,
        value: id
      }));
    case "projects":
      return await ProjectsNameDropdownService.call(value, configs);
    case "search-user":
      const users = await getUserOptionsWith(value);
      if (configs.filterOptions !== undefined) {
        return configs.filterOptions(users, value);
      }
      return users;
    case "property":
      return await PropertyDropdownService.searchWith(value, (item) => {
        const prop = item;
        const label = `${item.reference} (${prop.type} for ${prop.action})`;
        return getOption(label, prop.id);
      });
    case "property-agent":
      return AgentService.call(configs.resource, value);
    case "listing-owner":
      return ListingOwnerService.call(value);
    case SearchOptionKey.internalUsers:
    case SearchOptionKey.agents:
    case SearchOptionKey.users:
      return await getContactsService.call(value, configs);
    case "region_1":
    case "region_2":
    case "region_3":
    case "locality":
      const { key, type, country_id, region_2, region_3 } = configs;
      let filters = {};
      // prepare queries
      if (type === "field" && key === "region_1") {
        filters = { ...configs.filters, region_1: value }; // configs.filters = {country_id: ''}
      } else if (type === "field" && key === "region_2") {
        filters = { ...configs.filters, region_2: value }; // configs.filters = {country_id: ''}
      } else if (type === "field" && key === "region_3") {
        filters = { ...configs.filters, region_3: value }; // configs.filters = {country_id: '', region_2: ''}
      } else if (type === "field" && key === "locality") {
        filters = { ...configs.filters, locality: value };
      } else if (key === "region_1") {
        filters = { country_id: country_id.textValue.value, region_1: value };
      } else if (key === "region_2") {
        filters = { country_id: country_id.textValue.value, region_2: value };
      } else if (key === "region_3") {
        filters = {
          country_id: country_id.textValue.value,
          region_2: region_2.textValue.value,
          region_3: value
        };
      } else if (key === "locality") {
        filters = {
          country_id: country_id.textValue.value,
          region_2: region_2.textValue.value,
          region_3: region_3.textValue.value,
          locality: value
        };
      }

      return await LocationService.call(JSON.stringify(filters), {
        keySearch: key,
        render: (item: any) => ({ value: item[key], label: item[key] })
      });
    case "deal":
      return await DealDropdownService.call(value);
    case "location-search":
      return await LocationDropdownService.call(value, configs);
    case "categories":
      const categoryFilters = configs?.filters || {};
      return GetCategoriesDropdownService.call({
        ...categoryFilters,
        search: value
      });
    case "tags":
      const tagFilters = configs?.filters || {};
      return GetTagsDropdownService.call({
        ...tagFilters,
        search: value
      });
    case "teams":
      const teamFilters = configs?.filters || {};
      return GetTeamsDropdownService.call({
        filters: JSON.stringify(teamFilters),
        search: value
      });
    case "team-users":
      const teamUserFilters = configs?.filters || {};
      return GetTeamUsersDropdownService.call({
        filters: JSON.stringify(teamUserFilters),
        search: value
      });
  }
}, 500);

interface SearchSelectProps {
  configs: any;
  styles?: any;
  defaultValue?: any;
  highlightSelected?: boolean;
  [key: string]: any;
}

export default function SearchSelect({
  configs,
  styles: customStyles,
  defaultValue,
  highlightSelected,
  ...args
}: SearchSelectProps) {
  const value = defaultValue || (args.value?.length !== 0 && args.value);
  const styles = useMemo(() => {
    return customStyles || getCustomSelectStyles(value, highlightSelected);
  }, [customStyles, value, highlightSelected]);

  return (
    <AsyncSelect
      {...args}
      // menuPortalTarget={document.body}
      // menuShouldBlockScroll
      formatOptionLabel={(option: any) => option.label}
      defaultValue={defaultValue}
      styles={styles}
      loadOptions={(value: string) => loadOptions(value, configs)}
    />
  );
}
