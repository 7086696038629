import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IFilterProps } from "../table.filter";
import moment from "moment";
import { classNames } from "@utils/helpers";

type dateType = Date | null;

export default function DatePickerFilter(props: IFilterProps) {
  const myDatepickerRef = useRef<HTMLInputElement>(null);
  const showMonthYearPicker = props.config.showMonthYearPicker || false;
  const dateFormat = props.config.dateFormat || "dd/MM/yyyy";
  const placeholderText = props.config.placeholderText || "DD/MM/YYYY";
  return (
    <div>
      <DatePicker
        key={props.id}
        autoComplete="off"
        className={classNames(
          props.value
            ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
            : "focus:border-gray-300",
          "block w-full px-3 border-gray-300 focus:ring-0 py-2 pl-3 pr-10 text-sm  rounded-md sm:text-xs h-[38px]"
        )}
        // className="block w-full h-[38px] border-gray-300 rounded-md focus:ring-0 focus:border-1 sm:text-xs"
        selected={props.value ? new Date(props.value) : null}
        onChange={(date: dateType) => {
          if (props.setValue)
            props.setValue({ name: props.id, value: moment(date).format("YYYY-MM-DD") || "" });

          props.setFilterState((prev: {}) => ({
            ...prev,
            [props.id]: moment(date).format("YYYY-MM-DD") || ""
          }));
        }}
        disabledKeyboardNavigation
        name={props.id}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        peekNextMonth
        // showMonthDropdown
        // showYearDropdown
        showMonthYearPicker={showMonthYearPicker}
        dropdownMode="select"
        customInput={<DatepickerInput ref={myDatepickerRef} />}
        // maxDate={maxDate}
        // isClearable={true}
        // withPortal
      />
    </div>
  );
}

const DatepickerInput = React.forwardRef<HTMLInputElement>((props: any, ref) => {
  return (
    <div className="relative ">
      <input ref={ref} type="text" {...props} />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="calendar-check"
          className={classNames(
            props.value ? "text-purple-600 " : "text-gray-400",
            "w-5 h-5 svg-inline--fa fa-calendar-check"
          )}
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M219.3 395.3C213.1 401.6 202.9 401.6 196.7 395.3L132.7 331.3C126.4 325.1 126.4 314.9 132.7 308.7C138.9 302.4 149.1 302.4 155.3 308.7L208 361.4L308.7 260.7C314.9 254.4 325.1 254.4 331.3 260.7C337.6 266.9 337.6 277.1 331.3 283.3L219.3 395.3zM128 64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0C120.8 0 128 7.164 128 16V64zM32 448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192H32V448zM32 128V160H416V128C416 110.3 401.7 96 384 96H64C46.33 96 32 110.3 32 128z"
          ></path>
        </svg>
      </div>
    </div>
  );
});
