import { uploadFiles } from "@utils/api";

interface IPayload {
  data: any;
  endPoint: string;
}

class UploadImageService {
  endpoint: string;
  data: any;

  static async call(payload: IPayload): Promise<any> {
    const service = new UploadImageService(payload.endPoint, payload.data);
    return await service.call();
  }

  constructor(endpoint: string, data: any) {
    this.endpoint = endpoint;
    this.data = data;
  }

  async call(): Promise<any> {
    const {
      data: { data }
    }: any = await uploadFiles({ path: this.endpoint, data: this.data });
    return data;
  }
}

export default UploadImageService;
