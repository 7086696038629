import { get, queryType } from "@utils/api";

type GetEmailTemplatePayload = {
  contact_id: number | null;
};

class GetEmailTemplateDetailService {
  endpoint: string;
  queries: queryType = {};

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  static async call(templateId: number, payload: GetEmailTemplatePayload) {
    const endpoint = `email-templates/${templateId}`;
    const queries = payload;

    return await new GetEmailTemplateDetailService(endpoint, queries).getTemplates();
  }

  async getTemplates() {
    const { data } = await get({
      path: this.endpoint,
      queries: this.queries
    });

    return data;
  }
}

export default GetEmailTemplateDetailService;
