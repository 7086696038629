import { get } from "@utils/api";
import type { queryType } from "@utils/api";
import { UserRole } from "@model/app";

class AgentService {
  endpoint: string;
  queries: queryType;

  static async call(type: string | null, value: string): Promise<any> {
    const queries: queryType = {};
    const isUser: boolean = type === "user";
    const isTeam: boolean = type === "team";
    let endpoint: string = "";

    if (isUser) {
      endpoint = "users";
      queries.filters = JSON.stringify({
        name: value,
        role: {
          or: [
            UserRole.superAdmin,
            UserRole.admin,
            UserRole.agent,
            UserRole.customerSuccess,
            UserRole.affiliate
          ]
        }
      });
    } else if (isTeam) {
      endpoint = "teams";
      queries.filters = JSON.stringify({
        name: value
      });
    }

    return await new AgentService(endpoint, queries).getAgents();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async getAgents(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data.map((item: any) => ({
      label: item.name,
      value: item.id
    }));
  }
}

export default AgentService;
