import { ITableProps, MinTableItem, TableLayoutType } from "..";
import { CreateButton } from "@components/index";
import ViewButtons from "./table.view-buttons";
import DownloadButton from "@components/buttons/button.download";

interface ITableActionMenu<T extends MinTableItem> {
  data: ITableProps<T>;
  layout: TableLayoutType;
  setLayout: (view: TableLayoutType) => void;
  rowsSelected: number[];
}

const TableActionMenu = <T extends MinTableItem>({
  data,
  layout,
  setLayout,
  rowsSelected
}: ITableActionMenu<T>) => {
  return (
    <div className="flex flex-wrap items-center flex-1 min-h-[30px] space-x-3 ">
      {data.tableName && <p className="text-sm font-semibold text-gray-600 ">{data.tableName}</p>}
      {/* hide for deal page */}

      {data.onCreate && <CreateButton label="Create new" handleClick={data.onCreate} />}
      {data.onCSVDownload && (
        <DownloadButton label="CSV Download" handleClick={data.onCSVDownload} />
      )}
      {data.renderCardTemplate && <ViewButtons layout={layout} setLayout={setLayout} />}
      {!!rowsSelected.length && (
        // ml 270 is custom space only for deal-table
        <div
          className={`flex ${
            data.endpoint === "deals" ? "ml-[270px] pt-[0.275rem] sm:pt-1 lg:pt-[0.225rem]" : "ml-0"
          } space-x-2`}
        >
          {data.batchEditing}
        </div>
      )}
    </div>
  );
};

export default TableActionMenu;
