import { classNames } from "@utils/helpers";

import { TableLayoutType } from "../index";

const ViewButtons = ({
  layout,
  setLayout
}: {
  layout: TableLayoutType;
  setLayout: (layout: TableLayoutType) => void;
}) => {
  return (
    <span className="relative z-0 inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={() => {
          setLayout(TableLayoutType.table);
        }}
        className={classNames(
          layout === "table"
            ? "text-white bg-purple-600 border border-purple-300"
            : "text-gray-700 bg-white  hover:bg-gray-50",
          "relative inline-flex items-center px-3 py-[6px] text-xs font-medium rounded-l-md  focus:z-10 focus:outline-none focus:ring-0 "
        )}
      >
        Table
      </button>
      <button
        type="button"
        onClick={() => {
          setLayout(TableLayoutType.card);
        }}
        className={classNames(
          layout === "card"
            ? "text-white bg-purple-600 border border-purple-300"
            : "text-gray-700 bg-white  hover:bg-gray-50",
          "relative inline-flex items-center px-3 py-[6px] -ml-px text-xs font-medium  rounded-r-md focus:z-10 focus:outline-none focus:ring-0 "
        )}
      >
        Cards
      </button>
    </span>
  );
};

export default ViewButtons;
