import { get, put, queryType } from "@utils/api";

class NotificationService {
  endpoint: string;
  queries: queryType;

  static async fetch(page: number, per_page: number = 5): Promise<any> {
    const queries: queryType = {
      sort_by: "id",
      sort: "desc",
      page,
      per_page
    };
    let endpoint: string = "notifications";

    return await new NotificationService(endpoint, queries).get();
  }

  static async update(refId: number): Promise<any> {
    let endpoint: string = `notifications/${refId}`;
    const queries: queryType = {};

    return await new NotificationService(endpoint, queries).put();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async get(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data, meta } = await get({ path, queries });

    return { data, meta };
  }

  async put(): Promise<any> {
    const path = this.endpoint;
    return await put({ path, data: {} });
  }
}

export default NotificationService;
