import { ITask } from "@model/task";

export const storageKey = "29-5-2022";

export const defaultQueries = { per_page: 5, page: 1 };

export const defaultColumns = [
  {
    key: "id",
    label: "",
    columnDisplay: true,
    visible: true,
    sortable: true,
    filters: { type: "number", value: null }
  },
  {
    key: "title",
    label: "Title",
    columnDisplay: true,
    visible: true,
    sortable: false,
    filters: { type: "text", value: null }
  },
  {
    key: "agent_id",
    label: "Assigned to",
    columnDisplay: true,
    visible: true,
    sortable: false,
    filters: {
      type: "asyncSelect",
      value: null,
      endPoint: "team-users"
    }
  },
  {
    key: "deal_id",
    label: "Deal",
    columnDisplay: true,
    visible: true,
    sortable: false,
    filters: {
      type: "asyncSelect",
      value: null,
      endPoint: "deal"
    }
  },
  {
    key: "user_id",
    label: "Contact",
    columnDisplay: true,
    visible: true,
    sortable: false,
    filters: {
      type: "asyncSelect",
      value: null,
      endPoint: "users"
    }
  },
  {
    key: "property_id",
    label: "Property",
    columnDisplay: true,
    visible: true,
    sortable: false,
    filters: {
      type: "asyncSelect",
      value: null,
      endPoint: "property"
    }
  },
  // {
  //   key: 'type', label: 'Type', columnDisplay: true, visible: true, sortable: true, filters: {
  //     type: 'selection', value: null,
  //     options: [
  //       { label: 'Task only', value: 'task' }
  //     ]
  //   }
  // },
  {
    key: "is_done",
    label: "Done",
    columnDisplay: true,
    visible: true,
    sortable: true,
    filters: {
      type: "selection",
      value: null,
      options: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" }
      ]
    }
  },
  {
    key: "scheduled_at",
    label: "Due date",
    columnDisplay: true,
    visible: true,
    sortable: true,
    filters: { type: "calendar", value: null }
  },

  {
    key: "created_by",
    label: "Creator",
    columnDisplay: true,
    visible: true,
    sortable: false,
    filters: {
      type: "asyncSelect",
      value: null,
      endPoint: "users"
    }
  },
  {
    key: "created_at",
    label: "create date",
    columnDisplay: true,
    visible: true,
    sortable: true,
    filters: { type: "calendar", value: null }
  }
];

export type TasksState = {
  init: boolean;
  isLoading: boolean;
  total: number;
  items: ITask[] | [];
};
