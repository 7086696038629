import { useDispatch, useSelector } from "react-redux";
import { selectNotificationMenu } from "@state/selectors";
import NotificationService from "@services/notification/notification.service";
import {
  NotificationAlertType,
  NotificationMenuItemType,
  NotificationOnClickType,
  rawNotificationType
} from "@model/notification";
import {
  setNotificationMenu,
  setTargetNotificationItemTobeRead
} from "@state/actions/action.notification";
import { useHistory } from "react-router-dom";
import { notificationCreateUrl } from "@components/notification-controller/helper";

export default function useNotification() {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationMenu = useSelector(selectNotificationMenu);

  // fetch Notification Menu Item
  const fetchNotificationMenu = async (isInit: boolean = false) => {
    const nextPage = isInit ? 1 : notificationMenu?.meta.nextPage;
    const newNotifications: rawNotificationType = await NotificationService.fetch(nextPage, 10);
    // isInit be to will replace all the notificationMenu data
    setNotificationMenu(dispatch, newNotifications, isInit);
  };

  const onNotificationClick = (item: any, type: NotificationOnClickType) => {
    let formattedItem = null;
    // prepare item data for each type
    if (type === NotificationOnClickType.menu) {
      const { id, url, isRead } = item as NotificationMenuItemType;
      formattedItem = { id, url, isRead };
    } else if (type === NotificationOnClickType.alert) {
      const {
        data: { id, type, ref_id, event }
      } = item as NotificationAlertType;
      const url = notificationCreateUrl(type, ref_id, event);
      formattedItem = { id, url, isRead: false };
    }

    // invalid notification type then return
    if (!formattedItem) return;

    updateAndRedirectNotification(formattedItem);
  };

  const updateAndRedirectNotification = (item: { id: number; url: string; isRead: boolean }) => {
    const { id, url, isRead } = item;
    if (!isRead) {
      // update notification item status and reduce alert number by 1
      setTargetNotificationItemTobeRead(dispatch, notificationMenu, id);
      // update status on DB
      NotificationService.update(id).catch((error) =>
        console.log("updateAndRedirectNotification error", error)
      );
    }
    // redirect
    history.push(url);
  };

  return [fetchNotificationMenu, onNotificationClick] as const;
}
