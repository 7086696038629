import React from "react";
import { ITask } from "@model/task";

const ShowMoreButton = ({
  items,
  total,
  showMore
}: {
  items: ITask[] | [];
  total: number;
  showMore: () => void;
}) => {
  return !!items.length && total > items.length ? (
    <div className="flex items-center justify-center py-3">
      <p
        className="text-xs text-blue-400 underline cursor-pointer hover:text-blue-600"
        onClick={showMore}
      >
        Show More
      </p>
    </div>
  ) : null;
};

export default ShowMoreButton;
