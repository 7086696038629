import { TrackingActivityModelKey } from "@components/activity-controller";
import useTaskTabs from "@pages/tasks/show/hooks/useTaskTabs";
import { TASK_TAB, TASK_TYPE } from "@model/task";
import TaskLists from "@pages/tasks/show/task.lists";
import { CreateButton } from "@views/components/buttons";
import useModal from "@utils/hooks/useModal";

export default function ActivityTasks({
  modelId,
  modelKey,
  type
}: {
  modelId: number;
  modelKey: TrackingActivityModelKey;
  type: TASK_TYPE;
}) {
  const [, , queries] = useTaskTabs(type, { [modelKey]: { is: [modelId] } });
  const [openModal] = useModal();

  return (
    <>
      <div className="flex justify-end mt-4 mb-2">
        <CreateButton handleClick={() => openModal(`create_new_${type}`)} />
      </div>
      <div className="border-b-2 border-gray-300 border-dashed">
        <TaskLists
          positionType="tab"
          type={type}
          queries={queries}
          label="Today"
          tab={TASK_TAB.TODAY}
        />
        <TaskLists
          positionType="tab"
          type={type}
          queries={queries}
          label="Up Coming"
          tab={TASK_TAB.UPCOMING}
        />
        <TaskLists
          positionType="tab"
          type={type}
          queries={queries}
          label="Overdue"
          tab={TASK_TAB.OVERDUE}
        />
        <TaskLists
          positionType="tab"
          type={type}
          queries={queries}
          label="Done"
          tab={TASK_TAB.DONE}
        />
      </div>
    </>
  );
}
