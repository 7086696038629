import { Action } from "../actions/action.index";
import { ActionType } from "../actions/action.types";
import { GlobalEmailType } from "@model/email";
import {
  PreferenceType,
  propertySizeUnit,
  propertyRentalUnit,
  CountryCode,
  AppInputOptions
} from "@model/app";

export enum formControlStatus {
  onChanges = "onChanges",
  complete = "complete",
  normal = "normal",
  submitted = "submitted",
  errors = "errors"
}

export type formControlType = {
  key: string | null;
  diff: number;
  status: formControlStatus;
};

export type modalType = {
  key: string | null;
  actionCallback?: any;
  options?: {
    title: string;
    subTitle: string;
    confirmationButtonText?: string;
    cancelButtonText?: string;
  };
};

export type ViewUserType = {
  label: string;
  value: number | null;
};

export type superAdminType = {
  is_super_admin: boolean;
  is_system_super_admin: boolean;
  view_user?: ViewUserType;
};

export interface IApp {
  formControl: formControlType;
  modal: modalType;
  preferences: PreferenceType;
  globalEmail: GlobalEmailType;
  configs: any;
  inputOptions: Partial<AppInputOptions>;
  superAdmin: superAdminType;
}

const initialState = {
  formControl: {
    key: null,
    diff: 0,
    status: formControlStatus.normal
  },
  modal: { key: null },
  globalEmail: { refer: null, data: null },
  preferences: {
    defaultCountry: CountryCode.TH,
    propertySizeUnit: propertySizeUnit.SQM,
    propertyRentalUnit: propertyRentalUnit.Monthly
  },
  configs: {},
  inputOptions: {},
  superAdmin: { is_super_admin: false, is_system_super_admin: false }
};

const reducer = (state: IApp = initialState, action: Action) => {
  switch (action.type) {
    // FORM CONTROL
    case ActionType.ON_FORM_CHANGE:
      return {
        ...state,
        formControl: {
          ...state.formControl,
          key: action.payload.key,
          status: formControlStatus.onChanges,
          diff: action.payload.diff
        }
      };
    case ActionType.ON_FORM_SUBMIT:
      return {
        ...state,
        formControl: { ...state.formControl, status: formControlStatus.submitted }
      };
    case ActionType.ON_FORM_ERRORS:
      return { ...state, formControl: { ...state.formControl, status: formControlStatus.errors } };
    case ActionType.ON_FORM_SUBMIT_COMPLETED:
    case ActionType.ON_FORM_RESET:
      return {
        ...state,
        formControl: { ...state.formControl, key: null, status: formControlStatus.normal, diff: 0 }
      };

    // MODAL
    case ActionType.TOGGLE_MODAL:
      return { ...state, modal: { ...action.payload } };
    // GLOBAL EMAIL
    case ActionType.TOGGLE_GLOBAL_EMAIL:
      return { ...state, globalEmail: { ...action.payload } };
    // PREFERENCE
    case ActionType.UPDATE_PREFERENCE:
      return { ...state, preferences: { ...state.preferences, ...action.payload } };
    case ActionType.UPDATE_INPUT_OPTIONS:
      return { ...state, inputOptions: { ...state.inputOptions, ...action.payload } };
    //SUPER ADMIN
    case ActionType.UPDATE_VIEW_USER:
      return { ...state, superAdmin: { ...state.superAdmin, view_user: action.payload } };
    case ActionType.UPDATE_IS_SUPER_ADMIN:
      return { ...state, superAdmin: { ...state.superAdmin, is_super_admin: action.payload } };
    case ActionType.UPDATE_IS_SYSTEM_SUPER_ADMIN:
      return {
        ...state,
        superAdmin: { ...state.superAdmin, is_system_super_admin: action.payload }
      };
    default:
      return state;
  }
};

export default reducer;
