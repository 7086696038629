import { useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";

import { selectAdmin, selectPreferences, selectViewUserId } from "@state/selectors";
import { get } from "@utils/api";
import { convertPropertySize, convertPropertyRentalPrice } from "@utils/helpers";
import { propertyRentalUnit, propertySizeUnit } from "@model/app";
import {
  MinTableItem,
  ITableProps,
  rowOptions,
  QueryConfigType,
  ColumnConfigType,
  getFilterNotifyNumber,
  TableLayoutType
} from "./index";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";

export default function useTable<T extends MinTableItem>(props: ITableProps<T>) {
  const initialQueries = props.defaultConfig.queries ?? { per_page: rowOptions[3], page: 1 };
  const initialColumns = props.defaultConfig.columns ?? [];
  const initialFilters = props.defaultConfig.filters ?? [];

  const [layout, setLayout] = useState(props.defaultConfig.layout || TableLayoutType.table);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [items, setItems] = useState<T[] | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [queries, setQueries] = useState<QueryConfigType>(initialQueries);
  const [columns, setColumns] = useState<ColumnConfigType[]>(initialColumns);
  const [sidebar, setSidebar] = useState({ open: false, key: "settings" });
  const [allRowsChecked, setAllRowsChecked] = useState<boolean>(false);
  const [rowsSelected, setRowsSelected] = useState<number[]>([]);
  const init = useRef(false);
  const isAdmin = useSelector(selectAdmin);
  const viewUserId = useSelector(selectViewUserId);

  const filterNotifyNumber = getFilterNotifyNumber(queries.filters);
  const isShowBatchEditing: boolean = isAdmin && (!!props.batchEditing || !!props.batchItems);

  const preferences = useSelector(selectPreferences);

  // convert the filter values based on the user preferences
  const convertFilterValue = (filtersStr: string): string => {
    // copy the object without the referent
    const filters = JSON.parse(filtersStr);
    Object.keys(filters).forEach((k) => {
      if (["rental_price", "previous_rental_price", "interior_size", "land_size"].includes(k)) {
        Object.keys(filters[k]).forEach((conditionKey) => {
          filters[k][conditionKey] = filters[k][conditionKey].map((c: number) => {
            // convert value through array e.g. is: [x], between: [x, y]
            if (["rental_price", "previous_rental_price"].includes(k)) {
              if (!c) return null;
              return convertPropertyRentalPrice(
                parseFloat(c.toString()),
                preferences.propertyRentalUnit,
                propertyRentalUnit.Monthly
              );
            } else if (["interior_size", "land_size"].includes(k)) {
              if (!c) return null;
              return convertPropertySize(
                parseFloat(c.toString()),
                preferences.propertySizeUnit,
                propertySizeUnit.SQM
              );
            }
          });
        });
      }
    });
    return JSON.stringify(filters);
  };

  const fetchData = async () => {
    try {
      setIsFetching(true);
      setAllRowsChecked(false);

      const path = props.endpoint;

      const res = await get({
        path,
        queries: {
          ...queries,
          filters: convertFilterValue(JSON.stringify({ ...queries.filters })),
          view_user_id: viewUserId
        }
      });
      const totalListings = res?.meta?.total ?? 0;

      setItems(res.data);
      setTotal(totalListings);
      setRowsSelected([]);

      init.current = true;
      props.onFetchSucceeded && props.onFetchSucceeded(totalListings);
    } catch (error) {
      let errorMessage = "Failed to do something exceptional";
      init.current = true;
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      console.log(errorMessage);
    } finally {
      setIsFetching(false);
    }
  };

  const handleApplyFilters = () => {
    closeSidebar();
    setAllRowsChecked(false);
    setRowsSelected([]);
    props.onApplyFilters && props.onApplyFilters();
  };

  const handleFilterSidebar = useCallback(() => {
    setSidebar({ open: true, key: "filters" });
  }, []);

  const handleSettingSidebar = useCallback(() => {
    setSidebar({ open: true, key: "settings" });
  }, []);

  useLegacyEffect(() => {
    // setColumns(props.defaultConfig.columns)
    // if (props.defaultConfig.queries) setQueries(props.defaultConfig.queries)
    (async () => await fetchData())();
  }, []);

  useLegacyEffect(() => {
    if (init.current) {
      (async () => {
        await fetchData();
        props.setStorage({ columns, queries, layout, view_user_id: viewUserId });
      })();
    }
  }, [queries, viewUserId]);

  useLegacyEffect(() => {
    // update local storage when data was changes`
    if (columns) props.setStorage({ columns, queries, layout, view_user_id: viewUserId });
  }, [columns]);

  useLegacyEffect(() => {
    props.setStorage({ columns, queries, layout, view_user_id: viewUserId });
  }, [layout]);

  const closeSidebar = () => {
    setSidebar((prev) => ({ ...prev, open: false }));
  };

  return [
    rowsSelected,
    setRowsSelected,
    columns,
    setColumns,
    sidebar,
    queries,
    setQueries,
    closeSidebar,
    initialFilters,
    handleApplyFilters,
    handleFilterSidebar,
    filterNotifyNumber,
    handleSettingSidebar,
    isShowBatchEditing,
    allRowsChecked,
    setAllRowsChecked,
    isFetching,
    items,
    total,
    layout,
    setLayout
  ] as const;
}
