import { ITask } from "@model/task";
import { post } from "@utils/api";

class CreateTaskService {
  endpoint: string;
  data: any;

  static async call(item: any): Promise<ITask> {
    const service = new CreateTaskService("tasks", item);
    return await service.call();
  }

  constructor(endpoint: string, data: any) {
    this.endpoint = endpoint;
    this.data = data;
  }

  async call(): Promise<any> {
    const path = this.endpoint;
    const {
      data: { data }
    }: any = await post({ path, data: this.data });
    return data;
  }
}

export default CreateTaskService;
