import moment from "moment";

import { ITask, TASK_TYPE } from "@model/task";
import { XMark } from "@components/icon";
import { ReactComponent as CalendarClockIcon } from "@icons/calendar-clock.svg";
import { useTaskStore } from "./store";
import { classNames, getTimeZoneMoment } from "@utils/helpers";

export type formType = {
  id: number | string;
  content: string;
};

interface ListViewProps {
  data: ITask;
  editable: boolean;
  handleChange: (item: ITask) => void;
  handleDelete: (id: number | string) => void;
}

export default function ItemView(props: ListViewProps) {
  const { data }: { data: ITask } = props;
  const key = `mask_as_done_${data.id}`;
  const setSelectedTask = useTaskStore((state) => state.setSelectedTask);

  return (
    <div key={data.id} className="flex items-center min-h-[50px] py-2 text-xs">
      <div className="flex justify-between flex-1 w-full p-2 px-2 bg-white rounded-md">
        <div className="flex-1 min-w-0 ">
          <div className="relative flex flex-col mb-1 text-xs sm:space-x-3 sm:items-center sm:flex-row">
            {data.title && (
              <p
                className={classNames(
                  "text-sm font-semibold text-gray-700 capitalize",
                  props.editable && "hover:underline cursor-pointer"
                )}
                onClick={() => props.editable && setSelectedTask(data)}
              >
                {data.title}
              </p>
            )}
            {data.type !== TASK_TYPE.NOTES && data?.agent?.id && (
              <div className="flex space-x-1 text-sm text-gray-500 capitalize">
                <p>Assigned to</p>
                <a
                  href={`/contacts/${data?.agent?.id || ""}`}
                  className="text-purple-500 cursor-pointer hover:underline"
                >
                  {data?.agent.name}
                </a>
              </div>
            )}

            {props.editable && (
              <XMark
                onClick={() => props.handleDelete(data.id)}
                className="absolute top-0 right-0 w-3 h-3 text-red-300 cursor-pointer hover:text-red-600 svg-inline--fa fa-xmark"
              />
            )}
          </div>
          <p
            className="w-11/12 mt-3 text-xs text-gray-500 whitespace-pre-line sm:mt-0 min-h-[12px]"
            dangerouslySetInnerHTML={{ __html: data?.description || "" }}
          ></p>

          {data.type !== TASK_TYPE.NOTES &&
            (data.deal ||
              // data.property ||
              data.user) && (
              <div className="flex flex-wrap mt-4 mb-2 sm:space-x-3">
                {data?.deal && (
                  <a
                    href={`/deals/${data?.deal?.id || ""}`}
                    className="mt-2 cursor-pointer space-x-1 mr-3 sm:mt-0 sm:mr-0 whitespace-nowrap inline-flex items-center  py-0.5 rounded text-xs text-gray-500"
                  >
                    <span className="text-gray-400">Deal:</span>
                    <span className="font-semibold underline ">{data?.deal.title}</span>
                  </a>
                )}
                {/*
                {
                  data?.property && (

                    <span onClick={() => history.push(`/properties/${data?.property?.id || ''}`)} className="mt-2 cursor-pointer space-x-1 mr-3 sm:mt-0 sm:mr-0 whitespace-nowrap inline-flex items-center  py-0.5 rounded text-xs text-gray-500">
                      <span className='text-gray-400'>Property:</span><span className='font-semibold underline '>{data?.property?.reference}</span>
                    </span>
                  )
                } */}
                {data?.user && (
                  <a
                    href={`/contacts/${data?.user?.id || ""}`}
                    className="mt-2 cursor-pointer space-x-1 mr-3 sm:mt-0 sm:mr-0 whitespace-nowrap inline-flex items-center  py-0.5 rounded text-xs text-gray-500"
                  >
                    <span className="text-gray-400">Contact:</span>
                    <span className="font-semibold underline ">{data?.user?.name}</span>
                  </a>
                )}
              </div>
            )}

          <div className="flex flex-col pt-2 mt-2 border-t border-gray-200 sm:items-center sm:justify-between sm:flex-row">
            <div className="flex justify-between sm:justify-start sm:items-center sm:space-x-5">
              {data.type !== TASK_TYPE.NOTES && (
                <>
                  <div className="flex items-center space-x-1">
                    {!!data?.add_to_calendar && (
                      <CalendarClockIcon width={13} className="text-blue-500 mt-[-2px]" />
                    )}
                    {data.scheduled_at && (
                      <p
                        className={data.is_overdue ? "text-red-500" : "text-gray-700"}
                        title={moment(data.scheduled_at).format("llll")}
                      >
                        Due date: {getTimeZoneMoment(data.scheduled_at).calendar()}
                      </p>
                    )}
                  </div>
                  {data?.is_editable && (
                    <div className="relative flex items-center">
                      <div className="flex items-center h-5">
                        <input
                          id={key}
                          name={key}
                          type="checkbox"
                          defaultChecked={!!data.is_done}
                          onChange={() => props.handleChange({ ...data, is_done: !data.is_done })}
                          className={`w-3 h-3 ${
                            data.is_done ? "text-green-500" : "text-gray-400"
                          } rounded ring-0 focus:ring-0`}
                        />
                      </div>
                      <div className="ml-1 ">
                        <label
                          htmlFor={key}
                          className={`${data.is_done ? "text-green-600" : "text-gray-700"}`}
                        >
                          {data.is_done ? "Done" : "Mark as done"}
                        </label>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="flex items-end justify-between text-gray-400 sm:flex-col">
              <p className="order-2 sm:order-1">Created by: {data.creator?.name || ""}</p>
              <div className="flex flex-row items-center order-1 space-x-3 sm:order-2">
                <p className="" title={moment(data.updated_at).format("llll")}>
                  Updated {moment(data.updated_at).fromNow()}
                </p>
                {props.editable && (
                  <svg
                    onClick={() => setSelectedTask(props.data)}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="pen-to-square"
                    className="w-3 h-3 text-gray-400 cursor-pointer flex-0 hover:text-gray-600 svg-inline--fa fa-pen-to-square"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M495.6 49.23l-32.82-32.82C451.8 5.471 437.5 0 423.1 0c-14.33 0-28.66 5.469-39.6 16.41L167.5 232.5C159.1 240 154.8 249.5 152.4 259.8L128.3 367.2C126.5 376.1 133.4 384 141.1 384c.916 0 1.852-.0918 2.797-.2813c0 0 74.03-15.71 107.4-23.56c10.1-2.377 19.13-7.459 26.46-14.79l217-217C517.5 106.5 517.4 71.1 495.6 49.23zM461.7 94.4L244.7 311.4C243.6 312.5 242.5 313.1 241.2 313.4c-13.7 3.227-34.65 7.857-54.3 12.14l12.41-55.2C199.6 268.9 200.3 267.5 201.4 266.5l216.1-216.1C419.4 48.41 421.6 48 423.1 48s3.715 .4062 5.65 2.342l32.82 32.83C464.8 86.34 464.8 91.27 461.7 94.4zM424 288c-13.25 0-24 10.75-24 24v128c0 13.23-10.78 24-24 24h-304c-13.22 0-24-10.77-24-24v-304c0-13.23 10.78-24 24-24h144c13.25 0 24-10.75 24-24S229.3 64 216 64L71.1 63.99C32.31 63.99 0 96.29 0 135.1v304C0 479.7 32.31 512 71.1 512h303.1c39.69 0 71.1-32.3 71.1-72L448 312C448 298.8 437.3 288 424 288z"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
