import { CheckCircleIcon } from "@heroicons/react/solid";
import { ReactComponent as CloneIcon } from "@icons/clone-light.svg";
import { copyToClipboard } from "@utils/helpers";
import { useState } from "react";

const TemplateVariableListing = ({ variables }: { variables: string[] }) => {
  return (
    <ul className="divide-y divide-gray-200">
      {variables.map((variable, key) => {
        return (
          <li className="p-4" key={`variable-${key}`}>
            <TemplateVariableListItem name={variable} />
          </li>
        );
      })}
    </ul>
  );
};

const TemplateVariableListItem = ({ name }: { name: string }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleClick = () => {
    // clipboard copy item name
    const textToCopy = `{{${name}}}`;
    copyToClipboard(textToCopy, () => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500);
    });
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="flex-1 min-w-0">
        <p
          onClick={handleClick}
          className="text-sm font-medium text-gray-900 truncate cursor-pointer"
        >
          {name}
        </p>
      </div>
      {isCopied ? (
        <CheckCircleIcon className="w-5 h-5 text-green-500" />
      ) : (
        <CloneIcon onClick={handleClick} className="w-4 h-4 cursor-pointer hover:w-5 hover:h-5" />
      )}
    </div>
  );
};

export default TemplateVariableListing;
