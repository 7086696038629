import classNames from "classnames";
import moment from "moment/moment";
import { UserType } from "@model/contact";
import { TrackingActivityActionType } from "@components/activity-controller";
import { AudioPlayer } from "@components/audio-player";

export default function TwilioRecordCard({
  record,
  data,
  user,
  date,
  action
}: {
  record: any;
  data: any;
  date: Date;
  user?: UserType;
  action?: string;
}) {
  const { caller_name, receiver_name } = data || {
    caller_name: "Caller",
    receiver_name: "Receiver"
  };
  const title =
    action === TrackingActivityActionType.call_out
      ? `${caller_name} call ${receiver_name}`
      : `${receiver_name} receive call from ${caller_name}`;
  return (
    <div
      className={classNames(
        "block px-2 py-2 bg-white shadow sm:rounded-md sm:pr-2 sm:pl-3 h-auto relative"
      )}
    >
      <p className="truncate">{title}</p>
      {record && (
        <div className="flex flex-col mt-1 max-w-2xl">
          <AudioPlayer src={record.url}></AudioPlayer>
        </div>
      )}
      <div className="flex items-end justify-between mt-2 text-xs text-gray-400 border-t border-gray-200 sm:flex-col">
        <p className="order-2 mt-2 sm:order-1">Created by: {user?.name || "-"}</p>
        <div className="flex flex-row items-center order-1 space-x-3 sm:order-2">
          <p>Updated {moment(date).fromNow()}</p>
        </div>
      </div>
    </div>
  );
}
