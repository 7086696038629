import {
  TrackingActivityModelKey,
  TrackingActivityModelType
} from "@components/activity-controller";
import { ActivityHistory } from "@components/activity-controller/components/index";

export default function ActivityBoard({
  modelId,
  modelType,
  modelKey
}: {
  modelId: number;
  modelType: TrackingActivityModelType;
  modelKey: TrackingActivityModelKey;
}) {
  return (
    <div>
      <ActivityHistory modelId={modelId} modelType={modelType} modelKey={modelKey} />
    </div>
  );
}
