import { formatIntlWithDialCode, isIntlPhone } from "./helpers";
import ContactService from "@services/contacts/contact.service";
import { CountryCode } from "@model/app";

export type SearchOption = {
  label: string;
  value: string;
  searchValue: string | null;
  disabled: boolean;
};

export interface UserSearchOption extends SearchOption {
  name: string;
  email: string;
  phone: string;
  role: string;
}

export const getDropdownOptions = (options: string[]) => {
  return options.map((option: string) => getOption(option, option));
};

export const getOption = (label: string, value: any, searchValue: string | null = null) => {
  return { label, value, searchValue };
};

export const getDisableOption = (label: string, value: any, searchValue: string | null = null) => {
  return { label, value, searchValue, disabled: true };
};

export const getUserOptionsWith = (query: string) => {
  return ContactService.searchUserWith(query, (item) => {
    const label = `${item.email ? item.email : item.phone} (${item.name})`;
    return {
      ...getOption(label, parseInt(item.id)),
      name: item.name,
      email: item.email,
      phone: item.phone,
      role: item.role,
      tags: item.tags
    };
  });
};

export const getUserOptionsWithEmail = (email: string) => {
  return ContactService.getUserWith({ email }, (item) => {
    return {
      ...getOption(item.email, parseInt(item.id)),
      name: item.name,
      email: item.email,
      phone: item.phone,
      role: item.role
    };
  });
};

export const getUserOptionsWithPhone = (phone: string, dialCode: string) => {
  if (!isIntlPhone(phone)) {
    phone = formatIntlWithDialCode(phone, dialCode);
  }

  return ContactService.getUserWith({ phone }, (item) => {
    const label = `${item.phone} (${item.name})`;
    return {
      ...getOption(label, parseInt(item.id)),
      name: item.name,
      email: item.email,
      phone: item.phone,
      role: item.role
    };
  });
};

export const initialDialCode = () => ({
  ...getOption("th", "+66"),
  isoCode: CountryCode.TH
});

export const getCustomSelectStyles = (value: any, hightLightOn?: boolean, customStyle?: any) => {
  const color = !value ? "#6b7280" : hightLightOn ? "#9561e2" : "#333333";
  const borderColor = hightLightOn && !!value ? "#9561e2" : "#d1d5db";

  return {
    control: (base: any) => ({
      ...base,
      backgroundColor: "white",
      maxHeight: "38px",
      overflow: "auto",
      boxShadow: 0,
      borderColor,
      color: "#ff7272",
      "&:hover": {
        borderColor
      },
      ...(customStyle || {})
    }),
    singleValue: (base: any) => ({
      ...base,
      color
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    placeholder: (base: any) => ({
      ...base,
      color: "#6b7280",
      fontSize: "13px"
    })
  };
};
