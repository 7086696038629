import { DependencyList, useEffect, useRef } from "react";

const isDevelopmentRun = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const useLegacyEffect = (cb: () => void, deps?: DependencyList | undefined) => {
  const isMountedRef = useRef(!isDevelopmentRun);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return undefined;
    }

    return cb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useLegacyEffect;

// reference
// https://medium.com/@arm.ninoyan/fixed-react-18-useeffect-runs-twice-8480f0bd837f
