import { ReactNode } from "react";
import LayoutDefaultHeader from "./layout.default.header";
import LayoutDefaultSidebar from "./layout.default.sidebar";
import {
  FormButton,
  GlobalEmail,
  TaskModals,
  GooglePermissionModal
} from "@global-components/index";
import { NotificationAlert } from "@components/notification-controller";
import { createPortal } from "react-dom";

const GlobalComponents = () => {
  return (
    <>
      {createPortal(<FormButton />, document.body)}
      <GlobalEmail />
      <TaskModals />
      <GooglePermissionModal />
    </>
  );
};

const LayoutDefault = ({ children }: { children: ReactNode }) => {
  return (
    <div className="relative flex flex-col h-full">
      <NotificationAlert />
      {/* Top nav*/}
      <LayoutDefaultHeader />
      {/* Bottom section */}
      <div className="flex flex-1 min-h-0 overflow-hidden">
        {/* Narrow sidebar*/}
        <LayoutDefaultSidebar />

        <main className="relative z-10 flex-1 w-full h-full min-w-0 p-2 overflow-x-hidden overflow-y-auto border-t border-gray-200 sm:px-4 ">
          {children}
        </main>
      </div>
      <GlobalComponents />
      {/* <ConfirmationForm /> */}
    </div>
  );
};

export default LayoutDefault;
