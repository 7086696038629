import { useState, useEffect } from "react";

type ReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>, any];

export const useLocalStorage = <T>(
  prefixKey: string,
  key: string,
  initialValue?: T
): ReturnType<T> => {
  let storageKey = [prefixKey, key].filter((i) => !!i).join("_");

  const [state, setState] = useState<T>(() => {
    // if (!initialValue) return;
    try {
      // if (process.env.NODE_ENV === 'development') return initialValue

      const value = localStorage.getItem(storageKey);
      if (value) return JSON.parse(value);

      // clear old value
      if (prefixKey && Object.keys(localStorage).length) {
        Object.keys(localStorage).forEach((existKey) => {
          if (existKey.startsWith(prefixKey)) {
            localStorage.removeItem(existKey);
          }
        });
      }
      return initialValue;
    } catch (err) {
      console.log("err", err);
      return initialValue;
    }
  });

  useEffect(() => {
    if (state) {
      try {
        // console.log('state changed', state);
        localStorage.setItem(storageKey, JSON.stringify(state));
      } catch (err) {
        console.log(err);
      }
    }
  }, [state, storageKey]);

  return [state, setState, { storageKey }];
};
