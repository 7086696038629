import { get, queryType } from "@utils/api";
import { renderCallback } from "models/model.app";

class PropertyService {
  endpoint: string;
  queries: queryType;

  static async searchWith(id: string, renderCallback: renderCallback) {
    const endpoint = "properties";
    const queries: queryType = {};

    queries.page = 1;
    queries.per_page = 15;
    if (id.includes("LAZ")) {
      queries.filters = JSON.stringify({
        reference: id
      });
    } else {
      queries.filters = JSON.stringify({
        id
      });
    }

    return await new PropertyService(endpoint, queries).get(renderCallback);
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async get(renderCallback: renderCallback): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data.map(renderCallback);
  }
}

export default PropertyService;
