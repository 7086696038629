import { get } from "@utils/api";
import type { queryType } from "@utils/api";

class DeveloperSearchDropdownService {
  endpoint: string;
  queries: queryType;

  static async call(value: string): Promise<any> {
    const queries: queryType = { q: value };
    const service = new DeveloperSearchDropdownService("developers/search", queries);
    return await service.search();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async search(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) {
      return [];
    }

    return data;
  }
}

export default DeveloperSearchDropdownService;
