import React from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import { IFilterProps } from "../table.filter";
interface Options {
  label: string;
  value: string | number;
}

// interface IProps {
//   id: string
//   setFilterState: (prev: {}) => void
//   value: string | number
// }

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];
export default function AutocompleteFilter(props: IFilterProps) {
  const handleChange = (
    newValue: OnChangeValue<Options, false>,
    actionMeta: ActionMeta<Options>
  ) => {
    console.group("Value Changed");
    props.setFilterState((prev: {}) => ({ ...prev, [props.id]: newValue?.value || "" }));
    // console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  // const handleInputChange = (inputValue: any, actionMeta: any) => {
  //   console.group('Input Changed');
  //   console.log(inputValue);
  //   console.log(`action: ${actionMeta.action}`);
  //   console.groupEnd();
  // };
  const defaultValue = props.value ? options.filter((o) => o.value === props.value) : null;

  return (
    <CreatableSelect
      key={props.id}
      isClearable
      onChange={handleChange}
      value={defaultValue}
      // onInputChange={handleInputChange}
      options={options}
    />
  );
}
