import { NotifyBar } from "./table.tools";
import { classNames } from "@utils/helpers";
import { ITableProps, MinTableItem, TableLayoutType } from "../index";
import TableActionMenu from "./table.action-menu";

interface IToolbar<T extends MinTableItem> {
  data: ITableProps<T>;
  rowsSelected: number[];
  handleFilterSidebar: () => void;
  filterNotifyNumber: any;
  handleSettingSidebar: () => void;
  layout: TableLayoutType;
  setLayout: (view: TableLayoutType) => void;
  quickFilter?: JSX.Element;
}
const Toolbar = <T extends MinTableItem>({
  data,
  rowsSelected,
  handleFilterSidebar,
  filterNotifyNumber,
  handleSettingSidebar,
  layout,
  setLayout,
  quickFilter
}: IToolbar<T>) => {
  return (
    <div
      className={classNames(
        "mt-2 mb-4",
        quickFilter ? "space-y-4" : "flex items-center justify-between"
      )}
    >
      {/* Action Menu */}
      <TableActionMenu<T>
        data={data}
        layout={layout}
        setLayout={setLayout}
        rowsSelected={rowsSelected}
      />

      {/* Filters */}
      <div
        className={classNames(
          "flex items-center xl:items-baseline",
          quickFilter ? "justify-between" : "justify-end"
        )}
      >
        {/* Quick filters */}
        {quickFilter}

        {/* Default Filters */}
        <div className="flex justify-end gap-x-4">
          <NotifyBar
            type="filters"
            label="Filters"
            setOpen={handleFilterSidebar}
            notifyNumber={filterNotifyNumber}
          />
          {data.tabSettings && (
            <NotifyBar
              notifyNumber={0}
              label="Edit Columns"
              type="settings"
              setOpen={handleSettingSidebar}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
