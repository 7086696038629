import { SearchSelect } from "@components/forms/search-select";
import { IFilterProps } from "../table.filter";
import { getCustomSelectStyles } from "@utils/react-select.helpers";

export default function MultipleAsyncAutoComplete(props: IFilterProps) {
  const defaultValue = props?.value?.textValue ? props?.value?.textValue : null;

  return (
    <SearchSelect
      configs={{ ...props?.config, id: props.id, key: props?.config.endPoint, includedItem: false }}
      key={props.id}
      className="w-full text-[13px]"
      styles={getCustomSelectStyles(props.value, true)}
      isClearable={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      isMulti={true}
      value={defaultValue}
      noOptionsMessage={() => "Start typing"}
      onChange={(e: any) => {
        let value: any = null;
        if (Array.isArray(e) && e.length) value = { or: e.map((opt) => opt.value), textValue: e };
        if (props.config.relationship) value.relationship = props.config.relationship;
        props.setFilterState((prev: {}) => ({ ...prev, [props.id]: value }));
      }}
    />
  );
}
