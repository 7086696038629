import { UserType } from "@model/contact";
import { getAppResource } from "@state/actions/action.creators";
import { selectUserProfile, selectViewUserId } from "@state/selectors";
import { getDefaultRoute } from "@utils/helpers";
import LayoutWrapper from "@views/layouts";
import { lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { route, routes } from "./routes";
import { RoutePath } from "@model/app";

const SignInView = lazy(() => import("../views/pages/auth/auth.signin"));
const DevSignInView = lazy(() => import("../views/pages/auth/devAuth.signin"));

export default function App() {
  const userData = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const viewUserId = useSelector(selectViewUserId);

  // check user data at first time page loaded
  // then init app and user data to application state
  getAppResource(dispatch, viewUserId).read();

  const DefaultRoute = getDefaultRoute(userData);
  const isDevelopment = process.env.NODE_ENV === "development";

  return (
    <Router>
      <Switch>
        <SignInRoute
          path={RoutePath.signIn}
          authUser={userData}
          redirectRouteIfAuth={DefaultRoute}
        />

        {/* {isDevelopment && isEnableAutoLogin() && (
          <DevSignInRoute
            path={RoutePath.devSignIn}
            authUser={userData}
            redirectRouteIfAuth={DefaultRoute}
          />
        )} */}

        {/* Available and Private routes */}
        {routes.map((route) => {
          return PrivateRoute(route, route.path, userData, DefaultRoute);
        })}

        {/* NotFound Error page */}
        <Route path="*" render={() => <Redirect to={DefaultRoute} />} />
      </Switch>
    </Router>
  );
}

const SignInRoute = ({
  path,
  authUser,
  redirectRouteIfAuth
}: {
  path: string;
  authUser: UserType | null;
  redirectRouteIfAuth: string;
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const targetPath = queryParams.get("target");

  return (
    <Route
      path={path}
      exact
      render={() => {
        return authUser?.id ? (
          <Redirect to={targetPath || redirectRouteIfAuth} />
        ) : (
          <LayoutWrapper.Blank>
            <SignInView />
          </LayoutWrapper.Blank>
        );
      }}
    />
  );
};

const DevSignInRoute = ({
  path,
  authUser,
  redirectRouteIfAuth
}: {
  path: string;
  authUser: UserType | null;
  redirectRouteIfAuth: string;
}) => {
  return (
    <Route
      path={path}
      render={() => {
        return authUser?.id ? (
          <Redirect to={redirectRouteIfAuth} />
        ) : (
          <LayoutWrapper.Blank>
            <DevSignInView />
          </LayoutWrapper.Blank>
        );
      }}
    />
  );
};

const PrivateRoute = (
  route: route,
  path: string,
  authUser: UserType | null,
  fallbackRoute: string
) => {
  // Checking Auth: If user is not logged in Redirect user to Signin
  if (!authUser?.id) {
    return (
      <Route
        key={path}
        render={(props) => (
          <Redirect
            to={{
              pathname: RoutePath.signIn,
              search: props.location.pathname !== "/" ? `?target=${props.location.pathname}` : ""
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      key={route.path}
      path={path}
      exact
      render={(props) => {
        // Checking Permission
        if (
          !route.permission.includes("*") &&
          !route.permission.includes(authUser?.role || "") &&
          !route.extraRules?.includes(authUser?.user_rules.join(","))
        ) {
          return <Redirect to={fallbackRoute} />;
        }
        const MainWrapper = LayoutWrapper.default;

        return (
          <MainWrapper>
            <route.component {...props} />
          </MainWrapper>
        );
      }}
    />
  );
};
