import { useEffect } from "react";
import { formatDate } from "@utils/helpers";
import { notificationGetBadgeClass } from "@components/notification-controller/helper";
import { NotificationAlertType, NotificationOnClickType } from "@model/notification";
import useNotification from "@components/notification-controller/useNotification";
import parse from "html-react-parser";

interface IProps extends NotificationAlertType {
  onExpire: (id: number) => void;
}

export default function NotificationAlertItem(props: IProps) {
  const { due_date: dueDate, created_date: createDate, type } = props.data;
  const [, onNotificationClick] = useNotification();

  // close alert and redirect to target url
  const onClickAlert = () => {
    if (props.id) {
      props.onExpire(props.id);
      // create notification and whitelist path
      onNotificationClick(props, NotificationOnClickType.alert);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      props.onExpire(props.id);
      // close this component in 8 sec
    }, 8000);
    // console.log('run', props.id)
  }, []);
  return (
    <div
      className="flex w-full max-w-md bg-white divide-x divide-gray-200 rounded-lg shadow-lg cursor-pointer ring-1 ring-black ring-opacity-5"
      onClick={onClickAlert}
    >
      <div className="flex items-center flex-1 w-0 p-4">
        <div className="w-full">
          <div className="flex justify-between space-x-3">
            <div className="flex-1 min-w-0">
              <p className="block focus:outline-none">
                <span className="absolute" aria-hidden="true" />
                <div className="flex items-center space-x-2">
                  {/* create Badge Class and change color on type */}
                  <span className={notificationGetBadgeClass({ type, uppercase: true })}>
                    {type}
                  </span>
                  <p className="text-xs font-medium text-gray-900 truncate">{props.title}</p>
                </div>
              </p>
            </div>
          </div>

          <div className="w-full mt-1">
            <p className="text-xs text-gray-600 line-clamp-2">{parse(props.body ?? "")}</p>
            <div className="flex mt-1 space-x-3 text-xs text-gray-500">
              <small>Created: {formatDate(createDate)}</small>
              {dueDate && <small>Due: {formatDate(dueDate)}</small>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
