import toast from "@components/toast";
import { EmailStatus, GlobalEmailReferType, IMessage } from "@model/email";
import { IFileRecord } from "@model/file";
import DeleteDraftEmailService from "@services/emails/draft/delete-draft-email.service";
import GetEmailThreadService, { filters, queries } from "@services/emails/get-email-thread-service";
import GetDealAttachmentFilesService from "@services/deals/get-deals-attachment-files";
import SendEmailService from "@services/emails/send-email-service";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";
import useModal from "@utils/hooks/useModal";
import { useEffect, useState } from "react";

export type StateType = {
  queries: queries;
  total: number;
  items: IMessage[] | null;
};

export default function useEmail(
  refer: GlobalEmailReferType | null,
  filters?: filters,
  cb?: () => void
) {
  const [state, setState] = useState<StateType>({
    items: null,
    queries: { offset: 0, filters: filters || {} },
    total: 0
  });
  const [isLoading, setLoading] = useState(false);
  const [isNewPending, setNewPending] = useState(false);
  const [itemSelected, setItem] = useState<IMessage | null>(null);
  const [openModal, closeModal, , , modalState] = useModal();
  const [attachments, setAttachments] = useState<IFileRecord[]>([]);

  useLegacyEffect(() => {
    // calling from detail page (email tab) will need to provide the filters
    // otherwise this event is from the Global which no need fetching data
    if (filters) getMessages();

    // show the deal files if the refer is deal
    if (refer?.ref_type === "deal") {
      getAttachments();
    }
  }, []);

  useEffect(() => {
    if (itemSelected) {
      // console.log(itemSelected);
      openModal("open_email_form");
    }
  }, [itemSelected]);

  const getMessages = async () => {
    try {
      const { data, meta } = await GetEmailThreadService.call({
        ...state.queries
      });

      // get messages from threads
      // const messages = data.reduce((acc: IMessage[], thread: IMessage) => {
      //   return [...acc, ...thread.messages]
      // }, []);
      // console.log(messages)
      setState((prev: StateType) => {
        const newItems = prev.items ? [...prev.items, ...data] : data;

        return {
          ...prev,
          items: newItems,
          total: meta.total,
          queries: {
            ...prev.queries,
            // page: meta.current_page + 1,
            offset: newItems.length
          }
        };
      });
    } catch (error) {
      console.log("email error", error);
    }
  };

  const loadMore = () => getMessages();

  const getAttachments = async () => {
    if (!refer?.ref_id) return;

    try {
      setLoading(true);
      const { data } = await GetDealAttachmentFilesService.call(refer?.ref_id);
      if (data) setAttachments(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setItem(null);
    if (modalState.key) closeModal();
  };

  const onSubmit = async (message: IMessage) => {
    try {
      if (!refer) throw new Error("Message Refer not found");

      setNewPending(true);
      handleCloseModal();

      const payload = { ...message, ...refer, id: message.id || undefined };
      const newMessage: IMessage = await SendEmailService.call(payload);

      const isEditingDraft = payload.id && payload.status === EmailStatus.draft;
      if (isEditingDraft) {
        // if only editing the draft email, update state
        setState((prev: StateType) => {
          return {
            ...prev,
            items: prev.items
              ? prev.items.map((item) => {
                  if (item.id === payload.id) {
                    return { ...item, ...newMessage };
                  }
                  return item;
                })
              : [newMessage]
          };
        });
      } else {
        // remove the payload item and put the new one on top
        setState((prev: StateType) => {
          return {
            ...prev,
            items: prev.items
              ? ([newMessage, ...prev.items.filter((item) => item.id !== payload.id)] as IMessage[])
              : [newMessage]
          };
        });
      }
      if (cb) await cb();
      setNewPending(false);

      if (message.status === EmailStatus.draft) {
        toast.success("Email saved as draft");
      } else {
        toast.success("Email has been sent");
      }
    } catch (error) {
      console.log("err", error);
      toast.error("Please try again");
    }
  };

  const onDiscard = (id: number) => {
    try {
      DeleteDraftEmailService.call(id);

      setState((prev: StateType) => ({
        ...prev,
        items: prev.items ? prev.items.filter((i) => i.id !== id) : prev.items
      }));

      toast.success("Discard draft email.");
    } catch (e) {
      console.error(e);
      toast.error("Please try again.");
    }
  };

  return [
    state,
    setItem,
    isNewPending,
    itemSelected,
    loadMore,
    onSubmit,
    onDiscard,
    handleCloseModal,
    attachments,
    isLoading
  ] as const;
}
