import { IStage } from "@model/stage";

export interface IPipeline {
  id: number;
  name: string;
  stages: IStage[];
}

export interface DealMetric {
  stage_id: number;
  stage_name: string;
  currencies: CurrencyDetail[];
}

export interface CurrencyDetail {
  currency_code: string;
  total_deals_value: number;
  total_deals_commission: number;
}

export enum PipelineType {
  NONE = 0,
  LEADS = 1,
  BUYER = 2,
  COMMISSION = 3,
  SELLER = 4,
  VIEWINGS = 5,
  DEVELOPER = 6,
  REFERRED_LEADS = 7,
  DUBAI_INSTRUCTION = 8
}
