import { UserType } from "@model/contact";

export { default as useTrackingActivity } from "./useTrackingActivity";
export { default as ActivityCard } from "./components/activity.card";
export { default as ActivityBoard } from "./components/activity.board";

export interface ITrackingActivity {
  id: number;
  model_id: number;
  model_type: TrackingActivityModelType;
  ref_id?: number;
  ref_type?: TrackingActivityModelType;
  action?: TrackingActivityActionType;
  data?: any;
  ref?: any;
  created_at: Date;
  updated_at: Date;
  user?: UserType;
  user_id?: number;
}

export interface IUpdateFieldData {
  field: string;
  old_value: string;
  new_value: string;
}

export type trackingActivityMapDataType = { key: string; key_id: string };

export enum TrackingActivityModelType {
  deal = "deal",
  task = "task",
  property = "property",
  project = "project",
  message = "message",
  user = "user",
  twilio_record = "twilio_record"
}

export enum TrackingActivityModelKey {
  deal_id = "deal_id",
  property_id = "property_id",
  project_id = "project_id",
  user_id = "user_id"
}

export enum TrackingActivityActionType {
  delete = "delete",
  update = "update",
  create = "create",
  call_out = "call_out",
  call_in = "call_in"
}
