import {
  ITrackingActivity,
  TrackingActivityModelType
} from "@components/activity-controller/index";
import TrackingActivityService from "@services/tracking-activity/tracking-activity.service";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";
import { useState } from "react";

export default function useTrackingActivity(modelId: number, modelType: TrackingActivityModelType) {
  const [trackingActivities, setTrackingActivities] = useState<ITrackingActivity[] | null>(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  // fetch Tracking Activity
  const fetchTrackingActivities = async (init = false) => {
    const {
      data,
      meta: { total: currentTotal }
    }: { data: ITrackingActivity[]; meta: { total: number } } = await TrackingActivityService.fetch(
      modelId,
      modelType,
      page,
      10
    );
    setTotal(currentTotal);
    if (init) {
      setTrackingActivities(data);
    } else {
      setTrackingActivities((prev) => {
        return prev ? [...prev, ...data] : [...data];
      });
    }
  };

  // load more
  const loadMore = () => {
    if (trackingActivities && trackingActivities.length < total) {
      setPage(page + 1);
    }
  };
  // have to watch userId because sw action before react sign-in
  useLegacyEffect(() => {
    if (modelId && modelType) {
      fetchTrackingActivities();
    }
  }, [page]);

  return {
    trackingActivities,
    total,
    loadMore,
    fetchTrackingActivities
  } as const;
}
