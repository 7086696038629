import React from "react";
import FileCirclePlus from "@components/icon/file-circle-plus";

interface IProps {
  onCreateTemplate: () => void;
  label?: string;
}
export default function CreateTemplateButton(props: IProps) {
  return (
    <button
      type="button"
      onClick={props.onCreateTemplate}
      className="flex space-x-1 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded-l-md text-purple-700 bg-purple-100 hover:bg-purple-200"
    >
      <FileCirclePlus className="w-4" />
      <p className="">{props.label || "Create Folder"}</p>
    </button>
  );
}
