import { EmailType, IEmailTemplate } from "@model/email";
import { ReactComponent as FolderIcon } from "@icons/folder-solid.svg";
import ActionButtons from "@components/action-buttons";
import React from "react";

const EmailTemplateListItem = ({
  item,
  onHandleUpdated,
  onHandleRemoved,
  onHandleChangeFolder,
  isActive = false,
  editable = true
}: {
  item: IEmailTemplate;
  onHandleRemoved: (id: number, activeTemplate: boolean) => void;
  onHandleUpdated: (item: IEmailTemplate) => void;
  onHandleChangeFolder: (id: number) => void;
  isActive?: boolean;
  editable?: boolean;
}) => {
  const handleRemove = () => {
    onHandleRemoved(item.id, isActive);
  };

  const handleUpdate = () => {
    onHandleUpdated(item);
  };

  const { type, id } = item;
  const isFolder = type === EmailType.folder;
  // const remove = isFolder ? undefined : handleRemove;
  const update = isFolder ? handleUpdate : undefined;
  return (
    <div className="flex items-center space-x-4">
      <div className="min-w-0 flex-1 flex">
        {isFolder && (
          <div className="w-8 mr-1">
            <FolderIcon className="w-8 h-6 text-gray-600 ml-[-3px]" />
          </div>
        )}
        <p
          onClick={isFolder ? () => onHandleChangeFolder(id) : handleUpdate}
          className={
            "truncate text-sm cursor-pointer self-center" +
            (isActive ? " text-purple-600" : " text-gray-900")
          }
        >
          {item.name}
        </p>
      </div>
      {editable && (
        <ActionButtons
          remove={handleRemove}
          update={update}
          style={{ menuItems: "right-0 w-56 mt-6" }}
        />
      )}
    </div>
  );
};

export default EmailTemplateListItem;
