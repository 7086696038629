export interface IMessage {
  id: number | string;
  thread_id?: number;
  bcc?: string;
  cc?: string;
  subject?: string;
  created_at?: Date;
  from?: string;
  google_message_id?: string;
  reply_message_id?: string;
  history_id?: number;
  html?: string;
  main_html?: string;
  history_html?: string;
  label_ids?: string; // 'UNREAD' | 'CATEGORY_PERSONAL' | 'INBOX'; https://developers.google.com/gmail/api/guides/labels
  snippet?: string;
  text?: string;
  to?: string;
  attachments?: IAttachment[];
  parent_id?: number;
  replyTo?: any | null;
  status: EmailStatus;
  updated_at?: Date;
}

export interface IThread {
  id: number;
  user_id: number;
  google_thread_id: string;
  history_id: number;
  messages: IMessage[];
  snippet: string;
  updated_at: Date;
  created_at: Date;
}

export interface IAttachment {
  id: number;
  message_id: number;
  google_message_id: string;
  attachment_id: string;
  mime_type: string;
  file_name: string;
  data: Date;
  updated_at: Date;
  created_at: Date;
}

export type GlobalEmailType = {
  refer: GlobalEmailReferType | null;
  data: IMessage | null;
};
export type GlobalEmailReferType = { ref_id: number; ref_type: string; contact_id: number | null };

export enum EmailStatus {
  sent = "sent",
  draft = "draft"
}

export enum EmailAction {
  send = "send",
  update = "update"
}

export enum EmailType {
  template = "template",
  folder = "folder"
}

export interface IEmailTemplate {
  id: number;
  folder_id?: number;
  type: EmailType;
  name: string;
  subject: string;
  template: string;
  updated_at?: Date;
  created_at?: Date;
}

export const LIMITED_EMAIL_USERS = [
  "webquest@lazudi.com", // for testing
  "petch@lazudi.com",
  "sirawich@lazudi.com",
  "tidarat@lazudi.com",
  "sam@lazudi.com",
  "roger@lazudi.com",
  "ranida@lazudi.com",
  "key_jenjira@lazudi.com"
];
