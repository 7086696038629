import { deleteItem, get, post } from "@utils/api";
import type { queryType } from "@utils/api";
import { NewContactFormValues } from "@pages/contacts/create";
import { UserRole } from "@model/app";

type returnItemType = {
  label: string;
  value: string;
  options?: any;
};

type renderCallback = (item: any) => returnItemType;

class ContactService {
  endpoint: string;
  queries: queryType;

  static async call(
    keySearch: UserRole | null,
    value: string,
    renderCallback: renderCallback
  ): Promise<any> {
    const queries: queryType = {};
    let endpoint = "users";
    let roleFilters: string[] = [
      UserRole.superAdmin,
      UserRole.user,
      UserRole.admin,
      UserRole.agent,
      UserRole.customerSuccess,
      UserRole.affiliate
    ];

    if (keySearch && [UserRole.agent].includes(keySearch)) {
      roleFilters = [
        UserRole.superAdmin,
        UserRole.admin,
        UserRole.agent,
        UserRole.customerSuccess,
        UserRole.affiliate
      ];
    }

    queries.search = value;
    queries.filters = JSON.stringify({
      role: {
        or: roleFilters
      }
    });
    // const isUser: boolean = type === 'user';
    // const isTeam: boolean = type === 'team';

    // if (isUser) {
    //   endpoint = 'users';
    // } else if (isTeam) {
    //   endpoint = 'teams';
    //   queries.filters = JSON.stringify({
    //     'name': value,
    //   });
    // }

    return await new ContactService(endpoint, queries).get(renderCallback);
  }

  static async getUserWith(filters: queryType, renderCallback: renderCallback): Promise<any> {
    const queries: queryType = {};
    const endpoint: string = "users";

    queries.filters = JSON.stringify(filters);

    return await new ContactService(endpoint, queries).get(renderCallback);
  }

  static async searchUserWith(query: string, renderCallback: renderCallback): Promise<any> {
    const queries: queryType = {};
    const endpoint: string = "users/search";

    queries.q = query;

    return await new ContactService(endpoint, queries).get(renderCallback);
  }

  static async createUser(payload: NewContactFormValues): Promise<any> {
    const endpoint: string = "users";
    return await new ContactService(endpoint, {}).create(payload);
  }

  static async removeUser(id: number): Promise<any> {
    const endpoint: string = `users/${id}`;
    return await new ContactService(endpoint, {}).delete();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async create(payload: NewContactFormValues): Promise<any> {
    const path = this.endpoint;
    const data = {
      ...payload,
      role: "user",
      is_active: true,
      tags: JSON.stringify(["seller"])
    };
    return await post({ path, data });
  }

  async get(renderCallback: renderCallback): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data.map(renderCallback);
  }

  async delete(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    return await deleteItem({ path, queries });
  }
}

export default ContactService;
