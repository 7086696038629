import { useEffect, useState, Fragment } from "react";
import { FormGroup, MyEditor, WithIndicator } from "@views/components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { selectUserProfile } from "@state/selectors";
import { EmailStatus, IEmailTemplate, IMessage } from "@model/email";
import { useSelector } from "react-redux";
import UploadImageService from "@services/images/upload-image.service";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/solid";
import EmailChip from "@views/components/email-chip";

const schema = yup
  .object({
    to: yup.string().email().nullable().required("Required field"),
    cc: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().email().required()
        })
      )
      .nullable(),
    bcc: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().email().required()
        })
      )
      .nullable(),
    subject: yup.string().nullable().required("Required field"),
    html: yup.string().nullable().required("Required field")
    // html: yup.string().transform(removeHtmlTags)
    //   .min(300, 'Content must be at least 300 characters')
    //   .max(2000, 'Content must be at most 2000 characters').nullable().required('Required field'),
  })
  .required();

const getEmailFromString = (text: string): string => {
  let re = /[^< ]+(?=>)/g;
  let arr = text.match(re);
  return arr?.length ? arr[0] : text;
};

/*
  Email Action
    send: Send New Email [id = 0 & status = sent]
    draft: Draft New Email [id = 0 & status = draft]
    update-draft: Update Drafted Email [id != 0 & status = draft & parent-status = draft & parent-id = null]
    send-draft-email: Send email that has been drafted [id != 0 & status = sent & parent-status = draft & parent-id = null]
    reply: Reply Email [id != 0 & status = sent & parent-status = sent]
    draft-reply-email: Draft on email reply [id != 0 & status = draft & parent-status =draft]
    update-drafted-reply-email: Update draft on drafted email that has an parent id
    send-drafted-reply-email: Send an email that has been drafted as reply email
*/

const EmailForm = ({
  refer,
  data,
  selectedTemplate,
  onSubmit,
  onDiscard,
  closeModal
}: {
  refer?: { ref_id: number; ref_type: string };
  data: IMessage;
  selectedTemplate: IEmailTemplate | null;
  onSubmit: (item: IMessage) => Promise<void>;
  onDiscard: (id: number) => void;
  closeModal: () => void;
}) => {
  const [recipient, setRecipient] = useState({
    cc: !!data.cc,
    bcc: !!data.bcc
  });

  // 2 cases for reply obj
  // - click reply on the email card (replyTo)
  // - click edit draft (email has parent)
  const hasReplyObject = data.replyTo || data.parent_id;

  const userData = useSelector(selectUserProfile);

  const getReplyToEmail = (): string => {
    const userEmail = userData?.email;
    if (data?.replyTo) {
      const replyToFromEmail = getEmailFromString(data?.replyTo?.from);
      if (replyToFromEmail !== userEmail) {
        return replyToFromEmail;
      }
      const replyToToEmail = getEmailFromString(data?.replyTo?.to);
      if (replyToToEmail !== userEmail) {
        return replyToToEmail;
      }
    }
    if (data?.to) {
      return getEmailFromString(data?.to);
    }
    return "";
  };

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    formState: { errors }
  } = useForm<IMessage>({
    defaultValues: {
      id: data.id,
      to: getReplyToEmail(),
      bcc: data?.replyTo?.bcc || data?.bcc || "",
      cc: data?.replyTo?.cc || data?.cc || "",
      subject: data?.replyTo?.subject || data.subject || "",
      html: data.text || "", // avoid undefined from docs mentioned
      status: data.status
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (selectedTemplate) {
      // not to override subject on reply email
      if (!hasReplyObject) {
        resetField("subject", { defaultValue: selectedTemplate.subject });
      }

      resetField("html", { defaultValue: selectedTemplate.template });
    }
  }, [selectedTemplate]);

  const handleRecipient = (key: "cc" | "bcc") => {
    setRecipient((prev) => ({ ...prev, [key]: !prev[key] }));
    setValue(key, "");
  };

  const onSendEmail = async (item: IMessage) => {
    /* 
      Incoming Action:
      send, reply, send-draft-email, send-drafted-reply-email 
    */
    await onSubmit(generatePayloadByMessage({ ...item, status: EmailStatus.sent }));
  };

  const onDraftEmail = (e: Event) => {
    e.preventDefault();
    /* 
      Incoming Action:
      draft, update-draft, draft-reply-email, update-drafted-reply-email 
    */
    const payload: IMessage = getValues();
    onSubmit(generatePayloadByMessage({ ...payload, status: EmailStatus.draft }));
  };

  const onDiscardEmail = (e: Event) => {
    e.preventDefault();

    if (data.id) onDiscard(Number(data.id)); // call api to remove from DB

    closeModal();
  };

  function convertEmailsToString(payload: IMessage, fieldName: string) {
    const formPayload = payload as unknown as Record<string, string>;
    if (typeof formPayload[fieldName] !== "string") {
      const emailField = formPayload[fieldName] as unknown as { value: string }[];
      if (Array.isArray(emailField)) {
        formPayload[fieldName] = emailField.map((item) => item.value).join(", ");
      }
    }
    return formPayload[fieldName];
  }

  const generatePayloadByMessage = (message: IMessage): IMessage => {
    let formPayload: IMessage = { ...message, text: message.html };

    if (formPayload.cc) {
      formPayload.cc = convertEmailsToString(formPayload, "cc");
    }

    if (formPayload.bcc) {
      formPayload.bcc = convertEmailsToString(formPayload, "bcc");
    }

    if (!hasReplyObject) {
      // no reply email (create new or Edit draft[draft without parent])
      return formPayload;
    } else {
      // reply to emails

      let replyToEmail = {
        // Edit draft (data comes from API)
        parent_id: data.parent_id,
        thread_id: data.thread_id,
        reply_message_id: data.google_message_id
      };

      if (data.replyTo) {
        // Reply to (replyTo was added when click reply from email card)
        replyToEmail.parent_id = data.replyTo.id;
        replyToEmail.thread_id = data.replyTo.thread_id;
        replyToEmail.reply_message_id = data.replyTo.google_message_id;
      }

      // payload need to have formPayload (form data)
      // reply case needs to attach replyToEmail fields
      return { ...formPayload, ...replyToEmail };
    }
  };

  return (
    <form onSubmit={handleSubmit(onSendEmail)}>
      <FormGroup labelWidthClass="sm:w-[100px]" label="To" errors={errors} field="to">
        <div className="flex flex-col flex-1">
          <input
            type="email"
            {...register("to", { required: true })}
            className="h-[40px] block w-full border-gray-300 rounded-md shadow-sm focus:ring-0  sm:text-sm focus:border-purple-700"
          />

          <div className="absolute top-[-13px] right-4 flex justify-end space-x-3 z-20 text-xs text-gray-400">
            <p
              className={`cursor-pointer hover:underline ${recipient.cc && "text-purple-400"}`}
              onClick={() => handleRecipient("cc")}
            >
              Cc
            </p>
            <p
              className={`cursor-pointer hover:underline ${recipient.bcc && "text-purple-400"}`}
              onClick={() => handleRecipient("bcc")}
            >
              Bcc
            </p>
          </div>
        </div>
      </FormGroup>
      {recipient.cc && (
        <FormGroup labelWidthClass="sm:w-[100px]" label="Cc" errors={errors} field="cc">
          <div className="flex flex-col flex-1">
            <EmailChip name="cc" control={control} required />
          </div>
        </FormGroup>
      )}

      {recipient.bcc && (
        <FormGroup labelWidthClass="sm:w-[100px]" label="Bcc" errors={errors} field="bcc">
          <div className="flex flex-col flex-1">
            <EmailChip name="bcc" control={control} required />
          </div>
        </FormGroup>
      )}
      <FormGroup labelWidthClass="sm:w-[100px]" label="From" errors={errors} field="scheduled_at">
        <p className="text-gray-400">
          {userData?.name} ({userData?.email})
        </p>
      </FormGroup>
      <FormGroup labelWidthClass="sm:w-[100px]" label="Subject" errors={errors} field="subject">
        <div className="flex flex-col flex-1">
          {data.google_message_id || hasReplyObject ? (
            <p className="text-gray-400">Re: {data?.replyTo?.subject || data?.subject || ""}</p>
          ) : (
            <input
              type="text"
              {...register("subject", { required: true })}
              className="h-[40px] block w-full border-gray-300 rounded-md shadow-sm focus:ring-0  sm:text-sm focus:border-purple-700"
            />
          )}
        </div>
      </FormGroup>

      {/* <FormGroup labelWidthClass="sm:w-[100px]" label='Message' errors={errors} field='html'>
      
      </FormGroup> */}

      <div className="flex flex-col flex-1 mt-4 sm:px-3">
        <Controller
          key={selectedTemplate ? selectedTemplate.id : "html_editor"}
          name="html"
          control={control}
          render={({ field: { onChange, value } }: any) => (
            <WithIndicator isLoaded={selectedTemplate?.id !== 0}>
              <MyEditor
                initialValue={selectedTemplate ? selectedTemplate.template : value}
                init={{
                  height: 300,
                  paste_data_images: true,
                  plugins: ["paste", "image", "lists", "link", "textcolor"],
                  toolbar: [
                    {
                      name: "",
                      items: ["link", "forecolor", "backcolor", "image"]
                    }
                  ]
                }}
                // onBlur={onBlr}
                onImageUpload={async (blob: any) => {
                  const formData = new FormData();
                  formData.append("file", blob);
                  if (refer) {
                    formData.append("ref_id", String(refer.ref_id));
                    formData.append("ref_type", refer.ref_type);
                  }

                  const res = await UploadImageService.call({
                    data: formData,
                    endPoint: "messages/upload"
                  });

                  return res?.url;
                }}
                onChange={(event: any) => onChange(event)}
              />
            </WithIndicator>
          )}
        />
        <p className="mt-1 text-xs text-red-400 flex-0 whitespace-nowrap">
          {errors?.html?.message}
        </p>
      </div>
      <div className="pb-6 mt-7 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-col-dense">
        <SaveDraftButtonGroup onDraft={onDraftEmail} onClose={onDiscardEmail} />
        <button
          type="submit"
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-center text-white bg-purple-600 border border-transparent rounded-md shadow-sm hover:bg-purple-700 focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:text-sm"
        >
          Send
        </button>
      </div>
    </form>
  );
};

const SaveDraftButtonGroup = ({
  onDraft,
  onClose
}: {
  onDraft: (e: any) => void;
  onClose: (e: any) => void;
}) => {
  return (
    <div className="grid grid-cols-[1fr_auto_auto] hover:bg-gray-50 border border-gray-300 items-center rounded-md shadow-sm mt-3 sm:mt-0">
      <button
        type="button"
        onClick={onDraft}
        className="relative px-4 py-2 text-base font-medium text-center text-gray-700 bg-white rounded-l-md sm:text-sm hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-300"
      >
        Save Draft
      </button>
      <div className="w-[2px] h-6 bg-gray-400"></div>
      <Popover as="div" className="relative block h-full -ml-px">
        {({ open }) => (
          <>
            <Popover.Button
              className={
                "relative inline-flex h-full items-center rounded-r-md text-white px-2 py-2 sm:text-sm text-base font-medium hover:bg-gray-50 focus:z-10 focus:outline-none" +
                (open ? " bg-gray-50" : " bg-white")
              }
            >
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="w-5 h-5 text-gray-900" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-0 z-10 w-56 mt-2 -mr-1 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex w-full px-4 py-2 text-sm text-red-700 hover:bg-red-100"
                  >
                    <TrashIcon className="h-5 mr-2" />
                    <span>Close &amp; Discard</span>
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default EmailForm;
