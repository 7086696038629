import { post } from "@utils/api";
import { IMessage } from "@model/email";

class SendEmailService {
  endpoint: string;
  data: any;

  static async call(item: any): Promise<IMessage> {
    const service = new SendEmailService("messages", item);
    return await service.call();
  }

  constructor(endpoint: string, data: any) {
    this.endpoint = endpoint;
    this.data = data;
  }

  async call(): Promise<any> {
    const path = this.endpoint;
    const {
      data: { data }
    }: any = await post({ path, data: this.data });
    return data;
  }
}

export default SendEmailService;
