import { get } from "@utils/api";
import type { queryType } from "@utils/api";

class LocationDropdownService {
  endpoint: string;
  queries: queryType;

  static async call(value: string, configs: any): Promise<any> {
    let endpoint: string = "locations/search";

    const queries: queryType = {
      search: value,
      country_id: configs?.filters?.country_id || ""
    };

    return await new LocationDropdownService(endpoint, queries).get();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async get(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;

    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data.map((item: any) => {
      return { value: item.geo_id, label: item.location_format };
    });
  }
}

export default LocationDropdownService;
