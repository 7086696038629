import { useMemo } from "react";
import DropdownMenu, { DropdownMenuStyle } from "../dropdown-menu";
import ActionItem from "./action-item";

type ActionButtonProps = {
  style?: DropdownMenuStyle;
  remove?: () => void;
  update?: () => void;
  setPrimary?: () => void;
};

const ActionButtons = ({ style, remove, update, setPrimary }: ActionButtonProps) => {
  const dropdownStyles = useMemo(() => {
    return {
      menu: "flex justify-center text-left flex-none min-w-[40px]",
      menuItems: "right-0 md:left-0 w-56 mt-2",
      menuButton: "text-gray-400",
      ...style
    };
  }, [style?.menu, style?.menuButton, style?.menuItems]);

  return (
    <DropdownMenu
      style={dropdownStyles}
      popperOptions={{
        placement: "bottom-end",
        modifiers: [{ name: "offset", options: { offset: [30, 0] } }]
      }}
    >
      <div className="py-1">
        {setPrimary && <ActionItem label="Make Primary" onClick={setPrimary} />}
        {update && (
          <ActionItem
            customStyles="text-blue-500 hover:text-blue-600 hover:bg-blue-100"
            label="Update"
            onClick={update}
          />
        )}
        {remove && (
          <ActionItem
            customStyles="text-red-500 hover:text-red-600 hover:bg-red-100"
            label="Delete"
            onClick={remove}
          />
        )}
      </div>
    </DropdownMenu>
  );
};

export default ActionButtons;
