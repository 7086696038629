import useLegacyEffect from "@utils/hooks/useLegacyEffect";
import useModal from "@utils/hooks/useModal";
import { AppStorageType } from "@model/app";
import { useLocalStorage } from "@utils/hooks/useLocalStorage";

export default function useGooglePermissionModal() {
  const [openModal] = useModal();
  const [storage] = useLocalStorage<AppStorageType>("", "app", { token: "" });
  const hasGrantedAllScopes = storage?.hasGrantedAllScopes;

  useLegacyEffect(() => {
    if (hasGrantedAllScopes) return;
    openModal("google-permission-modal");
  }, []);
}
