import React from "react";
import { TASK_TYPE } from "@model/task";
import { TrackingActivityModelKey } from "@components/activity-controller";
import { ActivityTasks } from "@components/activity-controller/components";
import withTaskLoading from "@views/components/task-controller/hooks/withTaskLoading";
import withTaskModalStore from "@views/components/task-controller/hooks/withTaskModalStore";
import { IProperty } from "@model/property";
import { IProject } from "@model/project";
import { IDeal } from "@model/deal";
import { UserType } from "@model/contact";
import NoteView from "./task.note-view";

interface ITaskBoardProps {
  label: string;
  modelId: number;
  data: UserType | IDeal | IProperty | IProject;
  type: TASK_TYPE;
  modelKey: TrackingActivityModelKey;
}

const TaskBoard: React.FC<ITaskBoardProps> = ({ label, modelId, modelKey, data, type }) => {
  return (
    <div>
      {type === TASK_TYPE.NOTES ? (
        <NoteView modelId={modelId} modelKey={modelKey} type={type} data={data} label={label} />
      ) : (
        <ActivityTasks modelId={modelId} modelKey={modelKey} type={type} />
      )}
    </div>
  );
};

export default withTaskModalStore(withTaskLoading(TaskBoard));
