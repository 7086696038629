import React from "react";
import { IconType } from "./icon.type";

function FolderPlus({ className }: IconType): JSX.Element {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M448 96h-176L226.7 50.75C214.7 38.74 198.5 32 181.5 32H64C28.66 32 0 60.65 0 96v320c0 35.35 28.66 64 64 64h384c35.34 0 64-28.65 64-64V160C512 124.7 483.3 96 448 96zM480 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h117.5c8.547 0 16.58 3.328 22.62 9.375L258.7 128H448c17.64 0 32 14.36 32 32V416zM336 272h-64V207.1C272 199.2 264.8 192 256 192S240 199.2 240 207.1V272h-64C167.2 272 160 279.2 160 288c0 8.834 7.156 15.1 16 15.1h64v64C240 376.8 247.2 384 256 384c8.836 0 16-7.16 16-15.1V303.1h64C344.8 303.1 352 296.8 352 288S344.8 272 336 272z"
      />
    </svg>
  );
}

export default FolderPlus;
