import { TASK_TYPE } from "@model/task";

export type rawNotificationType = {
  data: any[];
  links: {};
  meta: {
    current_page: number;
    last_page: number;
    unread_count: number;
  };
};
export interface NotificationAlertType {
  title: string;
  body: any;
  id: number;
  data: NotificationAlertDataType;
}

// notification menu item
export type NotificationMenuItemType = {
  name: string;
  description?: string;
  dueDate: string;
  createDate?: string;
  type: TASK_TYPE;
  isRead: boolean;
  url: string;
  id: number;
};
export interface INotificationMenu {
  data: NotificationMenuItemType[];
  meta: {
    nextPage: number;
    lastPage: number;
    unreadCount: number;
  };
}

export type NotificationAlertDataType = {
  id: number;
  due_date: string;
  created_date: string;
  ref_id: string;
  ref_type: string;
  event: NOTIFICATION_EVENT;
  type: TASK_TYPE;
};

export enum NotificationOnClickType {
  menu = "menu",
  alert = "alert"
}

export enum NOTIFICATION_EVENT {
  BULK_LISTING_REASSIGN = "bulk-listing-reassign"
}
