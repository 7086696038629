import React from "react";
import { classNames } from "@utils/helpers";
import { Sorting } from "./table.tools";
import {
  MinTableItem,
  ITableProps,
  columnFiltered,
  isPrimitive,
  QueryConfigType,
  ColumnConfigType,
  TableLayoutType
} from "../index";

interface ITableView<T extends MinTableItem> {
  layout: TableLayoutType;
  data: ITableProps<T>;
  queries: QueryConfigType;
  columns: ColumnConfigType[];
  setQueries: React.Dispatch<React.SetStateAction<QueryConfigType>>;
  rowsSelected: number[];
  isShowBatchEditing: boolean;
  allRowsChecked: boolean;
  isFetching: boolean;
  setAllRowsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  items: T[] | null;
  setRowsSelected: React.Dispatch<React.SetStateAction<number[]>>;
}

const CardView = <T extends MinTableItem>(props: ITableView<T>) => {
  const { data, items } = props;

  return <>{data.renderCardTemplate && items?.map(data.renderCardTemplate)}</>;
};

const TableView = <T extends MinTableItem>(props: ITableView<T>) => {
  const {
    data,
    queries,
    columns,
    setQueries,
    rowsSelected,
    isShowBatchEditing,
    allRowsChecked,
    isFetching,
    setAllRowsChecked,
    items,
    setRowsSelected
  } = props;

  // render table body
  const renderRow = (item: T, idx: number) => {
    return (
      <tr
        key={idx}
        className={classNames(
          idx % 2 === 0 ? "bg-white" : "bg-gray-50",
          rowsSelected.includes(item.id) ? "bg-purple-100" : "hover:bg-gray-100"
        )}
      >
        {isShowBatchEditing && (
          <td className="px-2 text-center bg-gray-50 max-w-[150px]">
            <input
              id={`col_${idx}`}
              type="checkbox"
              className={`w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500 ${
                data.isSelectedAllItems ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              checked={rowsSelected.includes(item.id)}
              disabled={data.isSelectedAllItems}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                // add id to the lists
                if (e.target.checked) {
                  setRowsSelected((prev) => [...prev, item.id]);
                  data.onToggleItem && data.onToggleItem(true, item.id);
                  return;
                }

                // remove id from the lists
                setRowsSelected((prev) => prev.filter((i) => i !== item.id));
                data.onToggleItem && data.onToggleItem(false, item.id);
              }}
            />
          </td>
        )}
        <td className="px-2 max-w-[150px]">{data?.actionOptions && data.actionOptions(item)}</td>
        {
          // columns?.filter(col => col.visible)
          columnFiltered(columns ?? []).map((col) => {
            const key = col.key; // visible column key
            const customRenderer = data.customRenderers?.[key as keyof T];

            if (customRenderer) {
              return (
                <td
                  key={`${idx}_${key}`}
                  className="max-w-[150px] overflow-hidden truncate  px-4 py-3 text-sm text-gray-500 whitespace-nowrap"
                >
                  {customRenderer(item)}
                </td>
              );
            }

            return (
              <td
                key={`${idx}_${col.key}`}
                className="max-w-[150px] truncate px-4 py-3 text-sm text-gray-500 whitespace-nowrap capitalize"
              >
                <>{isPrimitive(item[key as keyof T]) ? item[key as keyof T] : ""}</>
              </td>
            );
          })
        }
      </tr>
    );
  };

  return (
    <table className="w-full divide-y divide-gray-200 select-none ">
      <thead className="bg-gray-50">
        <tr>
          {isShowBatchEditing && (
            <th className="bg-gray-50 ">
              <input
                id={`col_${0}`}
                type="checkbox"
                className="w-4 h-4 text-purple-600 border-gray-300 rounded cursor-pointer focus:ring-purple-500"
                checked={allRowsChecked}
                disabled={isFetching}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAllRowsChecked(!allRowsChecked);

                  const ids: number[] = [];
                  // add row id to the lists
                  if (e.target.checked) {
                    items?.map((item: T) => ids.push(item.id));
                  }

                  setRowsSelected(ids);
                  data.onToggleAllItems && data.onToggleAllItems(ids);
                }}
              />
            </th>
          )}
          <th>{/* action button */}</th>
          {columnFiltered(columns || []).map((col, idx) => (
            <th
              key={idx}
              scope="col"
              className="max-w-[150px] px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              <div
                title={col.label || col.key}
                className={classNames(col.sortable ? "cursor-pointer" : "", "flex items-center ")}
                onClick={() => {
                  if (!col.sortable) return;
                  let sortKey = col.key;
                  if (col.filters?.relationship) {
                    sortKey = `${col.filters?.relationship}.${col.key}`;
                  }

                  const sort =
                    queries.sort_by !== sortKey ? "desc" : queries.sort === "desc" ? "asc" : "desc";

                  setQueries({ ...queries, sort_by: sortKey, sort });
                }}
              >
                <p className="truncate">{col.label || col.key}</p>
                {col.sortable && <Sorting name={col.key} queries={queries} />}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{!isFetching && items?.map(renderRow)}</tbody>
    </table>
  );
};

const TableLayout = <T extends MinTableItem>(props: ITableView<T>) => {
  return (
    <div className="flex-1 overflow-auto">
      {props.layout === TableLayoutType.table ? <TableView {...props} /> : <CardView {...props} />}
    </div>
  );
};

export default TableLayout;
