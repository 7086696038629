import Select, { MultiValue } from "react-select";
import { IFilterProps } from "../table.filter";
import { getCustomSelectStyles } from "@utils/react-select.helpers";

export default function MultiSelectionFilter(props: IFilterProps) {
  const value = props.config?.options?.filter((f: any) =>
    props.value?.[props.config?.condition ?? ""]?.includes(f.value)
  );

  const handleChange = (e: MultiValue<any>) => {
    const searchKey = props.id ?? "";
    const values = e?.map((item: any) => item.value) || [];
    const searchValues = values?.length
      ? {
          [props.config?.condition ?? ""]: values,
          relationship: props.config?.relationship
        }
      : undefined;
    props.setFilterState((prev: {}) => ({
      ...prev,
      [searchKey]: searchValues
    }));
  };

  return (
    <Select
      className="w-full text-[13px]"
      key="features"
      styles={getCustomSelectStyles(!!value?.length, true)}
      options={props?.config?.options}
      placeholder="Search features..."
      value={value}
      onChange={handleChange}
      closeMenuOnSelect={false}
      isClearable={true}
      isMulti
    />
  );
}
