import { ReactNode } from "react";

export default function MessageInfo({
  children,
  isWarning,
  className
}: {
  children?: ReactNode;
  isWarning?: boolean;
  className?: string;
}) {
  if (!children) return null;

  const color = isWarning ? "yellow" : "green";

  return (
    <div className={className ? ` ${className}` : ""}>
      <div className={`flex space-x-3 bg-${color}-100 w-[fit-content] px-4 py-2 rounded-md`}>
        <div className="flex-shrink-0">
          <svg
            className={`w-4 h-4 text-${color}-400`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className={`flex flex-1 space-x-2 text-xs text-${color}-500`}>{children}</div>
      </div>
    </div>
  );
}
