import { ReactNode } from "react";
import { ReactComponent as TaskIcon } from "@icons/task.svg";
import { ReactComponent as EmailSolid } from "@icons/email-solid.svg";
import { ReactComponent as Note } from "@icons/note.svg";
import { ReactComponent as Field } from "@icons/field.svg";
import {
  TrackingActivityActionType,
  TrackingActivityModelType
} from "@components/activity-controller";
import ReactTooltip from "react-tooltip";
import { PhoneIcon } from "@heroicons/react/solid";

export default function ActivityCardWrapper({
  action,
  refType,
  refData,
  children
}: {
  children: ReactNode;
  action?: TrackingActivityActionType;
  refType?: TrackingActivityModelType;
  refData: any;
}) {
  return (
    <div className="flex">
      <div className="mx-2 relative my-[-0.75rem] pt-4 w-12">
        <div className="z-1 border-2-2 absolute border-dashed border-gray-300 h-full border left-[50%]"></div>
        <div className="flex justify-center">
          <EventIcon refType={refType} refData={refData} action={action} />
        </div>
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
}

interface LabelMap {
  [key: string]: {
    [key: string]: string;
  };
}

interface IconMap {
  [key: string]: {
    [key: string]: ReactNode;
  };
}

const EventIcon = ({
  refType,
  refData,
  action
}: {
  action?: TrackingActivityActionType;
  refType?: TrackingActivityModelType;
  refData: any;
}) => {
  // get label for icon type
  const getToolTipLabel = () => {
    const labelMap: LabelMap = {
      [TrackingActivityModelType.task]: {
        task: "Task",
        viewing: "Viewing",
        notes: "Notes"
      },
      [TrackingActivityModelType.message]: {
        default: "Email"
      },
      [TrackingActivityModelType.twilio_record]: {
        default: "Phone Call"
      }
    };

    if (
      action === TrackingActivityActionType.call_in ||
      action === TrackingActivityActionType.call_out
    ) {
      return "Phone Call";
    }

    return (refType && labelMap[refType][refData?.type ?? "default"]) ?? "Field Update";
  };

  // get icon from ref type
  const getIcon = () => {
    const iconMap: IconMap = {
      [TrackingActivityModelType.task]: {
        task: <TaskIcon className="h-[1rem] w-[1rem]" />,
        viewing: <TaskIcon className="h-[1rem] w-[1rem]" />,
        notes: <Note className="h-[1rem] w-[1rem]" />
      },
      [TrackingActivityModelType.message]: {
        default: <EmailSolid className="h-[1rem] w-[1rem]" />
      },
      [TrackingActivityModelType.twilio_record]: {
        default: <PhoneIcon className="h-[1rem] w-[1rem]" />
      }
    };

    if (
      action === TrackingActivityActionType.call_in ||
      action === TrackingActivityActionType.call_out
    ) {
      return <PhoneIcon className="h-[1rem] w-[1rem]" />;
    }

    return (
      (refType && iconMap[refType][refData?.type ?? "default"]) ?? (
        <Field className="h-[1rem] w-[1rem]" />
      )
    );
  };

  return (
    <div
      data-for="activity-icon-tooltip"
      data-tip={getToolTipLabel()}
      className="relative z-10 flex flex-col justify-center w-10 h-10 bg-white border border-gray-300 rounded-full"
    >
      <div className="flex justify-center">{getIcon()}</div>
      <ReactTooltip
        id="activity-icon-tooltip"
        place="top"
        type="dark"
        effect="solid"
        delayShow={100}
      />
    </div>
  );
};
