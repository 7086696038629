import React from "react";
import CustomButton from "./button.custom";

interface IProps {
  handleClick: () => void;
  label?: string;
}
export default function CreateButton({ handleClick, label }: IProps) {
  return (
    <CustomButton
      label="Create"
      variant="primary-outline"
      size="sm"
      onClick={handleClick}
      className="px-2.5 py-1.5 max-w-[120px] space-x-1 text-xs font-medium"
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="plus"
        className="w-3 h-3 svg-inline--fa fa-plus"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M432 256C432 269.3 421.3 280 408 280h-160v160c0 13.25-10.75 24.01-24 24.01S200 453.3 200 440v-160h-160c-13.25 0-24-10.74-24-23.99C16 242.8 26.75 232 40 232h160v-160c0-13.25 10.75-23.99 24-23.99S248 58.75 248 72v160h160C421.3 232 432 242.8 432 256z"
        />
      </svg>
      <p className="hidden md:block">{label || "Create new"}</p>
    </CustomButton>
  );
}
