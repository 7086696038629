import { SearchIcon } from "@heroicons/react/solid";
import { classNames } from "@utils/helpers";
import { QueryConfigType } from "@views/components/data-table";
import debounce from "debounce-promise";

interface IQuickSearchProps {
  queries: QueryConfigType;
  setQueries: (queries: QueryConfigType) => void;
  placeholder?: string;
}

const QuickSearch = ({ queries, setQueries, placeholder }: IQuickSearchProps) => {
  const handleSearch = debounce((value: string) => {
    setQueries({
      ...queries,
      search: value
    });
  }, 500);

  return (
    <div className="relative sm:max-w-[200px] w-full rounded">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <SearchIcon className="w-5 h-5 text-gray-300" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="search"
        id="search"
        defaultValue={queries?.search}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder={placeholder ? placeholder : "Search"}
        className={classNames(
          queries.search
            ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
            : "focus:border-gray-300",
          "block w-full pl-10 border-gray-300 rounded-md focus:ring-0 sm:text-sm text-xs"
        )}
      />
    </div>
  );
};

export default QuickSearch;
