import { get } from "@utils/api";
import type { queryType } from "@utils/api";
import { IDeal } from "@model/deal";

class DealDropdownService {
  endpoint: string;
  queries: queryType;

  static async call(value: string): Promise<any> {
    const queries: queryType = {};
    let endpoint: string = "deals";

    queries.filters = JSON.stringify({
      title: value
    });

    return await new DealDropdownService(endpoint, queries).get();
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async get(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;

    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data.map((item: any) => {
      const deal = item as IDeal;
      const label = `${deal?.pipeline?.name}: #${item.id} ${deal.title}`;
      return {
        label: label,
        value: item.id
      };
    });
  }
}

export default DealDropdownService;
