import { combineReducers } from "redux";
import authReducer from "./reducer.auth";
import appReducer from "./reducer.app";
import notificationReducer from "./reducer.notification";
import batchEditingReducer from "@components/data-table/features/batch-editing/batch.slice";

const reducers = combineReducers({
  auth: authReducer,
  app: appReducer,
  notification: notificationReducer,
  batchEditing: batchEditingReducer
});

export default reducers;

export type State = ReturnType<typeof reducers>;
