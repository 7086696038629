import React, { useEffect, useState, useRef, useMemo } from "react";
import { IFilterProps } from "../table.filter";
import DatePickerFilter from "./filter.date-picker";
import type { ColumnFilterType } from "../index";
import { numberWithCommas, removeCommas, classNames } from "@utils/helpers";

type CurrentCondition = {
  condition: string;
  values: string[];
};
type Conditions = {
  label: string;
  value: string;
  selected: boolean;
};
type handleStateUpdate = { type: string; value: string; index?: number };

const defaultCondition = [
  { label: "Is", value: "is", selected: true },
  { label: "Less than", value: "less", selected: false },
  { label: "More than", value: "more", selected: false },
  { label: "Between", value: "between", selected: false },
  { label: "Known", value: "is_not_null", selected: false },
  { label: "Unknown", value: "is_null", selected: false }
];
const calendarCondition = [
  { label: "Is", value: "is", selected: true },
  { label: "Before", value: "less", selected: false },
  { label: "After", value: "more", selected: false },
  { label: "Between", value: "between", selected: false },
  { label: "Known", value: "is_not_null", selected: false },
  { label: "Unknown", value: "is_null", selected: false }
];

export default function RangeFilter(props: IFilterProps) {
  const defaultValues = Object.entries<[string, string]>(props?.value || {})[0];
  let condition = defaultValues?.length ? defaultValues[0] : "is";
  let values: string[] = defaultValues?.length ? defaultValues[1] : [];
  const [state, setState] = useState<CurrentCondition>({ condition, values });
  const [conditions] = useState<Conditions[]>(
    props.config.type === "calendar" ? calendarCondition : defaultCondition
  );
  const isTextInputAllowed = useMemo(
    () => state.condition === "is" && props.config.allowTextInput,
    [state.condition, props.config.allowTextInput]
  );
  // const activeCondition = conditions.find(c => c.selected);
  const isMounted = useRef(false);

  useEffect(() => {
    const { value } = props;
    if (!value) {
      // clear filters
      setState({ condition: "is", values: [] });
    }
  }, [props.value]);

  useEffect(() => {
    // update state back to parent
    if (isMounted.current) {
      if (state.values.some((v) => !!v)) {
        let values = state.values;
        if (props.config.type !== "calendar" && !isTextInputAllowed) {
          // remove all commas from number input
          values = values.map((value) => removeCommas(value));
        }

        let filter: any = { [state.condition]: values };
        // add relation if exist
        if (props.config.relationship) filter.relationship = props.config.relationship;

        props.setFilterState((prev: {}) => ({ ...prev, [props.id]: filter }));

        if (props.onUpdateFilterState) {
          props.onUpdateFilterState({
            [props.id]: filter
          });
        }
        return;
      }
      // clear this filter if value is null
      props.setFilterState((prev: {}) => {
        const updatedFilters: any = { ...prev };
        delete updatedFilters[props.id];
        return {
          ...updatedFilters
        };
      });
    }
  }, [state]);

  const handleUpdate = (obj: handleStateUpdate) => {
    isMounted.current = true;
    if (obj.type === "condition") {
      // const newValues = obj.value !== 'between' ? [state.values[0]] : state.values

      let newValues = state.values;
      if (obj.value !== "between") {
        let singleValue = state.values[0];
        // for null and not_null will set the value same as key (to keep it not empty)
        if (["is_not_null", "is_null"].includes(obj.value)) {
          singleValue = obj.value;
        }
        newValues = [singleValue];
      }

      // clear values when conditions match
      if (
        !["is_not_null", "is_null"].includes(obj.value) &&
        newValues.find((n) => ["is_not_null", "is_null"].includes(n))
      ) {
        newValues = newValues.map((n) => "");
      }

      setState((prev) => ({ ...prev, condition: obj.value, values: newValues }));
    } else {
      // values
      if (obj.index! >= 0) {
        let values = [...state.values];
        values[obj.index!] = obj.value;
        setState((prev) => ({ ...prev, values }));
      }
    }
  };

  return (
    <div className="flex space-x-3">
      <div className="flex-0 max-w-[100px]">
        <select
          id={`condition_${props.id}`}
          className={classNames(
            props.value
              ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
              : "focus:border-gray-300",
            "block w-full px-3 border-gray-300 focus:ring-0 py-2 pl-3 pr-6 text-sm  rounded-md sm:text-xs h-[38px]"
          )}
          onChange={(e) => {
            handleUpdate({ type: "condition", value: e.target.value });
          }}
          value={state.condition}
        >
          {conditions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {["is_not_null", "is_null"].includes(state.condition) ? (
        <input
          type="text"
          disabled
          autoComplete="off"
          className="flex-1 block w-full bg-gray-100 border-gray-300 rounded-md focus:ring-0 sm:text-sm"
        />
      ) : (
        <div className="flex-1">
          {["between"].includes(state.condition) ? (
            <div className="flex items-center space-x-1">
              <InputType
                name="value1"
                handleUpdate={(obj) => handleUpdate({ ...obj, index: 0 })}
                value={state.values[0]}
                config={props.config}
              />
              <p>-</p>
              <InputType
                name="value2"
                handleUpdate={(obj) => handleUpdate({ ...obj, index: 1 })}
                value={state.values[1]}
                config={props.config}
              />
            </div>
          ) : (
            <InputType
              name="value1"
              handleUpdate={(obj) => handleUpdate({ ...obj, index: 0 })}
              value={state.values[0]}
              config={props.config}
              isTextInputAllowed={isTextInputAllowed}
            />
          )}
        </div>
      )}
    </div>
  );
}

type InputProps = {
  value: string | null;
  handleUpdate: (obj: handleStateUpdate) => void;
  name: string;
  config: ColumnFilterType;
  isTextInputAllowed?: boolean;
};
const InputType = ({ value, handleUpdate, name, config, isTextInputAllowed }: InputProps) => {
  if (config.type === "calendar") {
    return (
      <DatePickerFilter
        id={name}
        config={config}
        setValue={(obj) => handleUpdate({ ...obj, type: "values" })}
        setFilterState={() => null}
        value={value || ""}
      />
    );
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = isTextInputAllowed
      ? inputValue
      : numberWithCommas(removeCommas(inputValue));
    handleUpdate({ type: "values", value: formattedValue.toString() });
  };

  return (
    <input
      id={name}
      type="text"
      autoComplete="off"
      onChange={handleInputChange}
      value={isTextInputAllowed ? value ?? "" : numberWithCommas(value ?? "")}
      className={classNames(
        value
          ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
          : "focus:border-gray-300",
        "block w-full border-gray-300 rounded-md focus:ring-0  sm:text-sm"
      )}
    />
  );
};
