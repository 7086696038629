import React from "react";

export default function LoadingIndicator({ show }: { show: boolean }) {
  if (!show) return null;
  return (
    <div className="absolute inset-0 z-50 flex items-center justify-center w-full bg-gray-300 opacity-50">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fad"
        data-icon="spinner"
        className="text-gray-500 w-14 h-14 svg-inline--fa fa-spinner animate-spin "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <g className="fa-duotone-group">
          <path
            className="fa-secondary"
            fill="currentColor"
            d="M108.9 355.1c-26.5 0-48.01 21.51-48.01 48.01S82.39 451.1 108.9 451.1s47.99-21.49 47.99-47.99S135.4 355.1 108.9 355.1zM256 416c-26.5 0-48 21.5-48 48S229.5 512 256 512s48-21.5 48-48S282.5 416 256 416zM464 208C437.5 208 416 229.5 416 256s21.5 48 48 48S512 282.5 512 256S490.5 208 464 208zM403.1 355.1c-26.5 0-47.99 21.51-47.99 48.01S376.6 451.1 403.1 451.1s48.01-21.49 48.01-47.99S429.6 355.1 403.1 355.1zM403.1 156.9c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48S376.6 156.9 403.1 156.9z"
          />
          <path
            className="fa-primary"
            fill="currentColor"
            d="M48 208C21.5 208 0 229.5 0 256s21.5 48 48 48S96 282.5 96 256S74.5 208 48 208zM108.9 60.89c-26.5 0-48.01 21.49-48.01 47.99S82.39 156.9 108.9 156.9s47.99-21.51 47.99-48.01S135.4 60.89 108.9 60.89zM256 0C229.5 0 208 21.5 208 48S229.5 96 256 96s48-21.5 48-48S282.5 0 256 0z"
          />
        </g>
      </svg>
    </div>
  );
}
