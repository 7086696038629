import { get } from "@utils/api";
import type { queryType } from "@utils/api";
import { UserType } from "@model/contact";
import { SearchOptionKey } from "@components/forms/search-select";
import { UserRole } from "@model/app";

class ContactService {
  endpoint: string;
  queries: queryType;

  static async call(
    value: string,
    configs: { key: SearchOptionKey; includedItem: boolean }
  ): Promise<any> {
    const queries: queryType = {};
    let endpoint: string = "users";
    let userTypes = [
      UserRole.superAdmin,
      UserRole.user,
      UserRole.admin,
      UserRole.agent,
      UserRole.customerSuccess,
      UserRole.affiliate
    ]; // search all by default

    if (configs?.key === SearchOptionKey.agents) {
      userTypes = [
        UserRole.superAdmin,
        UserRole.admin,
        UserRole.agent,
        UserRole.customerSuccess,
        UserRole.affiliate
      ];
    }

    queries.search = value; // search value
    queries.filters = JSON.stringify({
      role: {
        or: userTypes // condition by type
      }
    });

    const includedItem = "includedItem" in configs ? configs.includedItem : true;

    let contactService = new ContactService(endpoint, queries);
    let users: UserType[] = await contactService.fetchContacts();

    // if search for internal-agents, filter user and external-agents
    if (configs?.key === SearchOptionKey.internalUsers || configs?.key === SearchOptionKey.agents) {
      users = users.filter((user: UserType) => {
        return !user.tags.includes("external-agent");
      });
    }

    return contactService.getContactOptions(users, includedItem);
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async fetchContacts(): Promise<any> {
    const path = this.endpoint;
    const queries = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data;
  }

  getContactOptions(contacts: UserType[], includedItem: boolean) {
    // transform payload for select option
    return contacts.map((item: any) => mapContactOption(item, includedItem));
  }
}

export const mapContactOption = (contact: UserType, includedItem?: boolean) => {
  return {
    label: `${contact.name} ${contact.email && `- ${contact.email}`} (${contact.role})`,
    value: contact.id,
    item: includedItem ? contact : null
  };
};
export default ContactService;
