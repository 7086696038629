import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserId } from "@state/selectors";
import NotificationService from "@services/notification/notification.service";
import useNotification from "@components/notification-controller/useNotification";

export default function useTaskNotification() {
  const userId = useSelector(selectUserId);
  const [fetchNotificationMenu] = useNotification();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  // have to watch userId because sw action before react sign-in
  useEffect(() => {
    const isRead = params.get("isRead");
    const whiteListToRemoveUrlQuery = ["isRead", "id"];
    if (id && userId) {
      if (isRead) {
        // update status on DB
        const formattedId = typeof id === "string" ? parseInt(id) : id;
        NotificationService.update(formattedId).catch((error) =>
          console.log("updateIsReadStatus error", error)
        );
        // fetch Notification Menu for get the lastest update
        fetchNotificationMenu(true).catch((error) =>
          console.log("fetchNotificationMenu error", error)
        );
      }
      whiteListToRemoveUrlQuery.forEach((key) => {
        if (params.has(key)) {
          params.delete(key);
        }
      });
      history.replace({
        search: params.toString()
      });
    }
  }, [id, userId]);
}
