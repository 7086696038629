import GetTasksService from "@services/tasks/get-tasks.service";
import { ITask, TASK_TYPE } from "@model/task";
import toast from "@components/toast";
import useModal from "@utils/hooks/useModal";
import { QueryConfigType } from "@views/components/data-table/index";
import useTaskNotification from "@pages/tasks/show/hooks/useTaskNotification";
import useLegacyEffect from "@utils/hooks/useLegacyEffect";

import { actionsSelector, useTaskStore } from "@views/components/task-controller/store";
import { useState } from "react";
import { TasksState } from "@pages/tasks/share";
import { useSelector } from "react-redux";
import { selectViewUserId } from "@state/selectors";

export default function useTaskLists(
  type: TASK_TYPE,
  tab: string,
  queries: QueryConfigType,
  positionType: "tab" | "page"
) {
  const [openModal, closeModal, onConfirm, , modalState] = useModal();
  const [state, setState] = useState<TasksState>({
    init: false,
    isLoading: false,
    items: [],
    total: 0
  });

  const viewUserId = useSelector(selectViewUserId);
  const { updateTask, deleteTask } = useTaskStore(actionsSelector);

  useTaskNotification();

  useLegacyEffect(() => {
    fetchData(0, queries.per_page);
  }, [tab, queries]);

  const fetchData = async (offset: number, per_page: number) => {
    setState((prev: TasksState) => ({ ...prev, isLoading: true }));
    const { data, meta } = await GetTasksService.call(
      {
        ...queries,
        offset,
        per_page,
        sort: "asc",
        sort_by: "scheduled_at",
        filters: { ...queries.filters, status: tab || "today", type },
        view_user_id: viewUserId
      },
      positionType === "tab" ? "task-relations" : "tasks"
    );

    let items = data;
    // merge data when loading more
    if (offset) items = [...state.items, ...data];

    setState((prev: TasksState) => ({
      ...prev,
      init: true,
      isLoading: false,
      items,
      total: meta?.total
    }));
  };

  const onUpdate = async (task: ITask) => {
    try {
      if (typeof task.id !== "number") return;
      await updateTask(task);
      fetchData(0, state.items?.length);
      toast.success("Data has been updated");
      if (modalState.key) closeModal();
    } catch (error) {
      console.log("handleUpdate error", error);
      toast.error("Please try again");
    }
  };

  const onDelete = async (id: number | string) => {
    try {
      const confirmed = await openModal(`delete_task`);
      if (!confirmed) return;
      await deleteTask(id);
      fetchData(0, state.items?.length);
      toast.success("Data has been deleted");
    } catch (error) {
      console.log("handleDelete error", error);
      toast.error("Please try again");
    }
  };

  const loadMore = () => fetchData(state.items?.length || 0, queries.per_page);

  const reload = () => fetchData(0, queries.per_page);

  return [state, loadMore, onDelete, onUpdate, onConfirm, closeModal, reload] as const;
}
