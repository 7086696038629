import { TASK_TYPE } from "@model/task";
import { UserType } from "@model/contact";
import { IDeal } from "@model/deal";
import { IProperty } from "@model/property";
import { IProject } from "@model/project";
import { CreateButton, WithIndicator } from "@components/index";
import { TrackingActivityModelKey } from "@components/activity-controller";
import ItemView from "./task.view";
import ShowMoreButton from "./task.more-btn";
import useNoteTask from "./useNoteTask";

interface INoteView {
  label: string;
  modelId: number;
  data: UserType | IDeal | IProperty | IProject;
  type: TASK_TYPE;
  modelKey: TrackingActivityModelKey;
}

const NoteView: React.FC<INoteView> = ({ label, modelId, modelKey, data, type }) => {
  const { ownerNotes, state, handleDelete, showMore, handleViewChange, createDraft } = useNoteTask(
    type,
    { label, refType: modelKey, data }
  );

  return (
    <>
      <div className="flex justify-end mt-4 mb-2">
        <CreateButton handleClick={createDraft} />
      </div>

      <WithIndicator isLoaded={!state.isLoading} isEmpty={state.items?.length === 0 && !ownerNotes}>
        <div>
          {/* show all items */}
          <div className="flow-root">
            {state.items?.map((item) => (
              <ItemView
                key={`type_${item.id}`}
                data={{ ...item }}
                editable={item.is_editable ?? false}
                handleChange={handleViewChange}
                handleDelete={handleDelete}
              />
            ))}

            {ownerNotes && (
              <div
                className="relative bg-white p-4 rounded-md text-sm"
                style={{ whiteSpace: "break-spaces" }}
              >
                {ownerNotes}
                <div className="absolute top-2 right-2 p-2 bg-orange-300 rounded-md">
                  <p className="text-xs text-white">Owner Notes</p>
                </div>
              </div>
            )}
          </div>
          <ShowMoreButton items={state.items} total={state.total} showMore={showMore} />
        </div>
      </WithIndicator>
    </>
  );
};

export default NoteView;
