import { UserRole } from "@model/app";

// Notification Alert: Real-time message from FCM
export { default as NotificationAlert } from "./components/notification.alert";
// Notification Menu: all notification from DB
export { default as NotificationMenu } from "./components/notification.menu";

export const notificationPermission = [
  UserRole.superAdmin,
  UserRole.admin,
  UserRole.agent,
  UserRole.user,
  UserRole.customerSuccess
];
