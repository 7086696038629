import { cn } from "@utils/helpers";
import React from "react";

type VariantClasses = {
  [key in
    | "primary"
    | "secondary"
    | "white"
    | "success"
    | "danger"
    | "warning"
    | "primary-outline"
    | "secondary-outline"
    | "success-outline"
    | "danger-outline"
    | "warning-outline"]: string;
};

const variantClasses: VariantClasses = {
  primary:
    "bg-primary-600 disabled:hover:bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 text-white",
  secondary:
    "bg-secondary-500 disabled:hover:bg-secondary-500 hover:bg-secondary-400 focus:ring-primary-300 text-white",
  white: "bg-white hover:bg-gray-100 focus:ring-gray-300 text-gray-700",
  success: "bg-green-700 hover:bg-green-600 focus:ring-green-500 text-white",
  danger: "bg-red-700 hover:bg-red-600 focus:ring-red-500 text-white",
  warning: "bg-yellow-500 hover:bg-yellow-400 focus:ring-yellow-300 text-white",
  "primary-outline":
    "text-primary-700 border-[1px] border-primary-300 bg-primary-100 hover:bg-primary-200 focus:ring-primary-500",
  "secondary-outline":
    "text-secondary-700 border-[1px] border-secondary-300 bg-white hover:bg-secondary-100 focus:ring-primary-500",
  "success-outline":
    "text-green-700 border-[1px] border-green-300 hover:bg-green-200 focus:ring-green-500",
  "danger-outline": "text-red-700 border-[1px] border-red-300 hover:bg-red-200 focus:ring-red-500",
  "warning-outline":
    "text-yellow-500 border-[1px] border-yellow-300 hover:bg-yellow-200 focus:ring-yellow-500"
};

const sizeClasses = {
  xs: "h-6",
  sm: "h-8",
  md: "h-10",
  lg: "h-12"
};

interface IProps {
  readonly variant?: keyof typeof variantClasses;
  readonly label: string;
  readonly className?: string;
  readonly onClick?: () => void;
  readonly disabled?: boolean;
  readonly size?: "xs" | "sm" | "md" | "lg";
  readonly fullWidth?: boolean;
  readonly buttonType?: "button" | "submit" | "reset";
  readonly children?: React.ReactNode;
  readonly startIcon?: React.ReactNode;
  readonly endIcon?: React.ReactNode;
}

export default function CustomButton(props: IProps) {
  const {
    variant = "primary",
    onClick,
    label,
    className,
    disabled,
    size,
    buttonType = "button",
    fullWidth,
    children,
    startIcon,
    endIcon
  } = props;
  const width = fullWidth ? "w-full" : "";
  const height = size ? sizeClasses[size] : "h-auto";
  const defaultClasses =
    "inline-flex justify-center items-center text-sm px-3 py-2.5 text-base sm:text-sm font-medium border border-transparent rounded-md rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed";

  return (
    <button
      type={buttonType}
      onClick={onClick}
      className={cn(
        defaultClasses,
        variantClasses[variant],
        height,
        width,
        className,
        startIcon || endIcon ? "gap-[10px]" : ""
      )}
      disabled={disabled}
    >
      {startIcon && <span>{startIcon}</span>}
      {children ?? label}
      {endIcon && <span>{endIcon}</span>}
    </button>
  );
}
