import { useState, useMemo } from "react";
import toast from "@components/toast";
import { ITask } from "@model/task";
import useModal from "@utils/hooks/useModal";
import UpdateTaskService from "@services/tasks/update-task.service";
import DeleteTaskService from "@services/tasks/delete-task.service";
import pluralize from "pluralize";

export default function useActivityTask(cb?: () => void) {
  const [itemSelected, setItemSelected] = useState<ITask | null>(null);
  const [openModal, closeModal, onConfirm, , modalState] = useModal();

  // modal title
  const title = useMemo(() => {
    if (!itemSelected) return "";

    let title: string[] = [];
    itemSelected.id !== "new" ? title.push("Update a") : title.push("Create a new");
    title.push(pluralize(itemSelected.type, 1));

    return title.join(" ");
  }, [itemSelected]);

  // delete task
  const handleDelete = async (id: number | string) => {
    try {
      const confirmed = await openModal("delete_task");
      if (!confirmed) return;
      await DeleteTaskService.call(id);
      if (cb) await cb();
      toast.success("Data has been deleted");
    } catch (error) {
      console.log(error);
      toast.error("Please try again");
    }
  };

  // cancel and close modal
  const handleCancel = () => {
    closeModal();
  };

  // open edit modal
  const handleEdit = (data: ITask) => {
    setItemSelected(data);
    openModal(`update_activity_task`);
  };

  // wrapper submit update
  const handleViewChange = (data: ITask) => {
    handleSubmit(data);
  };

  // submit update
  const handleSubmit = async (item: ITask) => {
    try {
      // update task
      if (typeof item?.id !== "number") return;
      await UpdateTaskService.call(item?.id, item);
      if (cb) await cb();
      toast.success("Data has been updated");
      // close model
      if (modalState.key) {
        closeModal();
      }
    } catch (error) {
      console.log("err", error);
      toast.error("Please try again");
    }
  };

  return [
    title,
    itemSelected,
    handleSubmit,
    handleDelete,
    handleCancel,
    handleViewChange,
    onConfirm,
    closeModal,
    handleEdit
  ] as const;
}
