import React from "react";
import ArrowLeft from "@components/icon/arrow-left";

interface IProps {
  onBack: () => void;
  label?: string;
}
export default function BackButton(props: IProps) {
  return (
    <button
      type="button"
      onClick={props.onBack}
      className="flex space-x-1 items-center px-0.5 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-500 max-w-[120px]"
    >
      <ArrowLeft className="h-3 w-3" />
      {props.label && <p className="">{props.label}</p>}
    </button>
  );
}
