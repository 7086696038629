import { deleteItem, queryType } from "@utils/api";

class DeleteDraftEmailService {
  endpoint: string;
  queries: queryType = {};

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  static async call(id: number) {
    const endpoint: string = `messages/${id}`;

    return await new DeleteDraftEmailService(endpoint).deleteEmail();
  }

  async deleteEmail() {
    return await deleteItem({
      path: this.endpoint,
      queries: this.queries
    });
  }
}

export default DeleteDraftEmailService;
