import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { devtools } from "zustand/middleware";
import { TASK_TYPE } from "@model/task";
import { RefType } from "..";

interface ITaskModalStoreState<T = any> {
  type: TASK_TYPE;
  referTo: RefType<T>;
}

interface ITaskModalStoreActions<T = any> {
  setStore: (store: ITaskModalStoreState<T>) => void;
  reset: () => void;
}

type ITaskModalStore<T = any> = ITaskModalStoreState<T> & ITaskModalStoreActions<T>;

const initialState = {
  type: TASK_TYPE.TASK,
  referTo: { data: {}, refType: "", label: "" }
};

const useTaskModalStore = create<ITaskModalStore>()(
  devtools((set) => ({
    ...initialState,

    setStore: (store) => set(store),
    reset: () => set(initialState)
  }))
);

const taskModalStateSelector = (state: ITaskModalStore) => ({
  type: state.type || TASK_TYPE.TASK,
  referTo: state.referTo
});

export type { ITaskModalStore };

export { shallow, useTaskModalStore, taskModalStateSelector };
