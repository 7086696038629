import { useState } from "react";
import EmailContent from "./email.content";
import { ReactComponent as AngleRight } from "@icons/angle-right-solid.svg";
import { ReactComponent as CaretDown } from "@icons/caret-down-solid.svg";
import classNames from "classnames";
import { EmailStatus, IMessage } from "@model/email";
import moment from "moment";
import { Popover } from "@headlessui/react";
import { useSelector } from "react-redux";
import { selectCanAccessEmail } from "@state/selectors";

export default function EmailCard({
  item,
  onSelectItem
}: {
  item: IMessage;
  onSelectItem: (item: IMessage) => void;
}) {
  const [isFullCard, setFullCard] = useState(false);

  return (
    <div
      className={classNames(
        "block px-2 py-4 bg-white shadow sm:rounded-md sm:pr-6 sm:pl-3 h-auto relative",
        {
          // '': isFullCard,
        }
      )}
    >
      <div className="flex">
        <div className="mr-2">
          <div
            className="flex flex-col items-center pt-1 space-y-2 cursor-pointer"
            onClick={() => setFullCard(!isFullCard)}
          >
            <AngleRight
              className={classNames(
                "relative  transition-all duration-400 text-gray-500 w-[12px] h-[12px]  ",
                {
                  "rotate-0": !isFullCard,
                  "rotate-90": isFullCard
                }
              )}
            />
          </div>
        </div>
        <div
          className={classNames("flex-1", {
            "overflow-hidden max-h-[110px]": !isFullCard
          })}
        >
          <div
            className="relative items-start flex-1 min-w-0 text-sm cursor-pointer sm:mb-2 sm:flex sm:justify-between"
            onClick={() => setFullCard(!isFullCard)}
          >
            {!isFullCard && (
              <div
                onClick={() => setFullCard(!isFullCard)}
                className="absolute left-0 right-0 top-[70px] h-[40px]"
                style={{
                  backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.4), rgb(255, 255, 255))"
                }}
              ></div>
            )}
            <p className="font-semibold text-purple-500 truncate">
              {item.from}
              {item.status === EmailStatus.draft && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-red-200 text-red-800">
                  Draft
                </span>
              )}
            </p>

            <div className="flex items-center text-gray-500 sm:mt-0 min-w-[150px] sm:justify-end text-xs">
              <p>{moment(item.created_at).format("lll")}</p>
            </div>
          </div>

          {!isFullCard ? (
            <MessageSubTitle isFullCard={isFullCard} item={item} onSelectItem={onSelectItem} />
          ) : (
            <div>
              <MessageSubTitle isFullCard={isFullCard} item={item} onSelectItem={onSelectItem} />
              <EmailContent isFullCard={isFullCard} item={item} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MessageSubTitle = ({
  isFullCard,
  item,
  onSelectItem
}: {
  isFullCard: boolean;
  item: IMessage;
  onSelectItem: (item: IMessage) => void;
}) => {
  const canAccessEmail = useSelector(selectCanAccessEmail);

  return isFullCard ? (
    <div className="flex flex-col items-start justify-between text-gray-500 sm:flex-row">
      {/* left */}
      <div className="flex space-x-2">
        <p className="flex-shrink-0 text-xs font-normal ">
          To: <span className="text-gray-600">{item.to}</span>
        </p>
        <Popover className="relative ">
          <Popover.Button>
            <CaretDown className="w-2" />
          </Popover.Button>

          <Popover.Panel className="absolute z-10 w-auto max-w-md transform translate-x-[-150px] md:translate-x-0 ">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative p-5 text-xs bg-white">
                <table>
                  <tr>
                    <td valign="top" className="pb-1 pr-2 text-right text-gray-500">
                      subject:
                    </td>
                    <td className="pb-2 text-gray-600 whitespace-normal">{item.subject}</td>
                  </tr>
                  <tr>
                    <td valign="top" className="pb-1 pr-2 text-right text-gray-500">
                      from:
                    </td>
                    <td className="pb-2 text-gray-600 whitespace-nowrap">{item.from}</td>
                  </tr>
                  <tr>
                    <td valign="top" className="pb-1 pr-2 text-right text-gray-500">
                      to:
                    </td>
                    <td className="pb-2 text-gray-600 whitespace-nowrap">{item.to}</td>
                  </tr>
                  {item.cc && (
                    <tr>
                      <td valign="top" className="pb-1 pr-2 text-right text-gray-500">
                        cc:
                      </td>
                      <td className="pb-2 text-gray-600 whitespace-nowrap">{item.cc}</td>
                    </tr>
                  )}
                  {item.bcc && (
                    <tr>
                      <td valign="top" className="pb-1 pr-2 text-right text-gray-500">
                        bcc:
                      </td>
                      <td className="pb-2 text-gray-600 whitespace-nowrap">{item.bcc}</td>
                    </tr>
                  )}
                  <tr>
                    <td valign="top" className="pb-1 pr-2 text-right text-gray-500">
                      date:
                    </td>
                    <td className="pb-2 text-gray-600 whitespace-nowrap">
                      {moment(item.created_at).format("llll")}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </Popover.Panel>
        </Popover>
      </div>
      {/* right */}
      <div className="flex space-x-3 text-xs text-blue-400 ">
        {item.status === EmailStatus.draft ? (
          <p className="cursor-pointer hover:text-blue-500" onClick={() => onSelectItem(item)}>
            Edit draft
          </p>
        ) : (
          // todo remove condition after email bug fix
          canAccessEmail && (
            <p
              className="cursor-pointer hover:text-blue-500"
              onClick={() =>
                onSelectItem({ id: 0, status: EmailStatus.draft, replyTo: { ...item } })
              }
            >
              Reply
            </p>
          )
        )}
        {/* <p>Delete</p> */}
      </div>
    </div>
  ) : (
    // preview email content
    <>
      {item.snippet && (
        <div
          className="mt-2 text-xs text-gray-500 md:mt-0"
          dangerouslySetInnerHTML={{ __html: item.snippet }}
        ></div>
      )}
      {/* {
        !!item?.attachments?.length && ((
          <div className="flex flex-col mt-3 space-y-1 text-xs text-gray-500">
            {
              item?.attachments?.map((a) => (
                <div className="flex items-center space-x-1">
                  <Paperclip className='w-[10px] h-[10px]' />
                  <label>{a.file_name}</label>
                </div>
              ))
            }
          </div>
        ))
      } */}
    </>
  );
};
