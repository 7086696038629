type appConfigType = {
  projectName: string;
  domain: string;
  websiteDomain: string;
  apiEndPoint: string;
};
type layoutConfigType = {
  sidebarExpanded: boolean;
};

// let myStorage = localStorage.getItem('app')

// myStorage = myStorage ? JSON.parse(myStorage) : {}

let { origin, hostname } = window.location;
let apiEndPoint = origin;
let websiteDomain = "https://lazudi.com";
if (process.env.NODE_ENV === "development") {
  // localhost
  websiteDomain = origin.replace("3000", "80");
  origin = origin.replace("3000", "8888");
  origin = origin.replace("https", "http");

  apiEndPoint = `${origin}/api`;
} else {
  apiEndPoint = "https://api.lazudi.com/api";
  if (hostname.startsWith("staging")) {
    // for staging
    apiEndPoint = "https://api.staging.lazudi.com/api";
    websiteDomain = "https://staging.lazudi.com";
  }
}

export const appConfig: appConfigType = {
  projectName: "Lazudi Agent Dashboard",
  domain: origin,
  websiteDomain,
  apiEndPoint
};

export const layoutConfig: layoutConfigType = {
  sidebarExpanded: false
};

export const localeKeys = ["en", "th", "ar", "de", "fr", "es", "ch", "no", "ru"];
export const attributeKeys = ["name", "description"];
