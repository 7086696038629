import React, { useState } from "react";
import { StylesConfig } from "react-select";
import Creatable from "react-select/creatable";
import { Controller } from "react-hook-form";
import { isValidEmail } from "@utils/helpers";

const customStyles: StylesConfig = {
  multiValueLabel: (provided: any, { data, isFocused }: any) => {
    const isValid = isValidEmail(data.value);
    return {
      ...provided,
      backgroundColor: isValid ? provided.backgroundColor : "#ffeff1",
      color: isValid ? provided.color : "#ff586c"
    };
  },
  multiValueRemove: (provided: any, { data, isFocused }: any) => {
    const isValid = isValidEmail(data.value);
    return {
      ...provided,
      backgroundColor: isValid ? provided.backgroundColor : "#ffeff1",
      color: isValid ? provided.color : "#ff586c",
      ":hover": {
        backgroundColor: isValid ? provided.backgroundColor : "#ff586c",
        color: isValid ? provided.color : "#ffeff1"
      }
    };
  }
};

interface IEmailChip {
  name: string;
  control: any;
  required: boolean;
}

const EmailChip = ({ name, control, required }: IEmailChip) => {
  const [options, setOptions] = useState<any>([]);

  const handleInputChange = (inputValue: any, { action }: any) => {
    if (action === "create-option" && isValidEmail(inputValue)) {
      setOptions((prevState: any) => [...prevState, { value: inputValue, label: inputValue }]);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        rules={{ required: required }}
        render={({ field }) => (
          <Creatable
            {...field}
            isMulti
            options={options}
            onInputChange={handleInputChange}
            placeholder=""
            noOptionsMessage={() => "Type to enter email"}
            formatCreateLabel={(inputValue) => `Add "${inputValue}" to email list`}
            styles={customStyles}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          />
        )}
      />
    </>
  );
};

export default EmailChip;
