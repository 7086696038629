import { useDispatch } from "react-redux";
import CustomButton from "@components/buttons/button.custom";
import googlePermissionImg from "@images/google-permission-1.png";
import googlePermissionImg2 from "@images/google-permission-2.png";
import { logout } from "@state/actions/action.creators";
import useModal from "@utils/hooks/useModal";

export default function RecommendationForm() {
  const dispatch = useDispatch();
  const [, closeModal] = useModal();

  // const handleKeepLoggedIn = () => {
  //   closeModal();
  // };

  const handleLogOut = () => {
    closeModal();
    logout(dispatch);
  };

  return (
    <div className="flex flex-col items-center justify-center w-[90vw] py-4 xl:w-[70vw] max-w-full">
      {/* description */}
      <span>
        It seems that you have yet to select all of the necessary Google permission scopes. These
        scopes will permit the app to access your information. Before proceeding with the login,
        please take a moment to review and carefully select the appropriate boxes for the required
        permission scopes.
      </span>

      {/* images */}
      <div className="flex flex-col items-center justify-center w-full py-4 xl:flex-row">
        <img src={googlePermissionImg} alt="Google permission 1" />
        <div className="p-2">
          <span className="hidden font-bold xl:block">{"→"}</span>
          <span className="font-bold xl:hidden">{"↓"}</span>
        </div>
        <img src={googlePermissionImg2} alt="Google permission 2" />
      </div>

      {/* buttons */}
      <div className="flex flex-row justify-between w-full gap-6 py-4 xl:w-3/4">
        {/* <CustomButton
          label="Keep logged in"
          fullWidth
          variant="primary"
          onClick={handleKeepLoggedIn}
        /> */}
        <CustomButton label="Sign out" fullWidth variant="warning" onClick={handleLogOut} />
      </div>
    </div>
  );
}
