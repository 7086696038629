import { useEffect, useState } from "react";
import { IFilterProps } from "../table.filter";
import Select from "react-select";
import FeatureService from "@services/features/feature.service";
import { classNames } from "@utils/helpers";
import { getCustomSelectStyles } from "@utils/react-select.helpers";

export default function PropertyFeatureGroup(props: IFilterProps & { filterState: any }) {
  const [featureOptions, setFeatureOptions] = useState([]);
  const [featureDropdownOptions, setFeatureDropdownOptions] = useState([]);

  const fetchData = async (type: string, callBack: (item: any) => void) => {
    const data = await FeatureService.call(
      { type, table: "property" },
      {
        render: (item: any) => ({ value: item.id, label: item.content, ...item })
      }
    );
    // console.log(data)
    callBack(data);
  };

  useEffect(() => {
    fetchData("checkbox", setFeatureOptions);
    fetchData("dropdown", setFeatureDropdownOptions);
  }, []);

  const handleDropdownChange = (items: []) => {
    let featureSelected: any = null;

    if (items?.length) {
      featureSelected = { and: items, relationship: "features" };
    }
    // console.log(featureSelected);
    props.setFilterState((prev: {}) => ({ ...prev, feature_id: featureSelected }));
  };

  const featureOptionsValue =
    featureOptions?.filter((f: any) => props.filterState?.feature_id?.and?.includes(f.value)) || [];
  const currentFeatures = props.filterState?.feature_id?.and || [];
  // console.log(currentFeatures.find((f: any) => typeof f !== 'number'));
  return (
    <div className="p-2 space-y-2 border rounded-md">
      <Select
        className="w-full text-[13px]"
        key="features"
        styles={getCustomSelectStyles(!!featureOptionsValue.length, true)}
        options={featureOptions}
        placeholder="Search features..."
        value={featureOptionsValue}
        onChange={(e: any) => {
          let features = e?.map((item: any) => item.value) || [];
          // const currentFeatures = props.filterState?.feature_id?.and || []
          // filter out only checkbox feature and replace by the new features
          features = [...currentFeatures.filter((f: any) => typeof f !== "number"), ...features];
          return handleDropdownChange(features);
        }}
        closeMenuOnSelect={false}
        isClearable={true}
        isMulti
      />
      {featureDropdownOptions.map(
        (featureDropdown: { value: string; label: string; values: [] }) => {
          let defaultValue = "";
          let objectValue = currentFeatures.find((f: any) => {
            return typeof f !== "number" && Object.keys(f)[0] === featureDropdown.value.toString();
          });
          if (objectValue) defaultValue = objectValue[featureDropdown.value]?.feature_value_id;
          return (
            <div className="">
              <label
                htmlFor={featureDropdown.value}
                className="flex mb-0 font-medium text-gray-700 uppercase"
              >
                {featureDropdown.label}
                {defaultValue && <div className="w-1 h-1 ml-1 bg-red-400 rounded-full"></div>}
              </label>
              <select
                id={featureDropdown.value}
                className={classNames(
                  defaultValue
                    ? "border-1 border-purple-500 text-purple-700 focus:border-purple-500"
                    : "focus:border-gray-300",
                  "block w-full px-3 border-gray-300 focus:ring-0 py-2 pl-3 pr-10 text-sm  rounded-md sm:text-xs h-[38px]"
                )}
                value={defaultValue}
                onChange={(e: any) => {
                  let value = null;
                  if (e.target.value)
                    value = { [e.target.id]: { feature_value_id: e.target.value } };

                  // find and remove the old value
                  let features = currentFeatures.filter((f: any) => {
                    return (
                      typeof f === "number" ||
                      (typeof f !== "number" && Object.keys(f)[0] !== e.target.id)
                    );
                  });

                  if (value) {
                    // some feature is selected then set value back
                    features = [...features, { ...value }];
                  }
                  return handleDropdownChange(features);
                }}
              >
                <option value="">Any</option>
                {featureDropdown.values?.map((option: { content: string; id: number }) => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.content}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        }
      )}
    </div>
  );
}
