import Router from "./router";
import useInitApp from "@utils/hooks/useInitApp";

const App = () => {
  useInitApp();

  return (
    <>
      <Router />
    </>
  );
};

export default App;
