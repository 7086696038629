import { getCustomSelectStyles } from "@utils/react-select.helpers";
import { IFilterProps } from "../table.filter";
import { SearchSelect } from "@components/forms/search-select";

export default function AsyncAutoComplete(props: IFilterProps) {
  const defaultValue = props?.value?.textValue ? props?.value?.textValue : null;
  return (
    <SearchSelect
      configs={{ ...props?.config, id: props.id, key: props?.config.endPoint, includedItem: false }}
      isClearable
      key={props.id}
      className="w-full text-[13px]"
      styles={getCustomSelectStyles(props.value, true)}
      value={defaultValue}
      noOptionsMessage={() => "Start typing"}
      onChange={(e: any) => {
        let value: any = null;
        if (e?.value) value = { is: [e.value], textValue: e };
        if (props.config.relationship) value.relationship = props.config.relationship;
        // props.setFilterState((prev: {}) => ({ ...prev, [props.id]: getFilterValue(props, value) }));
        props.setFilterState((prev: {}) => ({ ...prev, [props.id]: value }));
      }}
    />
  );
}
