import { Action } from "../actions/action.index";
import { ActionType } from "../actions/action.types";
import type { UserType } from "@model/contact";

type authType = {
  config: object | null;
  userData: UserType | null;
};

const initialState = {
  config: null,
  userData: null
};

const reducer = (state: authType = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.INITIALIZE:
      return {
        ...state,
        userData: action.payload
      };
    case ActionType.UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        userData: state.userData && {
          ...state.userData,
          profile_image: action.payload
        }
      };
    default:
      return state;
  }
};

export default reducer;
