import { IFilterProps } from "../table.filter";
import { SearchSelect } from "@components/forms/search-select";
import { countryOptions } from "@model/app";
import { getCustomSelectStyles } from "@utils/react-select.helpers";
import Select from "react-select";

// const handleLoadOptions = debounce(async (jsonValues: string, keySearch) => {
//   return await LocationService.call(jsonValues, {
//     keySearch,
//     render: (item: any) => ({ value: item[keySearch], label: item[keySearch] })
//   })
// }, 500);

export default function LocationGroup(props: IFilterProps & { filterState: any }) {
  // const loadOptions = (value: string, key: string) => {
  //   const { region_2, region_3 } = props.filterState
  //   switch (key) {
  //     case 'region_2':
  //       return handleLoadOptions(JSON.stringify({ 'region_2': value }), 'region_2')
  //     case 'region_3':
  //       return handleLoadOptions(JSON.stringify({ 'region_2': region_2.textValue.value, 'region_3': value }), 'region_3')
  //     case 'locality':
  //       return handleLoadOptions(JSON.stringify({ 'region_2': region_2.textValue.value, 'region_3': region_3.textValue.value, 'locality': value }), 'locality')
  //   }
  // }

  return (
    <div className="p-2 space-y-2 border rounded-md">
      <div>
        <label htmlFor="country_id" className="flex mb-0 font-medium text-gray-700 uppercase">
          Country
          {props.filterState.country_id && (
            <div className="w-1 h-1 ml-1 bg-red-400 rounded-full"></div>
          )}
        </label>
        <Select
          // configs={{ key: 'country_id', ...props.filterState }}
          className="w-full text-[13px]"
          key="country_id"
          styles={getCustomSelectStyles(props.filterState["country_id"]?.textValue || "", true)}
          value={
            props.filterState["country_id"]?.textValue
              ? props.filterState["country_id"]?.textValue
              : null
          }
          onChange={(e: any) => {
            let value: any = null;
            if (e?.value) value = { is: [e.value], relationship: "locations", textValue: e };
            props.setFilterState((prev: {}) => ({
              ...prev,
              country_id: value,
              region_2: null,
              region_3: null,
              locality: null
            }));
          }}
          options={countryOptions}
        />
      </div>

      {props.filterState?.country_id && (
        <div>
          <label htmlFor="region_2" className="flex mb-0 font-medium text-gray-700 uppercase">
            Province
            {props.filterState.region_2 && (
              <div className="w-1 h-1 ml-1 bg-red-400 rounded-full"></div>
            )}
          </label>
          <SearchSelect
            configs={{ key: "region_2", ...props.filterState }}
            className="w-full text-[13px]"
            key="region_2"
            styles={getCustomSelectStyles(props.filterState["region_2"]?.textValue || "", true)}
            value={
              props.filterState["region_2"]?.textValue
                ? props.filterState["region_2"]?.textValue
                : null
            }
            noOptionsMessage={() => "Start typing"}
            onChange={(e: any) => {
              let value: any = null;
              if (e?.value) value = { is: [e.value], relationship: "locations", textValue: e };
              props.setFilterState((prev: {}) => ({
                ...prev,
                region_2: value,
                region_3: null,
                locality: null
              }));
            }}
            isClearable={true}
          />
        </div>
      )}

      {props.filterState?.region_2 && (
        <div>
          <label htmlFor="region_3" className="flex mb-0 font-medium text-gray-700 uppercase">
            City
            {props.filterState.region_3 && (
              <div className="w-1 h-1 ml-1 bg-red-400 rounded-full"></div>
            )}
          </label>
          <SearchSelect
            configs={{ key: "region_3", ...props.filterState }}
            className="w-full text-[13px]"
            key="region_3"
            styles={getCustomSelectStyles(props.filterState["region_3"]?.textValue || "", true)}
            value={
              props.filterState["region_3"]?.textValue
                ? props.filterState["region_3"]?.textValue
                : null
            }
            noOptionsMessage={() => "Start typing"}
            onChange={(e: any) => {
              let value: any = null;
              if (e?.value) value = { is: [e.value], relationship: "locations", textValue: e };
              props.setFilterState((prev: {}) => ({ ...prev, region_3: value, locality: null }));
            }}
            isClearable={true}
          />
        </div>
      )}

      {props.filterState?.region_2 && props.filterState?.region_3 && (
        <div>
          <label htmlFor="locality" className="flex mb-0 font-medium text-gray-700 uppercase">
            Locality
            {props.filterState.locality && (
              <div className="w-1 h-1 ml-1 bg-red-400 rounded-full"></div>
            )}
          </label>
          <SearchSelect
            configs={{ key: "locality", ...props.filterState }}
            className="w-full text-[13px]"
            key="locality"
            styles={getCustomSelectStyles(props.filterState["locality"]?.textValue || "", true)}
            value={
              props.filterState["locality"]?.textValue
                ? props.filterState["locality"]?.textValue
                : null
            }
            noOptionsMessage={() => "Start typing"}
            onChange={(e: any) => {
              let value: any = null;
              if (e?.value) value = { is: [e.value], relationship: "locations", textValue: e };
              props.setFilterState((prev: {}) => ({ ...prev, locality: value }));
            }}
            isClearable={true}
          />
        </div>
      )}
    </div>
  );
}
