import moment, { Moment } from "moment-timezone";
import {
  countriesAvailable,
  CountryCode,
  propertyRentalUnit,
  propertySizeUnit,
  selectOptionType,
  DEFAULT_TIMEZONE,
  AppStorageType,
  PreferenceType,
  ReturnItemType,
  RoutePath,
  SuperAdminType,
  FormatNumberType
} from "@model/app";
import { parsePhoneNumberFromString, isValidPhoneNumber } from "libphonenumber-js/min";
import { ViewUserType } from "@state/reducers/reducer.app";
import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function getDefaultRoute(user: any): string {
  if (!user) return RoutePath.signIn;

  if (user.role === "contributor") return "/blog/articles";

  return "/properties";
}

export function classNames(...classes: any): string {
  return classes.filter(Boolean).join(" ");
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isObject(object: {}): boolean {
  return object !== null && typeof object === "object";
}

export function deepEqual(object1: any, object2: any): boolean {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);

    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export function shallowEqual(object1: any, object2: any): number {
  let count = 0;

  const keys1 = Object.keys(object1); // primary
  // const keys2 = Object.keys(object2);

  for (const key of keys1) {
    if (typeof object1[key] === "object") continue;

    const obj_1_value = object1[key] ? object1[key] : null;
    const obj_2_value = object2[key] ? object2[key] : null;

    if (obj_1_value !== obj_2_value) {
      count += 1;
    }
  }

  return count;
}

export function parseQueryString(query: string): string {
  if (!query) return "";

  const vars = query.split("&");
  const query_string: any = {};

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);

    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      query_string[key] = [query_string[key], decodeURIComponent(value)];
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }

  return query_string;
}

export function transformQueryString(q: any): string {
  const textStr = Object.keys(q)
    .filter((i) => !!q[i])
    .map((key) => `${key}=${encodeURIComponent(q[key])}`)
    .join("&");

  return textStr ? `?${textStr}` : "";
}

export function getTrans(objArr: { language: string }[], lang: string) {
  return objArr.find((i) => i.language === lang);
}

export function getContentTrans(
  objArr: { language: string; ref_field: string; content: string }[],
  lang: string,
  field: string | null = null
): string {
  return (
    objArr.filter((i) => i.language === lang && (!field || i.ref_field === field))[0]?.content || ""
  );
}

export function formatNumberWithSuffix(value: number, precision: number = 1): string {
  if (!value) return "0";

  const billion = 1_000_000_000;
  const million = 1_000_000;
  const thousand = 1_000;

  // Handle negative numbers by formatting their absolute value and prefixing with a minus sign
  const prefix = value < 0 ? "-" : "";
  const absoluteValue = Math.abs(value);

  // Format the number based on its size
  let formattedNumber: string;
  if (absoluteValue >= billion) {
    formattedNumber = (absoluteValue / billion).toFixed(precision) + "B";
  } else if (absoluteValue >= million) {
    formattedNumber = (absoluteValue / million).toFixed(precision) + "M";
  } else if (absoluteValue >= thousand) {
    formattedNumber = (absoluteValue / thousand).toFixed(precision) + "K";
  } else {
    // Handle small numbers including zero directly without suffix
    formattedNumber = absoluteValue.toString();
  }

  return prefix + formattedNumber;
}

export function numberWithCommas(number: string, returnZero = false): string {
  if (!number) {
    if (returnZero) return "0";
    return "";
  }

  number += "";

  let x = number.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  let rgx = /(\d+)(\d{3})/;

  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1,$2`);
  }

  return x1 + x2;
}

export function removeCommas(value: string | null | undefined): string {
  if (!value) return "";
  return String(value).replace(/[^0-9\\.]+/g, "");
}

export function removeHtmlTags(str: string): string {
  if (str === null || str === "") return "";

  str = str.toString();
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function trimmedString(str: string, length: number = 150): string {
  return str.length > length ? str.substring(0, length - 3) + "..." : str;
}

export function permissionScope(permission: string[], role: string): boolean {
  return permission.includes("*") || (!permission.includes("*") && permission.includes(role));
}

export function isLiveFeature(key: string | null): boolean {
  if (!key) return true;

  const keyFeatures: string[] = [
    // 'deal', // menu bar
    // 'property-deals', // sidebard on property page
    // 'task', // tab
    // 'file', // tab
    // 'tasks', // menu
  ];
  // show keyFeatures only on staging
  return !(keyFeatures.includes(key) && isProduction());
}

export function isProduction(): boolean {
  // return true;
  const location = window?.location || null;
  return location && !["staging", "localhost"].some((word) => location.hostname.startsWith(word));
}

export function formatDate(date: string, pattern: string = "DD/MM/YYYY"): string {
  return moment(date).format(pattern);
}

export const isIntlPhone = (phone: string) => {
  return phone.charAt(0) === "+";
};

export const formatIntlWithDialCode = (phone: string, dialCode: string) => {
  if (isIntlPhone(phone)) return phone;

  if (phone.charAt(0) === "0") {
    phone = phone.substring(1);
  }

  return `${dialCode}${phone}`;
};

export const removeEmptyObjectValues = (payload: object, scopeOfKeysToFilter: string[]) => {
  return Object.entries(payload)
    .filter(([key, value]) => {
      return value && scopeOfKeysToFilter.includes(key);
    })
    .reduce((accum: any, [k, v]) => {
      accum[k] = v;
      return accum;
    }, {});
};

export const getTimezoneOffset = (timeZone: string = DEFAULT_TIMEZONE): number => {
  return moment().tz(timeZone).utcOffset();
};

export const getGMTOffset = (timeZone: string = DEFAULT_TIMEZONE): string => {
  return moment().tz(timeZone).format("Z");
};

export const getCountryTimezone = (country_id: CountryCode = CountryCode.TH): string => {
  const countrySelected = countriesAvailable.find((c) => c.country_id === country_id);
  return countrySelected?.timeZone || DEFAULT_TIMEZONE;
};

export const getCurrency = (
  country_id: CountryCode | null = CountryCode.TH,
  type: "currency" | "currencySymbol" = "currencySymbol"
) => {
  const countrySelected = countriesAvailable.find((c) => c.country_id === country_id); // default
  if (countrySelected) return countrySelected[type];

  return "";
};

export const getCurrencySymbol = (currency: string) => {
  const countrySelected = countriesAvailable.find((c) => c.currency === currency);
  if (!countrySelected) return "";
  return countrySelected.currencySymbol;
};

export const serializeFilters = (filters: { [K in any]: any }) => {
  let newObj = {} as { [K in any]: { [is: string]: any } };
  Object.keys(filters)?.map((k) => (newObj[k] = { is: [filters[k]] }));
  return JSON.stringify(newObj); // '{"key":{"is":[id]}}'
};

export function setCookie(name: string, value: string, days?: number): void {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()};`;
  }
  document.cookie = `${name}=${value}; ${expires} path=/; domain=.lazudi.com`;
}

// export function getCookie(name: string): string | null {
//   let nameEQ = name + "=";
//   let ca = document.cookie.split(';');
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == ' ') c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// }

export function eraseCookie(name: string): void {
  document.cookie = `${name}=; path=/; domain=.lazudi.com;`;
}

export function convertPropertySize(
  value: number,
  currentUnit: propertySizeUnit,
  targetUnit: propertySizeUnit
): number {
  // SQM TO SQF
  const SQM_TO_SQF = 10.764;
  if (currentUnit === propertySizeUnit.SQM && targetUnit === propertySizeUnit.SQF) {
    return parseFloat((value * SQM_TO_SQF).toFixed(2));
  } else if (currentUnit === propertySizeUnit.SQF && targetUnit === propertySizeUnit.SQM) {
    // SQF TO SQM
    return parseFloat((value / SQM_TO_SQF).toFixed(4));
  }
  return value;
}

export function convertPropertyRentalPrice(
  value: number,
  currentUnit: propertyRentalUnit,
  targetUnit: propertyRentalUnit
): number {
  if (currentUnit === propertyRentalUnit.Monthly && targetUnit === propertyRentalUnit.Yearly) {
    // monthly to yearly
    //   return value * 12 // value * 12
    // } else if (currentUnit === propertyRentalUnit.Yearly && targetUnit === propertyRentalUnit.Monthly) {
    //   // yearly to monthly
    //   return (value / 12) // value / 12
    // }
    return parseFloat((value * 12).toFixed(2)); // value * 12
  } else if (
    currentUnit === propertyRentalUnit.Yearly &&
    targetUnit === propertyRentalUnit.Monthly
  ) {
    // yearly to monthly
    return value / 12; // value / 12
  }

  return value;
}

// copy text to clipboard
export function copyToClipboard(text: string, onCopied?: () => void) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      if (onCopied) onCopied();
    })
    .catch((e) => console.error(e));
}

export function snakeToCamel(str: string | null) {
  if (!str) {
    return "";
  }
  return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function camelToSnake(str: string | null) {
  if (!str) {
    return "";
  }
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

// helper to download blob file
export function downloadFile(filename: string, file: Blob) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const filterEmptyOptionIfExist = (value: string, options: selectOptionType[]) => {
  if (!value) return options.filter((option) => option.value);

  return options;
};

export const isEnableAutoLogin = (): boolean => {
  return process.env.REACT_APP_AUTO_LOGIN_MODE
    ? String(process.env.REACT_APP_AUTO_LOGIN_MODE).toLocaleLowerCase() === "true"
    : false;
};

export const updateLocalStoragePreferences = (preferences: PreferenceType) => {
  const item = localStorage.getItem("app") || "{}";
  const obj: AppStorageType = JSON.parse(item);
  const app: AppStorageType = { ...obj, preferences };
  localStorage.setItem("app", JSON.stringify(app));
};

export const updateLocalStorageSuperAdmin = (superAdmin: SuperAdminType) => {
  const item = localStorage.getItem("app") || "{}";
  const obj: AppStorageType = JSON.parse(item);
  const app: AppStorageType = { ...obj, superAdmin };
  localStorage.setItem("app", JSON.stringify(app));
};

export const getViewUser = (): ViewUserType => {
  const { superAdmin } = JSON.parse(localStorage.getItem("app") || "{}");
  return superAdmin?.view_user;
};

export const getTimeZoneMoment = (inp?: moment.MomentInput): Moment => {
  const { preferences } = JSON.parse(localStorage.getItem("app") || "{}");
  const defaultCountry = preferences?.defaultCountry;
  const timeZone = getCountryTimezone(defaultCountry);
  return moment.tz(inp, timeZone);
};

export const getFlattenOptions = (options: ReturnItemType[]) => {
  if (!options) return [];

  const flattenedOptions: ReturnItemType[] = [];

  options.forEach((option: any) => {
    if (option.options) {
      flattenedOptions.push(...getFlattenOptions(option.options));
    } else {
      flattenedOptions.push(option);
    }
  });

  return flattenedOptions;
};

export const hasCountryCode = (phone: string) => /^\+\d/.test(phone);

export const isValidPhoneNumberString = (phone: string) => {
  return hasCountryCode(phone)
    ? isValidPhoneNumber(phone)
    : parsePhoneNumberFromString(phone, "TH")?.isValid() ?? false;
};

export const getDateFromNow = (date: Date | string) => {
  return moment(date).isValid() ? moment(date).fromNow() : "-";
};

export const isValidEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const secondsToHMS = (seconds: number, delimiter: string = "."): string => {
  const pad = (num: number): string => String(num).padStart(2, "0");

  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.floor((seconds % 3600) / 60);
  const secs: number = seconds % 60;

  return `${pad(hours)}${delimiter}${pad(minutes)}${delimiter}${pad(secs)}`;
};

export const formatNumberText = (total: number, type?: FormatNumberType): string => {
  switch (type) {
    case "percentage":
      return `${(total ?? 0).toFixed(2)}%`;
    case "duration":
      return secondsToHMS(Math.floor(total));
    case "shorten":
      return formatNumberWithSuffix(total);
    default:
      return numberWithCommas(total?.toFixed(0));
  }
};


export const groupAndSplitArray = <T>(array: T[], groupSizes: number[])  => {
  let result = [];
  let startIndex = 0;

  for (let size of groupSizes) {
    if (startIndex < array.length) {
      result.push(array.slice(startIndex, startIndex + size));
      startIndex += size;
    }
  }

  if (startIndex < array.length) {
    result.push(array.slice(startIndex));
  }

  return result;
}
