export enum ActionType {
  INITIALIZE = "INIT_APP",
  ON_FORM_CHANGE = "ON_FORM_CHANGE",
  ON_FORM_SUBMIT = "ON_FORM_SUBMIT",
  ON_FORM_ERRORS = "ON_FORM_ERRORS",
  ON_FORM_SUBMIT_COMPLETED = "ON_FORM_SUBMIT_COMPLETED",
  ON_FORM_RESET = "ON_FORM_RESET",
  ON_FORM_BTN_CLICK = "ON_FORM_BTN_CLICK",

  TOGGLE_MODAL = "TOGGLE_MODAL",
  TOGGLE_GLOBAL_EMAIL = "TOGGLE_GLOBAL_EMAIL",
  UPDATE_PREFERENCE = "UPDATE_PREFERENCE",
  UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE",
  UPDATE_INPUT_OPTIONS = "UPDATE_INPUT_OPTIONS",
  // SUPER ADMIN
  UPDATE_VIEW_USER = "UPDATE_VIEW_USER",
  UPDATE_IS_SUPER_ADMIN = "UPDATE_IS_SUPER_ADMIN",
  UPDATE_IS_SYSTEM_SUPER_ADMIN = "UPDATE_IS_SYSTEM_SUPER_ADMIN",

  // Notifications
  UPDATE_NOTIFICATION_MENU = "UPDATE_NOTIFICATION_MENU",
  SET_NOTIFICATION_ALERT = "SET_NOTIFICATION_ALERT",
  SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT",
  SET_NOTIFICATION_MENU = "SET_NOTIFICATION_MENU",
  RESET_NOTIFICATION_MENU = "RESET_NOTIFICATION_MENU"
}
