import { IEmailTemplate } from "@model/email";
import { ConfirmDialog, Modal, WithIndicator } from "@views/components";
import React, { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BackButton, CreateTemplateButton } from "@components/buttons";
import ChevronDown from "../../../icon/chevron-down";
import FolderPlus from "@components/icon/folder-plus";
import EmailTemplateListItem from "@components/email-controller/templates/listing/template.item";
import useEmailTemplateList from "@components/email-controller/useEmailTemplateList";
import { EmailTemplateContentType } from "@components/email-controller";

const EmailTemplateListing = ({
  currentTemplate,
  activeTemplate,
  onClickUpdate,
  setParentFolderId,
  editable = true
}: {
  currentTemplate?: EmailTemplateContentType;
  activeTemplate: number | false;
  onClickUpdate: (item: IEmailTemplate) => void;
  setParentFolderId?: (id: number) => void;
  editable?: boolean;
}) => {
  const {
    templates,
    handleRemove,
    closeModal,
    onConfirm,
    REMOVE_TEMPLATE_MODAL_KEY,
    folderId,
    folderName,
    isLoading,
    handleFolderChange,
    handleBackFolder,
    showCreateForm,
    showCreateFolder,
    createOrUpdateEmailTemplate
  } = useEmailTemplateList({
    setSelectedTemplate: onClickUpdate,
    setParentFolderId: setParentFolderId
  });

  useEffect(() => {
    if (currentTemplate && currentTemplate.id !== 0) {
      createOrUpdateEmailTemplate(currentTemplate);
    }
  }, [currentTemplate]);

  return (
    <>
      <div className="p-4 flex flex-col">
        {editable && (
          <div className="flex justify-end">
            <CreateTemplateButton onCreateTemplate={showCreateForm} label="New Template" />
            <Menu as="div" className="relative -ml-px block">
              <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border-l border-white text-xs font-medium text-purple-700 bg-purple-100 hover:bg-purple-200">
                <div className="flex flex-col justify-center h-[1rem]">
                  <span className="sr-only">Open options</span>
                  <ChevronDown className="h-[0.8rem] w-[0.6rem]" aria-hidden="true" />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div>
                    <Menu.Item key="folder">
                      {({ active }) => (
                        <div
                          onClick={showCreateFolder}
                          className="flex space-x-1 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-yellow-500"
                        >
                          <FolderPlus className="w-4" />
                          <p className="">New Folder</p>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
        {folderId !== 0 && (
          <div className="flex justify-start">
            <BackButton onBack={handleBackFolder} />
            <span className="ml-2 text-sm">{folderName}</span>
          </div>
        )}
      </div>
      <ul className="relative divide-y divide-gray-200 overflow-y-auto overflow-x-hidden h-[40rem]">
        <WithIndicator
          isLoaded={!isLoading}
          isEmpty={Array.isArray(templates) && templates.length === 0}
          options={{
            emptyLabelStyle: "pt-4",
            LoadingStyle: "pt-4"
          }}
        >
          {templates.map((template) => {
            return (
              <li className="p-4" key={template.id}>
                <EmailTemplateListItem
                  item={template}
                  isActive={activeTemplate ? template.id === activeTemplate : false}
                  onHandleUpdated={onClickUpdate}
                  onHandleRemoved={handleRemove}
                  onHandleChangeFolder={handleFolderChange}
                  editable={editable}
                />
              </li>
            );
          })}
        </WithIndicator>
      </ul>
      <Modal modalKey={REMOVE_TEMPLATE_MODAL_KEY}>
        <ConfirmDialog
          onConfirm={onConfirm}
          onCancel={closeModal}
          options={{
            title: "Remove template?",
            subTitle: "Are you sure you want to remove template?",
            btnStyle: "negative"
          }}
        />
      </Modal>
    </>
  );
};

export default EmailTemplateListing;
