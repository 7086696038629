import { useState } from "react";
import { defaultQueries } from "../../share";
import { QueryConfigType } from "@views/components/data-table/index";
import { TASK_TYPE } from "@model/task";

export type formType = {
  id: number | string;
  content: string;
};

export type TabType = {
  name: string;
  key: string;
  current: boolean;
  disable: boolean;
  fields: string[];
};

export default function useTaskTabs(type: TASK_TYPE, filters?: object | null) {
  const [tabs, setTabs] = useState<TabType[]>([
    { name: "Due today", key: "today", current: true, disable: false, fields: [] },
    { name: "Overdue", key: "overdue", current: false, disable: false, fields: [] },
    { name: "Upcoming", key: "upcoming", current: false, disable: false, fields: [] },
    { name: "Done", key: "done", current: false, disable: false, fields: [] }
  ]);

  const initialQueries = { ...defaultQueries, filters: {} as any };

  // Set default filters
  if (filters) {
    initialQueries.filters = { ...initialQueries.filters, ...(filters ?? {}) };
  }

  // Remove empty filters
  Object.keys(initialQueries.filters).forEach((k) => {
    if (!initialQueries.filters[k]) delete initialQueries.filters[k];
  });

  const [queries, setQueries] = useState<QueryConfigType>(initialQueries);
  const activeTab = tabs?.find((tab) => tab.current);

  return [tabs, setTabs, queries, setQueries, activeTab] as const;
}
