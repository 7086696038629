import { get } from "@utils/api";

class GetTasksService {
  endpoint: string;
  queries: any;

  static async call(queries: any, endPoint: string = "tasks"): Promise<any> {
    const service = new GetTasksService(endPoint, {
      ...queries,
      filters: JSON.stringify(queries.filters)
    });
    return await service.call();
  }

  static async show(id: any): Promise<any> {
    const endpoint = `tasks/${id}`;
    const service = new GetTasksService(endpoint, {});
    return await service.show();
  }

  constructor(endpoint: string, queries: any) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async call(): Promise<any> {
    const path = this.endpoint;
    return await get({ path, queries: this.queries });
  }

  async show(): Promise<any> {
    const path = this.endpoint;
    return await get({ path, queries: this.queries });
  }
}

export default GetTasksService;
