import { get } from "@utils/api";
import type { queryType } from "@utils/api";

class FeatureService {
  endpoint: string;
  queries: queryType;

  static async call(queries: any, options?: any): Promise<any> {
    return await new FeatureService("features/search", queries).get(options);
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async get(options: any): Promise<any> {
    const path: string = this.endpoint;
    const queries: queryType = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return options?.render ? data.map(options.render) : data;
  }
}

export default FeatureService;
