import { get } from "@utils/api";
import type { queryType } from "@utils/api";

class LocationService {
  endpoint: string;
  queries: queryType;

  static async call(jsonValues: string, options?: any): Promise<any> {
    // const slugValue = value?.toLowerCase()?.replace(/ /g, '-')?.replace(/[^\w-]+/g, '') || '';
    // ex.
    // { 'region_2': region_2, 'region_3': region_3, 'locality': value }
    return await new LocationService("locations", { filters: jsonValues }).getLocations(options);
  }

  constructor(endpoint: string, queries: queryType) {
    this.endpoint = endpoint;
    this.queries = queries;
  }

  async getLocations(options: any): Promise<any> {
    const path: string = this.endpoint;
    const queries: queryType = this.queries;
    const { data } = await get({ path, queries });

    if (!data.length) return [];

    return data.map(options.render);
  }
}

export default LocationService;
