import { classNames } from "@utils/helpers";
import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

interface IPlaceholderOptions {
  emptyLabel?: string;
  emptyLabelStyle?: string;
  LoadingStyle?: string;
}

interface IProps {
  isEmpty?: boolean;
  options?: IPlaceholderOptions;
  isLoaded: boolean;
  rows?: number;
  lines?: number;
  children: ReactNode;
}

export default function PlaceholderComponent({
  isEmpty = false,
  isLoaded,
  rows = 1,
  lines = 5,
  options,
  children
}: IProps): any {
  if (!isLoaded) {
    return Array.from(Array(rows).keys()).map((key, idx) => {
      return (
        <div key={idx} className={classNames("px-3", options?.LoadingStyle || "")}>
          <Skeleton count={lines} /> <br />
        </div>
      );
    });
  }

  if (isEmpty) {
    return (
      <p
        className={classNames(
          "w-full text-center text-gray-400 text-base font-semibold mt-4",
          options?.emptyLabelStyle || ""
        )}
      >
        {options?.emptyLabel || "No data found."}
      </p>
    );
  }

  return <>{children}</>;
}
