import { selectModal } from "@state/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  openGlobalModal,
  onConfirmGlobalModal,
  closeGlobalModal
} from "@state/actions/action.creators";

export default function useModal<T = boolean>() {
  const dispatch = useDispatch();
  const modalState = useSelector(selectModal);

  // OPEN EVENT
  const openModal = (key: string, options?: any) => {
    return openGlobalModal<T>(dispatch, { key, options });
  };

  // SUBMIT EVENT
  const onConfirm = (data?: any) => {
    if (modalState.key === null) return;
    onConfirmGlobalModal(dispatch, modalState, data);
  };

  // CLOSE EVENT
  const closeModal = () => {
    if (modalState.key === null) return;
    closeGlobalModal(dispatch, modalState);
  };

  const modalOptions = modalState.options;

  return [openModal, closeModal, onConfirm, modalOptions, modalState] as const;
}
