import { classNames } from "@utils/helpers";
import { ItemView, ShowMoreBtn } from "@components/task-controller/index";
import { LoadingIndicator } from "@components/index";
import { QueryConfigType } from "@views/components/data-table/index";
import useTaskLists from "./hooks/useTaskLists";
import { TASK_TAB, TASK_TYPE } from "@model/task";
import withTaskLoading from "@views/components/task-controller/hooks/withTaskLoading";

interface TaskListsProps {
  type: TASK_TYPE;
  queries: QueryConfigType;
  tab: TASK_TAB;
  label?: string;
  positionType: "page" | "tab";
}

function TaskLists({ type, queries, tab, label, positionType }: TaskListsProps) {
  const [state, loadMore, onDelete, onUpdate] = useTaskLists(type, tab, queries, positionType);

  const badgeColor = () => {
    if (label === "Overdue") {
      return "bg-red-100 text-red-800";
    } else if (label === "Done") {
      return "bg-green-100 text-green-800";
    } else if (label === "Up Coming") {
      return "bg-yellow-100 text-yellow-800";
    }
    return "bg-blue-100 text-blue-800";
  };

  return (
    // LABEL FOR OVERDUE AND TODAY
    <div className={`relative ${state.isLoading ? "min-h-[80px]" : "min-h-[20px]"} mb-5`}>
      <div className="flex items-center space-x-3">
        {label && (
          <div className="flex items-center space-x-2 text-sm">
            <span
              className={classNames(
                badgeColor(),
                "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium "
              )}
            >
              {label}
            </span>
          </div>
        )}

        {
          // SHOW NOT FOUND
          state.init && !state.items?.length && (
            <div className="flex justify-start">
              <p className="font-semibold text-gray-400">No tasks found.</p>
            </div>
          )
        }
      </div>

      <div className="overflow-hidden">
        {/* SHOW TASKS */}
        {state.items?.map((item: any) => (
          <ItemView
            key={`type_${item.id}`}
            data={{ ...item }}
            editable={item?.is_editable || false}
            handleChange={onUpdate}
            handleDelete={onDelete}
          />
        ))}

        {/* SHOW LOAD MORE */}
        <ShowMoreBtn items={state.items} total={state.total} showMore={loadMore} />
      </div>

      <LoadingIndicator show={state.isLoading} />
    </div>
  );
}

export default withTaskLoading(TaskLists);
