import { QueryConfigType } from "../index";
import { classNames } from "@utils/helpers";
import { rowOptions } from "../index";
import { numberWithCommas } from "@utils/helpers";

interface IProps {
  total: number;
  queries: QueryConfigType;
  setQueries: (queries: QueryConfigType) => void;
}

export default function TablePagination({ total, queries, setQueries }: IProps) {
  if (!queries) return null;
  const maxNumber = Math.ceil(total / queries.per_page);
  const renderNumber = (c: number, m: number) => {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  return (
    <div className="flex items-center justify-between px-1 py-2 border-t border-gray-200 bg-gray-50 sm:px-4">
      <div className="justify-between flex-1 hidden">
        <button
          onClick={() => {
            if (queries.page > 1) {
              // console.log(queries);
              setQueries({ ...queries, page: queries.page - 1 });
              // setQueries((prev: QueryConfigType) => ({ ...prev, page: prev.page - 1 }))
            }
          }}
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() => {
            if (maxNumber > queries.page) {
              setQueries({ ...queries, page: queries.page + 1 });
            }
          }}
          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="flex flex-col items-center justify-between flex-1 space-y-4 sm:justify-start sm:flex-row sm:space-y-0">
        <div className="flex items-center justify-between w-full sm:flex-col sm:items-start">
          <p className="text-xs text-gray-700 sm:text-sm">
            Showing{" "}
            <span className="font-medium">
              {queries.page * queries.per_page - queries.per_page + 1}
            </span>{" "}
            to <span className="font-medium">{queries.page * queries.per_page}</span> of{" "}
            <span className="font-medium">{numberWithCommas(total.toString())}</span> results
          </p>
          <div className="flex items-center space-x-1 text-xs text-gray-700 sm:text-sm">
            <p>Showing</p>
            <select
              name="showing"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setQueries({ ...queries, per_page: parseInt(e.target.value), page: 1 });
              }}
              defaultValue={queries.per_page}
              className="h-[20px] sm:h-full py-0 pl-2 text-gray-700 bg-transparent rounded-md focus:ring-0 pr-5 text-xs sm:text-sm"
            >
              {rowOptions.map((r, idx) => (
                <option key={idx} value={r}>
                  {r}
                </option>
              ))}
            </select>
            <p>entries</p>
          </div>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => {
                if (queries.page > 1) {
                  // console.log(queries);
                  setQueries({ ...queries, page: queries.page - 1 });
                }
              }}
              className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-left"
                className="w-3 h-3 svg-inline--fa fa-chevron-left"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"
                ></path>
              </svg>
            </button>
            {renderNumber(queries.page, maxNumber).map((i, idx) =>
              i === "..." ? (
                <span
                  key={`page_dot_${idx}`}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300"
                >
                  ...
                </span>
              ) : (
                <button
                  key={`page_${i}`}
                  onClick={() => {
                    let number = typeof i === "number" ? i : parseInt(i);
                    setQueries({ ...queries, page: number });
                  }}
                  aria-current="page"
                  className={classNames(
                    i === queries.page
                      ? "z-10 bg-purple-50 border-primary-200 text-primary-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                      : "cursor-pointer  bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  )}
                >
                  {i}
                </button>
              )
            )}
            <button
              onClick={() => {
                if (maxNumber > queries.page) {
                  // console.log(queries);
                  setQueries({ ...queries, page: queries.page + 1 });
                }
              }}
              className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-right"
                className="w-3 h-3 svg-inline--fa fa-chevron-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                ></path>
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
