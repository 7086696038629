import { deleteItem, queryType } from "@utils/api";

class DeleteEmailTemplateService {
  endpoint: string;
  queries: queryType = {};

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  static async call(templateId: number) {
    const endpoint = `email-templates/${templateId}`;

    return new DeleteEmailTemplateService(endpoint).removeTemplate();
  }

  async removeTemplate() {
    return await deleteItem({
      path: this.endpoint,
      queries: this.queries
    });
  }
}

export default DeleteEmailTemplateService;
